import { useEffect, useState } from "react";
import { Form, FormInstance, Radio, RadioChangeEvent, Space } from "antd";
import { _t } from "../../utils/translate";
import { patternDrawingData } from "../../utils/constants";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

const AdvancedPatternComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: Boolean }) => {
  const { lang } = useSelector((state: RootState) => state.auth);
  const [prevState, setPrevState] = useState<string>("");

  useEffect(() => {
    updateFilter({
      "pattern": form.getFieldValue('pattern'),
    });
    setPrevState(form.getFieldValue('pattern'));
  }, []);

  useEffect(() => {
    setPrevState("");
  }, [reset]);

  const handleClick = (value: any) => () => {
    setPrevState(prevState !== "" && prevState === value ? "" : value);
    updateFilter({ 'pattern': prevState !== "" && prevState === value ? undefined : value });
  }

  return (
    <Form.Item name="pattern" style={{ height: 150, overflowY: 'auto', marginBottom: 0 }}>
      <Radio.Group size="large">
        <Space direction="vertical">
          {Object.keys(patternDrawingData)
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map((val) => (
            <Radio value={val} key={'imprimePattern' + val} onClick={handleClick(val)}>{_t(val)}</Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default AdvancedPatternComponent;