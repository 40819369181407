import { Button } from "antd";
import { _t } from "../utils/translate";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

export default function Home() {
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);

  return (
    <div className="flex flex-col">
      <div className="text-4xl md:text-6xl uppercase flex justify-center text-center font-bold mt-24 md:mt-32 mb-10 md:mb-20 text-blue-800">
        <h1 className="w-[720px]">{_t("home.title")}</h1>
      </div>
      <div className="flex justify-center gap-x-6 md:gap-x-12">
        <Button 
          className="w-[10rem] md:w-[14rem] h-[10rem] md:h-[14rem] rounded-full bg-[#364F97] flex items-center justify-center p-5 text-white text-xl md:text-2xl font-semibold aspect-square"
          onClick={() => navigate(auth ? (auth.type === 'VISITOR' ? '/auth/becomeSeller' : '/fabrics/publish') : '/auth/login')}
        >
          {_t("home.publishFabric")}
        </Button>
        <Button 
          className="w-[10rem] md:w-[14rem] h-[10rem] md:h-[14rem] rounded-full bg-[#364F97] flex items-center justify-center p-5 text-white text-xl md:text-2xl font-semibold aspect-square"
          onClick={() => navigate('/fabrics/research')}
        >
          {_t("home.findFabric")}
        </Button>
      </div>
    </div>
  );
}
