import { useEffect, useState } from "react"
import {
  Col,
  Row,
  Collapse,
  CollapseProps,
  Card,
  Button,
  Input,
  Skeleton,
  Form,
  Pagination,
  PaginationProps,
  Empty,
} from "antd"
import {
  AlignHorizontalDistributeCenter,
  BetweenHorizonalStart,
  BetweenVerticalStart,
  BookMarked,
  BrickWall,
  Brush,
  Building2,
  CircleDollarSign,
  CircleHelp,
  Earth,
  Filter,
  FilterIcon,
  Grid3X3,
  Hammer,
  Minus,
  Palette,
  Pencil,
  PencilRuler,
  Puzzle,
  Ruler,
  ShieldEllipsis,
  Sparkle,
} from "lucide-react"

import FabricCard from "../../components/space/FabricCard"
import { BrowserView, MobileView, isBrowser, isDesktop } from "react-device-detect"

import { fetchData } from "../../utils/utils"
import { FabricDataType } from "../../utils/types"
import { _t } from "../../utils/translate"

import BasicCommercialNameComponent from "../../components/filter/basicCommercialName"
import BasicFabricUsageComponent from "../../components/filter/basicFabricUsage"
import BasicQuantityComponent from "../../components/filter/basicQuantity"
import BasicPriceComponent from "../../components/filter/basicPrice"
import BasicArmorComponent from "../../components/filter/basicArmor"
import BasicColorComponent from "../../components/filter/basicColor"
import BasicColorTypeComponent from "../../components/filter/basicColorType"
import BasicElasticityComponent from "../../components/filter/basicElasticity"
import BasicWidthComponent from "../../components/filter/basicWidth"
import BasicCompositionComponent from "../../components/filter/basicComposition"
import TreatmentsComponent from "../../components/filter/treatments"
import CertificationsComponent from "../../components/filter/certifications"
import OtherIntendedUseComponent from "../../components/filter/otherIntendedUse"
import OtherStructureComponent from "../../components/filter/otherStructure"
import OtherIroningPleatedComponent from "../../components/filter/otherIroningPleated"
import OtherTurbularOpenComponent from "../../components/filter/otherTurbularOpen"
import OtherPlyComponent from "../../components/filter/otherPly"
import MoreCountryComponent from "../../components/filter/moreCountry"
import MoreOriginComponent from "../..//components/filter/moreFabricOrigin"
import MoreCityComponent from "../../components/filter/moreCity"
import MoreDiscountComponent from "../../components/filter/moreDiscount"
import AdvancedDrawingColorTypeComponent from "../../components/filter/advancedDrawingColor"
import AdvancedSecondColorTypeComponent from "../../components/filter/advancedSecondColor"
import { patternDrawingData } from "../../utils/constants"
import AdvancedPatternComponent from "../../components/filter/advancedPattern"
import AdvancedDrawingComponent from "../../components/filter/advancedDrawing"
import AdvancedDrawingWidthComponent from "../../components/filter/advancedDrawingWidth"
import AdvancedDrawingHeightComponent from "../../components/filter/advancedDrawingHeight"
import AdvancedDrawingSpaceComponent from "../../components/filter/advancedDrawingSpace"
import BasicDensityGrammageComponent from "../../components/filter/basicDensityGrammage"
import AdvancedVolumeComponent from "../../components/filter/advancedVolume"
import BasicUnitComponent from "../../components/filter/basicUnit"
import { City, Country, ICity } from "country-state-city"

import PerfectScrollbar from "react-perfect-scrollbar"
import { useDispatch } from "react-redux"
import { setResearchFilters, setResearchText, setResearchPage, setFilterEnabled } from "../../redux/authSlice"
import { useSelector } from "react-redux"
import { RootState } from "@/redux/store"

interface FilterOptionType {
  title: React.ReactNode
  data: CollapseProps["items"]
}

const FabricResearch = () => {
  const dispatch = useDispatch();

  const { lang, researchFilters, researchText, researchPage, isFilterEnabled } = useSelector((state: RootState) => state.auth);

  const [isInit, setIsInit] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fabricData, setFabricData] = useState<FabricDataType[]>([])

  const [searchText, setSearchText] = useState<string>("")
  const [sortDate, setSortDate] = useState<boolean>(true)
  const [unit, setUnit] = useState<string>("")

  const [isFilterShown, setIsFilterShown] = useState<boolean>(true)
  const [filters, setFilters] = useState<Record<string, any>>({})
  const [drawings, setDrawings] = useState<string[]>([])

  const [totalCount, setTotalCount] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)

  const [form] = Form.useForm()
  const [isReset, setIsReset] = useState<boolean>(false)

  const countries = Country.getAllCountries()
  const [cities, setCities] = useState<ICity[] | undefined>(undefined)

  const pageSize = isBrowser ? 6 : 3

  useEffect(() => {
    // if (researchFilters['expTime'] && researchFilters['expTime'] > Date.now()) {
    if (isFilterEnabled) {
      setSearchText(researchText);
      setCurrent(researchPage);
      onFilter(researchFilters);
      loadData(researchPage, researchFilters, researchText);
      dispatch(setFilterEnabled(false));
      setIsFilterShown(false);
    } else {
      // loadData(1, {}, "")
    }
  }, [])

  const loadData = async (
    current: number,
    filters: Record<string, any>,
    searchText: string
  ) => {
    console.log("Researching data ...")
    setIsLoading(true);
    setTotalCount(0);
    setIsInit(false);
    try {
      let updatedFilters: Record<string, any> = {}
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          if (Array.isArray(filters[key])) updatedFilters[key] = filters[key]
          else if (typeof filters[key] === "object") {
            updatedFilters[key] = filters[key].realValue
              ? filters[key].realValue
              : filters[key].value
          } else updatedFilters[key] = filters[key]
        }
      })

      dispatch(setResearchFilters(updatedFilters));
      dispatch(setResearchText(searchText));
      dispatch(setResearchPage(current));

      await fetchData("api/fabric/research", {
        from: (current - 1) * pageSize,
        to: current * pageSize - 1,
        searchText,
        ...updatedFilters,
      }).then((data: any) => {
        if (data) {
          const fabrics: FabricDataType[] = data.fabrics
          setFabricData(fabrics)
          setTotalCount(data.total)
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onFilter = (options: Record<string, any>) => {
    setFilters({ ...filters, ...options })
    form.setFieldsValue(options)
  }

  const filterItems: FilterOptionType[] = [
    {
      title: <span className='underline'>{_t("Basic")}</span>,
      data: [
        {
          key: 1,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Earth />
              {_t("form.commercialName")}
            </span>
          ),
          children: (
            <BasicCommercialNameComponent form={form} updateFilter={onFilter} />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 2,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Hammer />
              {_t("form.fabricUsage")}
            </span>
          ),
          children: (
            <BasicFabricUsageComponent form={form} updateFilter={onFilter} />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 3,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <ShieldEllipsis />
              {_t("form.armor")}
            </span>
          ),
          children: <BasicArmorComponent form={form} updateFilter={onFilter} />,
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 23,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <CircleHelp />
              {_t("Fabric for")}
            </span>
          ),
          children: (
            <OtherIntendedUseComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 4,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Brush />
              {_t("form.colorType")}
            </span>
          ),
          children: (
            <BasicColorTypeComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 5,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Palette />
              {_t("form.color")}
            </span>
          ),
          children: <BasicColorComponent form={form} updateFilter={onFilter} />,
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 14,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Palette />
              {_t("form.drawingPrincipalColor")}
            </span>
          ),
          children: (
            <AdvancedDrawingColorTypeComponent
              form={form}
              updateFilter={onFilter}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 15,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Palette />
              {_t("form.drawingSecondaryColor")}
            </span>
          ),
          children: (
            <AdvancedSecondColorTypeComponent
              form={form}
              updateFilter={onFilter}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 16,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Sparkle />
              {_t("form.pattern")}
            </span>
          ),
          children: (
            <AdvancedPatternComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 17,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Pencil />
              {_t("form.drawing")}
            </span>
          ),
          children: (
            <AdvancedDrawingComponent form={form} updateFilter={onFilter} />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 18,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <BetweenHorizonalStart />
              {_t("form.drawingWidth")}
            </span>
          ),
          children: (
            <AdvancedDrawingWidthComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 19,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <BetweenHorizonalStart />
              {_t("form.drawingHeight")}
            </span>
          ),
          children: (
            <AdvancedDrawingHeightComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 20,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <BetweenVerticalStart />
              {_t("form.drawingSurfaceCm")}
            </span>
          ),
          children: (
            <AdvancedDrawingSpaceComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 6,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Ruler />
              {_t("form.quantity")}
            </span>
          ),
          children: (
            <BasicQuantityComponent form={form} updateFilter={onFilter} />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 7,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Grid3X3 />
              {_t("Density / Grammage")}
            </span>
          ),
          children: (
            <BasicDensityGrammageComponent
              form={form}
              updateFilter={onFilter}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 8,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Puzzle />
              {_t("details.composition")}
            </span>
          ),
          children: (
            <BasicCompositionComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 9,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <CircleDollarSign />
              {_t("form.priceText")}
            </span>
          ),
          children: <BasicPriceComponent form={form} updateFilter={onFilter} />,
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        // {
        //   key: 10,
        //   label: (
        //     <span className='flex gap-x-2 text-blue-700'>
        //       <Ruler />
        //       {_t("form.unit")}
        //     </span>
        //   ),
        //   children: (
        //     <BasicUnitComponent
        //       form={form}
        //       updateFilter={onFilter}
        //       reset={isReset}
        //     />
        //   ),
        //   style: {
        //     border: "1px solid #93C5FD",
        //     boxShadow: "1px 1px 2px 0px #1E40AF33",
        //     borderRadius: 8,
        //     marginBottom: 6,
        //   },
        // }, 
      ],
    },
    {
      title: <span className='underline'>{_t("Advanced Filters")}</span>,
      data: [
        {
          key: 11,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Minus />
              {_t("form.elasticity")}
            </span>
          ),
          children: (
            <BasicElasticityComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 31,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <AlignHorizontalDistributeCenter />
              {_t("form.volume")}
            </span>
          ),
          children: (
            <AdvancedVolumeComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 12,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <PencilRuler />
              {_t("form.width")}
            </span>
          ),
          children: <BasicWidthComponent form={form} updateFilter={onFilter} />,
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },

      ],
    },
    {
      title: <span className='underline'>{_t("More Advanced Filters")}</span>,
      data: [
        {
          key: 21,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <BrickWall />
              {_t("Treatments")}
            </span>
          ),
          children: <TreatmentsComponent form={form} updateFilter={onFilter} />,
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 22,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <BookMarked />
              {_t("Certifications")}
            </span>
          ),
          children: (
            <CertificationsComponent form={form} updateFilter={onFilter} />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 24,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Building2 />
              {_t("details.fabricCountry")}
            </span>
          ),
          children: (
            <MoreCountryComponent form={form} updateFilter={onFilter} />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 31,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Building2 />
              {_t("form.origin")}
            </span>
          ),
          children: <MoreOriginComponent form={form} updateFilter={onFilter} />,
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 30,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <Building2 />
              {_t("details.fabricCity")}
            </span>
          ),
          children: (
            <MoreCityComponent
              form={form}
              updateFilter={onFilter}
              cities={cities}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 25,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <CircleDollarSign />
              {_t("Discount")}
            </span>
          ),
          children: (
            <MoreDiscountComponent form={form} updateFilter={onFilter} />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 26,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <CircleHelp />
              {_t("form.turbularOpen")}
            </span>
          ),
          children: (
            <OtherTurbularOpenComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 27,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <CircleHelp />
              {_t("Single or double faced")}
            </span>
          ),
          children: (
            <OtherPlyComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 28,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <CircleHelp />
              {_t("Knit or Warp and Weft")}
            </span>
          ),
          children: (
            <OtherStructureComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
        {
          key: 29,
          label: (
            <span className='flex gap-x-2 text-blue-700'>
              <CircleHelp />
              {_t("Pleated or not pleated")}
            </span>
          ),
          children: (
            <OtherIroningPleatedComponent
              form={form}
              updateFilter={onFilter}
              reset={isReset}
            />
          ),
          style: {
            border: "1px solid #93C5FD",
            boxShadow: "1px 1px 2px 0px #1E40AF33",
            borderRadius: 8,
            marginBottom: 6,
          },
        },
      ],
    },
  ]

  const onSearch = (text: string) => {
    setSearchText(text)
    setCurrent(1)

    loadData(1, filters, text)
  }

  const onResearch = async () => {
    setCurrent(1)
    loadData(1, filters, searchText);
    setIsFilterShown(false);
  }

  const onReset = async () => {
    await form.resetFields()
    setUnit("")
    setSearchText("")

    const values: Record<string, any> = await form.getFieldsValue()
    setFilters(values)
    setCurrent(1)
    setIsReset(!isReset)
    
    setCities(undefined)
    
    setIsInit(true);
    setFabricData([]);
    setTotalCount(0);
    // loadData(1, values, "");
    setIsFilterShown(false);
  }

  const onFormChange = () => {
    setDrawings(patternDrawingData[form.getFieldValue("pattern")])
    if (form.getFieldValue("stockCountry")) {
      const index = countries.findIndex(
        (country) => country.name === form.getFieldValue("stockCountry").value
      )
      setCities(
        index === -1
          ? undefined
          : City.getCitiesOfCountry(countries[index].isoCode)
      )
    }
  }

  const buildFilterBody = () => (
    <>
      <Collapse
        bordered={false}
        items={[
          {
            key: "1",
            label: (
              <p className='text-center text-xl font-semibold text-blue-500'>
                {filterItems[0].title}
              </p>
            ),
            children: (
              <Form.Item style={{ marginBottom: 8 }} key={"filterItem" + 0}>
                {/* <p className="text-center text-lg font-semibold mb-2">{filterItems[0].title}</p> */}
                <Collapse
                  items={filterItems[0].data?.filter((_, index) =>
                    (filters.colorType === 'form.unified')
                      ? index < 6 || index > 12
                      : 1
                  )}
                  expandIconPosition={"end"}
                  className='custom-collapse font-semibold'
                  size='large'
                  style={{ border: "none" }}
                />
              </Form.Item>
            ),
          },
        ]}
      ></Collapse>
      <Collapse
        bordered={false}
        items={[
          {
            key: "2",
            label: (
              <p className='text-center text-xl font-semibold text-blue-500'>
                {filterItems[1].title}
              </p>
            ),
            children: (
              <Form.Item style={{ marginBottom: 8 }} key={"filterItem" + 0}>
                {/* <p className="text-center text-lg font-semibold mb-2">{filterItems[1].title}</p> */}
                <Collapse
                  items={
                    form.getFieldValue("colorType") === "form.unified"
                      ? filterItems[1].data?.slice(0, 2)
                      : filterItems[1].data
                  }
                  expandIconPosition={"end"}
                  className='custom-collapse font-semibold'
                  size='large'
                  style={{ border: "none" }}
                />
              </Form.Item>
            ),
          },
        ]}
      ></Collapse>
      <Collapse
        bordered={false}
        items={[
          {
            key: "3",
            label: (
              <p className='text-center text-xl font-semibold text-blue-500'>
                {filterItems[2].title}
              </p>
            ),
            children: (
              <Form.Item style={{ marginBottom: 8 }} key={"filterItem" + 0}>
                {/* <p className="text-center text-lg font-semibold mb-2">{filterItems[2].title}</p> */}
                <Collapse
                  items={filterItems[2].data}
                  expandIconPosition={"end"}
                  className='custom-collapse font-semibold'
                  size='large'
                  style={{ border: "none" }}
                />
              </Form.Item>
            ),
          },
        ]}
      ></Collapse>
    </>
  )

  const buildFilter = () => (
    <Card
      title={
        <span className='text-[20px] text-blue-700 flex items-center gap-x-1'>
          <FilterIcon /> {_t("form.filters")}
        </span>
      }
      extra={
        <div className='flex'>
          <Button
            type={"link"}
            onClick={onReset}
            className='text-base font-semibold'
          >
            {_t("form.reset")}
          </Button>
          <MobileView>
            <Button
              type={"link"}
              className='text-base font-semibold'
              onClick={() => setIsFilterShown(false)}
            >
              {_t("details.close")}
            </Button>
          </MobileView>
        </div>
      }
      styles={{
        body: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 8,
          paddingBottom: 0,
          backgroundColor: "#F9FAFB",
        },
        header: {
          backgroundColor: "#F9FAFB",
          borderBottom: "2px solid #93C5FD",
        },
      }}
      className="relative md:sticky top-0 md:top-[80px]"
    // style={{ height: lang === 'en' ? "calc(100vh - 340px)" : "calc(100vh - 370px)" }}
    >
      <div className='flex flex-col gap-y-3'>
        <Form
          layout={"horizontal"}
          form={form}
          className='w-full'
          onValuesChange={onFormChange}
        >
          <BrowserView>
            <Button
              type='primary'
              size='large'
              className='m-3 mt-0 text-lg h-[44px]'
              loading={isLoading}
              onClick={onResearch}
              style={{ width: "calc(100% - 24px)" }}
            >
              {_t("form.research")}
            </Button>
            <PerfectScrollbar>
              <div
                className='relative'
                style={{ height: "calc(100vh - 300px)" }}
              >
                {buildFilterBody()}
              </div>
            </PerfectScrollbar>
          </BrowserView>
          <MobileView>
            {buildFilterBody()}
          </MobileView>
        </Form>
      </div>
    </Card>
  )

  const onPaginationChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page)
    loadData(page, filters, searchText)
  }

  const onRefresh = () => {
    loadData(current, filters, searchText)
  }

  const buildView = () => (
    <>
      <div className='flex flex-col mb-2 gap-y-2 text-base'>
        <div className='flex flex-col md:flex-row gap-x-2 items-center md:justify-between'>
          <div className='flex flex-col gap-x-[20px] md:flex-row items-start md:items-center w-full'>
            <p className='font-semibold text-lg text-right text-blue-700'>
              {_t("form.searchBy")}:
            </p>
            <Input.Search
              size='large'
              placeholder=''
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={onSearch}
              className='flex-1 w-full md:max-w-[300px]'
            />
          </div>
          <span className='text-blue-600 text-lg font-semibold w-full text-right'>
            {totalCount} {_t("fabric result")}
          </span>
        </div>
      </div>
      <Row gutter={16} className='flex h-auto'>
        {!isLoading ? (
          fabricData.length === 0 ? (
            <div className='w-full h-[500px] flex items-center justify-center'>
              <Empty
                className='text-xl font-bold'
                description={
                  <span className='text-blue-800'>{_t(isInit ? "research.initial" : "research.empty")}</span>
                }
              />
            </div>
          ) : (
            fabricData.map((item, index) => (
              <Col
                key={"fabric-card" + index}
                span={isBrowser ? 8 : 24}
                className='mb-3'
              >
                <FabricCard item={item} onRefresh={onRefresh} />
              </Col>
            ))
          )
        ) : (
          (isBrowser ? [1, 2, 3, 4, 5, 6] : [1, 2, 3]).map((item, index) => (
            <Col key={"fabric-card" + index} span={isBrowser ? 8 : 24}>
              <Card
                className='mb-3'
                styles={{
                  body: {
                    padding: 0,
                    boxShadow: "0 1px 2px #021263",
                    borderRadius: 8,
                  },
                }}
              >
                <div style={{ aspectRatio: 1 }}>
                  <Skeleton.Image
                    className='custom-skeleton-image rounded-md'
                    active={true}
                  />
                </div>
                <div className='flex flex-col gap-2 p-3'>
                  <Skeleton active />
                  <Skeleton.Button active={true} block />
                </div>
              </Card>
            </Col>
          ))
        )}
      </Row>
      <Pagination
        className='flex justify-center'
        current={current}
        onChange={onPaginationChange}
        total={totalCount}
        pageSize={pageSize}
      />
    </>
  )

  return (
    <div className='flex-1 md:pb-5 flex flex-col items-center'>
      <BrowserView className='w-full h-full px-5'>
        <h1 className='text-center text-4xl text-blue-800 font-bold py-3'>
          {_t("research.title")}
        </h1>
        <Row>
          <Col span={6}>{buildFilter()}</Col>
          <Col span={18} className='px-5'>
            {buildView()}
          </Col>
        </Row>
      </BrowserView>
      <MobileView className='relative w-full flex-1 flex flex-col items-center'>
        <h1 className='text-center text-2xl text-blue-800 font-bold w-full py-3'>
          {_t("research.title")}
        </h1>
        <div className='w-full flex pb-3'>
          <Button block
            type={isFilterShown ? "default" : "primary"}
            // className='w-full text-center'
            size="large"
            onClick={() => setIsFilterShown(!isFilterShown)}
          >
            {_t("form.filters")}
          </Button>
        </div>
        {isFilterShown ? (
          <>
            <div
              className='left-0 overflow-y-auto w-full flex-1'
              style={{ zIndex: 1, maxHeight: "100%" }}
            >
              {buildFilter()}
            </div>
            <div className="sticky bottom-0 z-10 w-full bg-white">
              <Button
                type='primary'
                size='large'
                className='w-full m-3 mt-0 text-lg h-[44px]'
                loading={isLoading}
                onClick={onResearch}
                style={{ width: "calc(100% - 24px)" }}
              >
                {_t("form.research")}
              </Button>
            </div>
          </>
        ) : (
          <div className='w-full'>
            {buildView()}
          </div>
        )}
      </MobileView>
    </div>
  )
}

export default FabricResearch
