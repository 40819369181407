import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, message } from "antd";
import { Building, Mail, Phone, UserCircle } from "lucide-react";
import { RootState } from "@/redux/store";
import { postData } from "../../utils/utils";
import { setAuth, updateAuth } from "../../redux/authSlice";
import { _t } from "../../utils/translate";
import { isBrowser } from "react-device-detect";

const SettingInfoPage = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!auth) return;

    form.setFieldsValue({
      fullName: auth.fullName,
      email: auth.email,
      phone: auth.phone,
      city: auth.city
    });
  }, [auth, form])

  const onSave = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();

      try {
        const apiResult = await postData('api/auth/update_info', {
          fullName: values['fullName'],
          email: values['email'],
          phone: values['phone'],
          city: values['city'],
        });

        if (apiResult) {
          messageApi.open({
            key: 'notify-msg',
            type: 'success',
            content: _t("error.updatedInfo"),
            duration: 2,
          });

          dispatch(updateAuth({
            fullName: values['fullName'],
            email: values['email'],
            phone: values['phone'],
            city: values['city'],
          }));
        }
      } catch (error: any) {
        messageApi.error({
          key: 'notify-msg',
          type: 'error',
          content: _t("error.updateInfoFailed"),
          duration: 2,
        });
      }
    } catch (errorInfo) {
      console.log('Failed');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
      >
        <Form.Item
          label={_t("form.fullName")} name="fullName" key='fullName'
          initialValue={""}
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <Input style={{ width: 300 }} size="large" prefix={<UserCircle />} />
        </Form.Item>
        <Form.Item
          label={_t("form.email")} name="email" key='email'
          initialValue={""}
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <Input type="email" style={{ width: 300 }} size="large" prefix={<Mail />} />
        </Form.Item>
        <Form.Item
          label={_t("form.phone")} name="phone" key='phone'
          initialValue={""}
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <Input style={{ width: 300 }} size="large" prefix={<Phone />} />
        </Form.Item>
        <Form.Item
          label={_t("form.city")} name="city" key='city'
          initialValue={""}
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <Input style={{ width: 300 }} size="large" prefix={<Building />} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: isBrowser ? 8 : 0 }}>
          <Button size="large" onClick={onSave} loading={isLoading}>{_t("form.save")}</Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default SettingInfoPage;