import { Button, Form, FormInstance, Input, Radio } from "antd";
import { isMobile } from "react-device-detect";
import { elasticities } from "../../utils/constants";
import { _t } from "../../utils/translate";
import { postData } from "../../utils/utils";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RuleObject } from "antd/es/form";

const StepOneComponent = ({ form, showToast, update, initMultiOption, refs }: { form: FormInstance, showToast: Function, update: Function, initMultiOption: string, refs: any[] }) => {
  const [searchParams] = useSearchParams();
  
  const [multiOption, setMultiOption] = useState<string>(initMultiOption);

  useEffect(() => {
    setMultiOption(initMultiOption);
  }, [initMultiOption])

  const validateRef = (_: RuleObject, value: any) => {
    if (refs.map(r => r.value).includes(value)) {
      showToast('error', _t("Reference exists already"));
      return Promise.reject(new Error(''));
    }
    return Promise.resolve();
  };

  const onNext = async () => {
    showToast('loading', _t('Checking reference...'));
    try {
      const values = await form.validateFields();

      try {
        const apiResult = await postData('api/fabric/check_ref', { ref: values['ref'], fabricId: searchParams.get("id") });
        apiResult && apiResult.status === 'success' && update('next-step');
      } catch (error) {
        form.setFieldValue('ref', '');
        form.setFields([{ name: "ref", errors: [''] }]);
        showToast('error', _t("error.refInvalid"));
      }
    } catch (error) {
      console.error(error);
      if (typeof error === "string") {
        showToast('error', _t(error));
      } else {
        showToast('error', _t("error.publishError"));
      }
    }
  }

  return (
    <>
      <Form.Item
        label={_t('form.ref')} name={"ref"} key={"ref"}
        initialValue={""} rules={[
          { required: true, message: '' },
          { validator: validateRef }
        ]}
      >
        <Input size="large" style={{ width: 300 }} />
      </Form.Item>
      <Form.Item
        label={_t("form.intendedUse")} name={"intendedUse"} key={"intendedUse"}
        initialValue={'form.clothing'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={'form.clothing'} key={'form.clothing'} className="text-base">{_t('form.clothing')}</Radio>
          <Radio value={'form.interFurnish'} key={'form.interFurnish'} className="text-base">{_t('form.interFurnish')}</Radio>
          <Radio value={'form.outFurnish'} key={'form.outFurnish'} className="text-base">{_t('form.outFurnish')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.colorType")} name={"colorType"} key={"colorType"}
        initialValue={initMultiOption}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large" onChange={(e) => setMultiOption(e.target.value)}>
          <Radio value={'form.unified'} key={'form.unified'} className="text-base">{_t('form.unified')}</Radio>
          <Radio value={'Printed'} key={'Printed'} className="text-base">{_t('Printed')}</Radio>
          <Radio value={'Dyed woven'} key={'Dyed woven'} className="text-base">{_t('Dyed woven')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.structure")} name={"structure"} key={"structure"}
        initialValue={'form.knit'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={'form.knit'} key={'form.knit'} className="text-base">{_t('form.knit')}</Radio>
          <Radio value={'form.warpWeft'} key={'form.warpWeft'} className="text-base">{_t('form.warpWeft')}</Radio>
          <Radio value={'form.warpWeftJacquard'} key={'form.warpWeftJacquard'} className="text-base">{_t('form.warpWeftJacquard')}</Radio>
          <Radio value={'Nonwoven'} key={'Nonwoven'} className="text-base">{_t('Nonwoven')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.elasticity")} name={"elasticity"} key={"elasticity"}
        initialValue={elasticities[0]}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          {elasticities.map(elasticity => (
            <Radio value={elasticity} key={elasticity} className="text-base">{_t(elasticity)}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.volume")} name={"volume"} key={"volume"}
        initialValue={'form.flat'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={'form.flat'} key={'form.flat'} className="text-base">{_t('form.flat')}</Radio>
          <Radio value={'form.embossed'} key={'form.embossed'} className="text-base">{_t('form.embossed')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.ironingPleated")} name={"ironingPleated"} key={"ironingPleated"}
        initialValue={'form.unpleated'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={'form.unpleated'} key={'form.unpleated'} className="text-base">{_t('form.unpleated')}</Radio>
          <Radio value={'form.pleated'} key={'form.pleated'} className="text-base">{_t('form.pleated')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.turbularOpen")} name={"turbularOpen"} key={"turbularOpen"}
        initialValue={'form.open'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={'form.open'} key={'form.open'} className="text-base">{_t('form.open')}</Radio>
          <Radio value={'form.turbular'} key={'form.turbular'} className="text-base">{_t('form.turbular')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.ply")} name={"ply"} key={"ply"}
        initialValue={'form.single'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={'form.single'} key={'form.single'} className="text-base">{_t('form.single')}</Radio>
          <Radio value={'form.double'} key={'form.double'} className="text-base">{_t('form.double')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <Button size="large" onClick={onNext}>{_t("form.next")}</Button>
      </Form.Item>
    </>
  )
}

export default StepOneComponent;