import { Button, Card, Form, Input, InputNumber, Modal, Radio, RadioChangeEvent, Upload, UploadFile, UploadProps, message } from "antd";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { _t, messages } from "../../utils/translate";
import { primaryColors, shadowColors } from "../../utils/constants";
import { ColorDropdownType } from "@/utils/types";
import { useEffect, useState } from "react";
import { Check, Palette } from "lucide-react";
import { RootState } from "@/redux/store";
import { FABRIC_URI, convertResultFormToApi, customFilterOption, postData, uploadData } from "../../utils/utils";
import { CloseCircleOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

const DuplicateUnitedPage = ({ fabric }: { fabric: Record<string, any> }) => {
  const { lang } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [primaryColor, setPrimaryColor] = useState<string>("");
  const [mode, setMode] = useState<string>("light");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, showSuccessModal] = useState<boolean>(false);
  const [publishedId, setPublishedId] = useState<number>(-1);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState('');
  const [fileImage, setFileImage] = useState<File>();
  const [isImageEmpty, setIsImageEmpty] = useState<boolean>(false);

  const buildColorItem = (color: ColorDropdownType, index: number) => ({
    label: <div className="flex gap-x-2 justify-between">
      <Palette />
      <span className="text-base font-semibold">{_t(color.label)}</span>
      <span style={{ width: 50, height: 24, borderRadius: 8, backgroundColor: color.color, boxShadow: '0 1px 2px black' }}></span>
    </div>,
    realValue: color.label,
    // value: messages[lang][color.label],
    value: color.label,
    key: index,
  });

  const primaryItems = primaryColors
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));

  // Unified Mode
  const shadeItems = (shadowColors[`${mode}${primaryColor}`] ? shadowColors[`${mode}${primaryColor}`] : [])
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));

  useEffect(() => {
    // form.setFieldValue('title', fabric['title']);

    // const primaryColor = fabric['principalColor'];
    // setPrimaryColor(primaryColor);
    // const primaryIndex = primaryColors.findIndex((e: ColorDropdownType) => e.label === primaryColor);
    // if (primaryIndex !== -1) form.setFieldValue('principalColor', buildColorItem(primaryColors[primaryIndex], primaryIndex));
    // const mode = fabric['color'] === 'form.light' ? 'light' : 'dark';
    // setMode(mode);
    // form.setFieldValue('color', fabric['color']);
    // const shadeColors = shadowColors[`${mode}${primaryColor}`];
    // if (shadeColors) {
    //   const shadeIndex = shadeColors.findIndex((e: ColorDropdownType) => e.label === fabric['colorShade']);
    //   if (shadeIndex !== -1) form.setFieldValue('colorShade', buildColorItem(shadeColors[shadeIndex], shadeIndex));
    // }

    // form.setFieldValue('currentStock', fabric['currentStock']);
    // form.setFieldValue('upcomingShipmentQuantity', fabric['upcomingShipmentQuantity']);
    // form.setFieldValue('availabilityIn6Weeks', fabric['availabilityIn6Weeks']);

    // fabric['image'] != "" && setPreviewImage(FABRIC_URI + '/' + fabric['image']);
    // form.setFieldValue('image', fabric['image'] ? fabric['image'] : "");
  }, [])

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 5
    });
  }

  const onModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value === 'form.light' ? 'light' : 'dark');
    form.setFieldValue('colorShade', "");
  }

  const onPrimaryChange = (e: any) => {
    setPrimaryColor(e.realValue);
    form.setFieldValue('colorShade', "");
  }

  const onPublish = async () => {
    setIsLoading(true);

    // if (fabric['colorShade'] === form.getFieldValue('colorShade').realValue) {
    //   showToast('error', _t('You have a fabric with the same color, please choose another color'));
    //   setIsLoading(false);
    //   return;
    // }

    try {
      setIsImageEmpty(form.getFieldValue('image') === undefined || form.getFieldValue('image').toString() === "undefined" || form.getFieldValue('image') === "");
      await form.validateFields();
    } catch (error) {
      showToast('error', _t('error.publishError'));
      setIsLoading(false);
      return;
    }

    const values = await form.getFieldsValue();
    const convertValues = convertResultFormToApi(values);

    try {
      // Check if the reference and color is not duplicated
      await postData('api/fabric/check-dupllicated-color', {
        ref: fabric['ref'],
        principalColor: convertValues['principalColor'],
        colorShade: convertValues['colorShade'],
      });

      // Check 
      if (fileImage) {
        let data = new FormData();
        data.append('file', fileImage!);
        const imageResult = await uploadData('api/fabric/file-upload', data);
        form.setFieldValue('image', imageResult.key);
      }

      const result = { ...fabric, ...form.getFieldsValue() };
      if (!result['image']) result['image'] = "";
      const apiResult = await postData('api/fabric/duplicate', JSON.stringify(convertResultFormToApi(result)))
      setPublishedId(apiResult.id);
      showSuccessModal(true);
    } catch (error: any) {
      console.error(error);
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message))
      } else {
        showToast('error', _t("An error occured with connecting to the server"));
      }
    } finally {
      setIsLoading(false);
    }
  }

  const onSeePreview = () => {
    navigate(publishedId === -1 ? '/' : `/fabrics/details?id=${publishedId}`);
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);

  const beforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      if (event.target !== null) {
        console.log(event.target);
        setPreviewImage(event.target.result as string);
      }
    }
    setFileImage(file);
    return Upload.LIST_IGNORE; // Returning LIST_IGNORE to prevent file from being added to fileList
  }

  const onDuplicateAnother = () => {
    showSuccessModal(false);
    setTimeout(() => navigate('/space/fabrics'), 200);
  }

  const onFinish = () => {
    showSuccessModal(false);
    setTimeout(() => navigate('/'), 200);
  }

  return (
    <div className="flex flex-col items-center">
      {contextHolder}
      <div className="flex flex-col items-center mb-3">
        <div className="w-full md:w-[768px] px-5">
          <h3 className="font-bold text-5xl text-center mt-12 mb-3 mx-5 text-blue-800">{_t("Duplicate a reference")}</h3>
          <p className="font-semibold text-xl text-center mb-3 text-blue-600">{_t("Step")} 1/1: {_t(`duplicate.step1Title`)}</p>
        </div>
      </div>
      <Card className="flex justify-center mb-5">
        <Form
          form={form}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          className="w-full md:w-[800px]"
        >
          <Form.Item
            label={_t("form.principalColor")} name={"principalColor"} key={"principalColor"}
            rules={[{ required: true, message: '' }]} initialValue={""}
          >
            <Select
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("principalColor").length > 0 ? '1px solid red' : '' }) }}
              options={primaryItems}
              onChange={onPrimaryChange}
              placeholder=""
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Form.Item
            label={_t("form.color")} name={"color"} key={"color"}
            initialValue={"form.light"}
            rules={[{ required: true, message: '' }]}
          >
            <Radio.Group size="large" onChange={onModeChange}>
              <Radio value={"form.light"} key={"form.light"} className="text-base">{_t("form.light")}</Radio>
              <Radio value={"form.dark"} key={"form.dark"} className="text-base">{_t("form.dark")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={_t("form.colorShade")} name={"colorShade"} key={"colorShade"}
            rules={[{ required: true, message: '' }]} initialValue={""}
          >
            <Select
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("principalColor").length > 0 ? '1px solid red' : '' }) }}
              options={shadeItems}
              placeholder=""
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.currentStock")}</span>}
            name={"currentStock"} key={"currentStock"}
            initialValue={''}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber size="large" style={{ width: 300 }} addonAfter={fabric['unit']} />
          </Form.Item>
          {/* <Form.Item
            label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.upcomingShipmentQuantity")}</span>}
            name={"upcomingShipmentQuantity"} key={"upcomingShipmentQuantity"}
            initialValue={''}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber size="large" style={{ width: 300 }} addonAfter={fabric['unit']} />
          </Form.Item> */}
          <Form.Item
            label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.quantityAvailableInTwoWeeks")}</span>}
            name={"quantityAvailableInTwoWeeks"} key={"quantityAvailableInTwoWeeks"}
            initialValue={''}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber size="large" style={{ width: 300 }} addonAfter={fabric['unit']} />
          </Form.Item>
          <Form.Item
            label={<span style={{ maxWidth: 280, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.availabilityIn6Weeks")}</span>}
            name={"availabilityIn6Weeks"} key={"availabilityIn6Weeks"}
            initialValue={''}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber size="large" style={{ width: 300 }} addonAfter={fabric['unit']} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
            <Button size="large" style={{ width: 300 }} loading={isLoading} onClick={onPublish}>{_t("form.publish")}</Button>
          </Form.Item>
        </Form>
      </Card>
      <Modal title={_t("Success")} open={isSuccess} centered footer={<></>} closable={false}>
        <div className="flex flex-col items-center gap-y-2">
          <Check size={64} color="#00ff9d" />
          <span className="text-2xl font-bold text-blue-700 text-center">{_t("error.fabricDuplicated")}</span>
          <div className="flex gap-x-3 mt-3">
            <Button type="primary" size="large" onClick={onDuplicateAnother}>{_t("Duplicate another fabric")}</Button>
            <Button type="primary" size="large" onClick={onFinish}>{_t("Finish")}</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DuplicateUnitedPage;