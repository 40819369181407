import { useEffect, useMemo, useState } from "react"
import { Button, Card, Form, Input, InputNumber, Popconfirm } from "antd"
import Select from "react-select"
import PhoneInput from "react-phone-input-2"
import { City, Country, State } from "country-state-city"
import { _t } from "../../utils/translate"
import { isBrowser } from "react-device-detect"
import { convertResultFormToApi, customFilterOption, postData } from "../../utils/utils"

const SpaceEditUser = ({
  user,
  showToast,
  onRefresh,
}: {
  user: Record<string, any>
  showToast: Function
  onRefresh: Function
}) => {
  const [form] = Form.useForm()

  const [type, setType] = useState<string>("")
  const [selectedCountry, setSelectedCountry] = useState<string>("")
  const [selectedState, setSelectedState] = useState<string>("")

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const countries = Country.getAllCountries()

  const states = useMemo(() => {
    if (!form.getFieldValue("country")) return []

    const country = countries.find(
      (c) => c.name === form.getFieldValue("country").value
    )
    return country ? State.getStatesOfCountry(country.isoCode) : []
  }, [selectedCountry])

  const cities = useMemo(() => {
    if (!form.getFieldValue("country")) return []

    const country = countries.find(
      (c) => c.name === form.getFieldValue("country").value
    )
    const state = states.find(
      (c) => c.name === form.getFieldValue("state").value
    )
    return country && state
      ? City.getCitiesOfState(country.isoCode, state.isoCode)
      : []
  }, [selectedState])

  const userTypeOptions = [
    { label: _t("details.seller"), value: "SELLER" },
    { label: _t("details.visitor"), value: "VISITOR" },
    { label: _t("details.admin"), value: "ADMIN" },
  ]

  useEffect(() => {
    form.setFieldsValue({
      "type":
        userTypeOptions[
          userTypeOptions.findIndex((option) => option.value === user.type)
        ],
      "fullName": user.fullName,
      "email": user.email,
      "phone": user.phone,
      "sellerPhone": user.sellerPhone,
      "businessId": user.businessId,
      "streetName": user.streetName,
      "streetNumber": user.streetNumber,
      "neighborhood": user.neighborhood,
      "country": { value: user.country, label: user.country },
      "state": { value: user.state, label: user.state },
      "city": { value: user.city, label: user.city },
      "postalCode": user.postalCode,
    })
    setType(user.type)
    setSelectedCountry(user.country)
    setSelectedState(user.state)
  }, [])

  const onFormChange = () => {
    setType(form.getFieldValue("type").value)
    setSelectedCountry(form.getFieldValue("country"))
    setSelectedState(form.getFieldValue("state"))
  }

  const onSaveUser = async (id: string, values: Record<string, any>) => {
    setIsSaving(true)
    try {
      await postData("api/auth/edit-user", {
        id,
        user: convertResultFormToApi(values),
      })

      showToast("success", _t("User has been edited successfully"))
      onRefresh()
    } catch (error: any) {
      if (error.data && error.data.message) {
        showToast("error", _t(error.data.message))
      } else {
        showToast("error", _t("An error occured with connecting to the server"))
      }
    } finally {
      setIsSaving(false)
    }
  }

  const onDeleteUser = async (id: string) => {
    setIsDeleting(true)

    try {
      await postData("api/auth/delete-user", { id })

      showToast("success", _t("User has been deleted successfully"))
      onRefresh()
    } catch (error: any) {
      if (error.data && error.data.message) {
        showToast("error", _t(error.data.message))
      } else {
        showToast("error", _t("An error occured with connecting to the server"))
      }
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <div className='flex justify-center'>
      <Card>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          layout='horizontal'
          className='w-full md:w-[800px]'
          onValuesChange={onFormChange}
        >
          <Form.Item
            label={_t("User Type")}
            name={"type"}
            key='type'
            rules={[{ required: true, message: "" }]}
          >
            <Select
              isSearchable
              placeholder=''
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%",
                  borderRadius: 4,
                  border:
                    form.getFieldError("type").length > 0
                      ? "1px solid red"
                      : "",
                  fontSize: 16,
                }),
              }}
              options={userTypeOptions}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Form.Item
            label={_t("form.fullName")}
            name={"fullName"}
            key={"fullName"}
            rules={[{ required: true, message: "" }]}
          >
            <Input size='large' />
          </Form.Item>
          <Form.Item
            label={_t("form.email")}
            name={"email"}
            key={"email"}
            rules={[{ required: true, message: "" }]}
          >
            <Input size='large' />
          </Form.Item>
          <Form.Item
            label={_t("form.directPhone")}
            name={"phone"}
            key={"phone"}
            rules={[{ required: true, message: "" }]}
          >
            <PhoneInput
              country={"ma"}
              inputStyle={{
                width: "100%",
                height: 40,
                borderRadius: 8,
                outline:
                  form.getFieldError("directPhone").length > 0
                    ? "1px solid red"
                    : "",
                border:
                  form.getFieldError("directPhone").length > 0
                    ? "none"
                    : "1px solid #CACACA",
                fontSize: 16,
              }}
              buttonStyle={{ borderRadius: 8 }}
            />
          </Form.Item>
          {type !== "VISITOR" ? (
            <>
              <Form.Item
                label={_t("form.sellerPhone")}
                name={"sellerPhone"}
                key={"sellerPhone"}
                rules={[{ required: true, message: "" }]}
              >
                <PhoneInput
                  country={"ma"}
                  inputStyle={{
                    width: "100%",
                    height: 40,
                    borderRadius: 8,
                    outline:
                      form.getFieldError("sellerPhone").length > 0
                        ? "1px solid red"
                        : "",
                    border:
                      form.getFieldError("sellerPhone").length > 0
                        ? "none"
                        : "1px solid #CACACA",
                    fontSize: 16,
                  }}
                  buttonStyle={{ borderRadius: 8 }}
                />
              </Form.Item>
              <Form.Item
                label={_t("form.uniqueBusinessId")}
                name={"businessId"}
                key={"businessId"}
                rules={[{ required: true, message: "" }]}
              >
                <Input size='large' className='w-full' />
              </Form.Item>
              <Form.Item
                label={_t("form.streetNumber")}
                name={"streetNumber"}
                key={"streetNumber"}
                rules={[{ required: true, message: "" }]}
              >
                <InputNumber size='large' className='w-full' />
              </Form.Item>
              <Form.Item
                label={_t("form.streetName")}
                name={"streetName"}
                key={"streetName"}
                rules={[{ required: true, message: "" }]}
              >
                <Input size='large' />
              </Form.Item>
              <Form.Item
                label={_t("form.neighborhood")}
                name={"neighborhood"}
                key={"neighborhood"}
                rules={[{ required: true, message: "" }]}
              >
                <Input size='large' />
              </Form.Item>
              <Form.Item
                label={_t("form.country")}
                name={"country"}
                key={"country"}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  isSearchable
                  placeholder=''
                  styles={{
                    container: (base) => ({
                      ...base,
                      width: "100%",
                      borderRadius: 4,
                      border:
                        form.getFieldError("country").length > 0
                          ? "1px solid red"
                          : "",
                      fontSize: 16,
                    }),
                  }}
                  options={countries.map((country) => {
                    return { value: country.name, label: country.name }
                  })}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                  onChange={() => {
                    form.setFieldsValue({ "state": "", "city": "" })
                    setSelectedState("")
                  }}
                  filterOption={customFilterOption}
                />
              </Form.Item>
              {states.length ? (
                <Form.Item
                  label={_t("form.state")}
                  name={"state"}
                  key={"state"}
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    isSearchable
                    placeholder=''
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "100%",
                        borderRadius: 4,
                        border:
                          form.getFieldError("state").length > 0
                            ? "1px solid red"
                            : "",
                        fontSize: 16,
                      }),
                    }}
                    options={states.map((state) => {
                      return { value: state.name, label: state.name }
                    })}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    onChange={() => {
                      form.setFieldsValue({ "city": "" })
                    }}
                    filterOption={customFilterOption}
                  />
                </Form.Item>
              ) : (
                ""
              )}
              {cities.length ? (
                <Form.Item
                  label={_t("form.city")}
                  name={"city"}
                  key={"city"}
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    isSearchable
                    placeholder=''
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "100%",
                        borderRadius: 4,
                        border:
                          form.getFieldError("city").length > 0
                            ? "1px solid red"
                            : "",
                        fontSize: 16,
                      }),
                    }}
                    options={cities.map((city) => {
                      return { value: city.name, label: city.name }
                    })}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    filterOption={customFilterOption}
                  />
                </Form.Item>
              ) : (
                ""
              )}
              <Form.Item
                label={_t("form.postalCode")}
                name={"postalCode"}
                key={"postalCode"}
                rules={[{ required: true, message: "" }]}
              >
                <Input size='large' />
              </Form.Item>
            </>
          ) : (
            ""
          )}
          <Form.Item wrapperCol={{ offset: isBrowser ? 8 : 0, span: 16 }}>
            <div className='w-full flex'>
              <Button
                block
                type='default'
                size='large'
                loading={isSaving}
                onClick={() => onSaveUser(user.id, form.getFieldsValue())}
              >
                {_t("form.save")}
              </Button>
              <Popconfirm
                title={_t("Delete User")}
                description={_t("Are you sure to delete this user?")}
                okText={_t("Yes")}
                cancelText={_t("No")}
                onConfirm={() => onDeleteUser(user.id)}
              >
                <Button
                  block
                  type='primary'
                  danger
                  size='large'
                  loading={isDeleting}
                >
                  {_t("Delete")}
                </Button>
              </Popconfirm>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default SpaceEditUser
