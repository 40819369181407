import { useEffect, useState } from "react";
import { Input, Radio, Space, RadioChangeEvent, Pagination, PaginationProps, Table, TableProps, Empty } from "antd";

import SpaceHeader from "../../components/space/space-header";
import { ArrowDown, ArrowUp } from "lucide-react";

import { FABRIC_URI, fetchData } from "../../utils/utils";
import { ColorDropdownType, HistoryDataType } from "../../utils/types";
import { _t } from "../../utils/translate";
import { primaryColors, shadowColors } from "../../utils/constants";
import { isBrowser } from "react-device-detect";

const { Search } = Input;

const HistoryPage = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [sortByDate, setSortByDate] = useState<boolean>(false); //  Old2New(T), New2Old(F)
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [historyData, setHistoryData] = useState<HistoryDataType[]>([]);

  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const pageSize = 10;

  useEffect(() => {
    loadHistories(page, searchText, sortByDate);
  }, [page, searchText, sortByDate])

  const renderType = (text: string) => {
    let color = "black";
    switch (text) {
      case "Edit":
        color = "#FACC15";
        break;
      case "Delete":
        color = "#DC2626";
        break;
      case "Addition":
        color = "#3B82F6";
        break;
      case "Exit":
        color = "#10B981";
        break;
      case "Discount":
        color = "#15803D";
        break;
      case "Duplicate":
        color = "#777";
        break;
    }
    return <span className="font-semibold" style={{ color: color }}>{_t(text)}</span>;
  }

  const renderQuantity = (value: number, record: Record<string, any>) => {
    if (value === 0) return <span className="text-blue">-</span>;

    if (record.type === 'Discount') {
      let result = "";
      if (value > 0) result += "+";
      result += value.toString();
      result += "%";
      return <span style={{ color: value > 0 ? '#F87171' : '#22C55E' }}>{result}</span>;
    } else if (record.type === 'Exit' || record.type === 'Addition') {
      let result = "";
      if (value > 0) result += "+";
      result += value.toString();
      result += "mts";
      return <span style={{ color: value > 0 ? '#3B82F6' : '#22C55E' }}>{result}</span>;
    }
  }

  const renderIncome = (value: number) => {
    if (value === 0) return <span className="text-blue">-</span>;

    let result = "+";
    result += value.toString();
    result += "mad";
    return <span style={{ color: '#22C55E' }}>{result}</span>;
  }

  const renderColorShade = (text: string, record: Record<string, any>) => {
    const primaryColor = primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === text)].label;
    const shadeItems = [...shadowColors[`light${primaryColor}`], ...shadowColors[`dark${primaryColor}`]];
    const shadeColor = shadeItems[shadeItems.findIndex((e: ColorDropdownType) => e.label === record.colorShade)].color;

    return (
      <div className="flex justify-center">
        {record.image === ""
          ? <div className="w-[50px] h-[50px] rounded-full" style={{ backgroundColor: shadeColor, boxShadow: '0 0 3px black' }}></div>
          : <img className="w-[50px] h-[50px] rounded-full" style={{ backgroundColor: shadeColor, boxShadow: '0 0 3px black' }} src={FABRIC_URI + '/' + record.image} />
        }
      </div>
    );
  }

  const renderDate = (text: string) => {
    const parts = new Date(text).toLocaleString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).split(', ');
    return `${parts[1]}   ${parts[0]}`;
  }

  const columns: TableProps<HistoryDataType>['columns'] = [
    { title: _t("form.ref"), align: 'center', dataIndex: 'ref', key: 'ref' },
    { title: _t("Update Type"), align: 'center', dataIndex: 'type', key: 'type', render: (text: string) => renderType(text) },
    { title: _t("form.quantity"), align: 'center', dataIndex: 'quantity', key: 'quantity', render: (value: number, record) => renderQuantity(value, record) },
    {
      title: _t("form.color"), align: 'center', dataIndex: 'principalColor', key: 'color', render: (text, record) => renderColorShade(text, record)
    },
    { title: _t("Income"), align: 'center', dataIndex: 'income', key: 'income', render: (value: number) => renderIncome(value) },
    {
      title: _t("Date"), align: 'center', dataIndex: 'date', key: 'date', render: (text: string) => renderDate(text)
    },
  ]

  const loadHistories = async (page: number, searchText: string, sortByDate: boolean) => {
    setIsLoading(true);
    try {
      const apiResult = await fetchData("api/history/read", { from: (page - 1) * pageSize, to: page * pageSize - 1, searchText, sortByDate })

      console.log(apiResult);

      if (apiResult) {
        const histories: HistoryDataType[] = apiResult.histories;
        const total: number = apiResult.total;
        setHistoryData(histories);
        setTotalCount(total);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const onSearchChange = (text: string) => {
    setPage(1);
    setSearchText(text);
    loadHistories(page, text, sortByDate);
  }

  const onDateChange = (e: RadioChangeEvent) => {
    setSortByDate(e.target.value);
    loadHistories(page, searchText, e.target.value);
  }

  const onPageChange: PaginationProps['onChange'] = (page: number) => {
    setPage(page);
  };

  return (
    <div className='p-2 md:p-10'>
      <SpaceHeader />
      <div className="flex flex-col md:flex-row mt-5 gap-x-5">
        <div className="py-3">
          <div className="flex flex-col gap-4">
            <Search size="large" onSearch={onSearchChange} className="w-full md:w-[300px]" />
            <div className="flex flex-row md:flex-col justify-between">
              <div className="flex flex-col">
                <p className="text-lg font-bold text-blue-700">{_t("Sort by Date")}: </p>
                <Radio.Group size="large" onChange={onDateChange} value={sortByDate} className="p-3">
                  <Space direction={isBrowser ? "vertical" : "horizontal"}>
                    <Radio value={true}><div className="flex items-center text-base text-blue-400 font-semibold">{_t("form.old2New")} <ArrowUp /></div></Radio>
                    <Radio value={false}><div className="flex items-center text-base text-blue-400 font-semibold">{_t("form.new2Old")} <ArrowDown /></div></Radio>
                  </Space>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <Table
            className="custom-table w-full overflow-auto"
            bordered columns={columns} dataSource={historyData.map((data, index) => ({ ...data, key: data.ref + index }))}
            pagination={false} loading={isLoading}
            locale={{ emptyText: <Empty description={<span className="text-base font-bold">{<span className="text-blue-800">{_t("No data")}</span>}</span>} /> }}
          />
          <div className="flex justify-center mt-3">
            <Pagination pageSize={pageSize} total={totalCount} current={page} onChange={onPageChange} showSizeChanger={false} showLessItems={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryPage;