import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { _t } from "../../utils/translate";

const ScrollIndicator = () => {
  const [showScrollContainer, setShowScrollContainer] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;

      // Adjust visibility based on scroll position
      setShowScrollContainer(scrollPercentage < 100); // Show when not at bottom
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onScrollDown = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }

  return (
    <>
      {showScrollContainer && <div
        className='scroll-container'
        onClick={onScrollDown}
      >
        <span>{_t("Scroll down")}</span>
        <div className='w-[36px] h-[64px] rounded-2xl border border-[#1d4ed8] border-2 relative'>
          <DownOutlined className='icon-indicator left-[8px] bottom-[8px]' />
        </div>
      </div>}
    </>
  )
}

export default ScrollIndicator;