import { useEffect, useState } from "react";
import { Button, Dropdown, Form, FormInstance, InputNumber, Radio, RadioChangeEvent } from "antd";
import Select from 'react-select';
import { _t } from "../../utils/translate";
import { OptionType } from "@/utils/types";
import { Country } from "country-state-city";
import { customFilterOption } from "../../utils/utils";


const MoreCountryComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const countries = Country.getAllCountries();

  useEffect(() => {
    updateFilter({
      "stockCountry": form.getFieldValue('stockCountry'),
    });
  }, []);

  const onSelectChange = (newOption: OptionType) => {
    updateFilter({ "stockCountry": newOption });
  }

  return (
    <>
      <Form.Item name="stockCountry" className="flex-1" style={{ marginBottom: 4 }}>
        <Select
          isSearchable
          placeholder=""
          styles={{ container: (base) => ({ ...base, width: '100%', borderRadius: 4, fontSize: 16 }) }}
          options={countries.map((country) => { return { value: country.name, label: country.name }; })}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          onChange={onSelectChange}
          filterOption={customFilterOption}
        />
      </Form.Item>
    </>
  )
}

export default MoreCountryComponent;
