import { useState } from 'react';
import { Button, Card, Col, Row } from 'antd';

import SpaceHeader from '../../components/space/space-header';

import SettingInfoPage from '../../components/settings/info';
import SettingPasswordPage from '../../components/settings/password';
import SettingDeletePage from '../../components/settings/delete';
import { BrowserView, MobileView } from 'react-device-detect';
import { _t } from '../../utils/translate';

const SettingsPage = () => {
  const [page, setPage] = useState<string>('info');

  let renderPage: React.ReactNode = <></>;

  switch (page) {
    case 'info':
      renderPage = <SettingInfoPage />;
      break;
    case 'password':
      renderPage = <SettingPasswordPage />;
      break;
    case 'delete':
      renderPage = <SettingDeletePage />;
      break;
  }

  return (
    <div className='p-2 md:p-10'>
      <SpaceHeader />
      <BrowserView className='mt-5'>
        <Row>
          <Col span={6} className='flex flex-col gap-y-3 justify-start items-start'>
            <Button type={'link'} size='large' className='underline' onClick={() => setPage('info')}>{_t("setting.editInfo")}</Button>
            <Button type={'link'} size='large' className='underline' onClick={() => setPage('password')}>{_t("setting.editPassword")}</Button>
            <Button type={'link'} danger size='large' className='underline' onClick={() => setPage('delete')}>{_t("setting.deleteAccount")}</Button>
          </Col>
          <Col span={18}>
            <Card>
              {renderPage}
            </Card>
          </Col>
        </Row>
      </BrowserView>
      <MobileView className='mt-5'>
        <div className='flex flex-col'>
          <Button type={'link'} size='large' className='underline' onClick={() => setPage('info')}>{_t("setting.editInfo")}</Button>
          <Button type={'link'} size='large' className='underline' onClick={() => setPage('password')}>{_t("setting.editPassword")}</Button>
          <Button type={'link'} danger size='large' className='underline' onClick={() => setPage('delete')}>{_t("setting.deleteAccount")}</Button>
        </div>
        <div className='mt-3'>
          {page === 'info' ? <SettingInfoPage /> : <SettingPasswordPage />}
        </div>
      </MobileView>
    </div>
  )
}

export default SettingsPage;