import { useEffect } from "react";
import { Form, FormInstance, InputNumber } from "antd";
import { _t } from "../../utils/translate";

const BasicWidthComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  useEffect(() => {
    updateFilter({
      "widthMin": form.getFieldValue('widthMin'),
      "widthMax": form.getFieldValue('widthMax')
    });
  }, []);

  const onMinChange = (value: number | null) => {
    updateFilter({'widthMin': value});
  }

  const onMaxChange = (value: number | null) => {
    updateFilter({'widthMax': value});
  }

  return (
    <>
      <Form.Item name="widthMin" label={_t("form.min")} style={{ marginBottom: 4 }}>
        <InputNumber size="large" addonAfter={"MTS"} onChange={onMinChange} />
      </Form.Item>
      <Form.Item name="widthMax" label={_t("form.max")} style={{ marginBottom: 0 }}>
        <InputNumber size="large" addonAfter={"MTS"} onChange={onMaxChange} />
      </Form.Item>
    </>
  )
}

export default BasicWidthComponent;
