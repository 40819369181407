import { BrowserRouter as Router, Routes, Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './redux/store';

import MainLayout from './components/layout/mainlayout';

import DashboardPage from './pages/dashboard';
import LoginPage from './pages/auth/login';
import RegisterPage from './pages/auth/register';
import CreateSellerPage from './components/auth/createSeller';
import CreateVisitorPage from './components/auth/createVisitor';
import ResetPasswordPage from './pages/auth/resetPassword';
import BecomeSellerPage from './pages/auth/becomeSeller';
import Publish2Page from './pages/fabrics/publish2';
import EditPage from './pages/fabrics/edit';
import FabricDuplicatePage from './pages/fabrics/duplicate';
import FabricsPage from './pages/space/fabrics';
import HistoryPage from './pages/space/history';
import UsersPage from './pages/space/users';
import EditUserPage from './pages/space/editUser';
import SettingsPage from './pages/auth/settings';
import FabricResearchPage from './pages/fabrics/research';
import FabricDetailsPage from './pages/fabrics/details';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<MainLayout />}>
      <Route index element={<DashboardPage />} />
      <Route path='/auth/login' element={<LoginPage />} />
      <Route path='/auth/register' element={<RegisterPage />} />
      <Route path='/auth/create-seller' element={<CreateSellerPage />} />
      <Route path='/auth/create-visitor' element={<CreateVisitorPage />} />
      <Route path='/auth/reset-password' element={<ResetPasswordPage />} />
      <Route path='/auth/settings' element={<SettingsPage />} />
      <Route path='/auth/becomeSeller' element={<BecomeSellerPage />} />
      <Route path='/space/fabrics' element={<FabricsPage />} />
      <Route path='/space/history' element={<HistoryPage />} />
      <Route path='/space/users' element={<UsersPage />} />
      <Route path='/space/users/edit' element={<EditUserPage />} />
      <Route path='/fabrics/publish' element={<Publish2Page />} />
      <Route path='/fabrics/edit' element={<EditPage />} />
      <Route path='/fabrics/duplicate' element={<FabricDuplicatePage />} />
      <Route path='/fabrics/research' element={<FabricResearchPage />} />
      <Route path='/fabrics/details' element={<FabricDetailsPage />} />
    </Route>
  )
)

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<div className="w-full h-full flex justify-center items-center">Loading...</div>} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
}

export default App;
