import { useEffect, useState } from "react";
import { Button, Dropdown, Form, FormInstance, InputNumber, Radio, RadioChangeEvent } from "antd";
import Select from 'react-select';
import { _t } from "../../utils/translate";
import { compositionTypesData, elasticities } from "../../utils/constants";
import { OptionType } from "@/utils/types";
import { CloseCircleFilled } from "@ant-design/icons";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { customFilterOption } from "../../utils/utils";

const BasicCompositionComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: boolean }) => {
  const [count, setCount] = useState<number>(1);
  const [array, setArray] = useState<number[]>([1]);
  const [selectedCompositions, setSelectedCompositions] = useState<string[]>(["", "", "", ""]);
  const [availableCompositions, setAvailableCompositions] = useState<OptionType[]>([]);
  const { lang } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    updateFilter({
      "compName1": form.getFieldValue('compName1'),
      "compMin1": form.getFieldValue('compMin1'),
      "compMax1": form.getFieldValue('compMax1'),
      "compName2": form.getFieldValue('compName2'),
      "compMin2": form.getFieldValue('compMin2'),
      "compMax2": form.getFieldValue('compMax2'),
      "compName3": form.getFieldValue('compName2'),
      "compMin3": form.getFieldValue('compMin2'),
      "compMax3": form.getFieldValue('compMax2'),
      "compName4": form.getFieldValue('compName3'),
      "compMin4": form.getFieldValue('compMin3'),
      "compMax4": form.getFieldValue('compMax3'),
      "compName5": form.getFieldValue('compName4'),
      "compMin5": form.getFieldValue('compMin4'),
      "compMax5": form.getFieldValue('compMax4'),
    });
    const initSelection = [
      form.getFieldValue('compName1') ? form.getFieldValue('compName1').realValue : undefined,
      form.getFieldValue('compName2') ? form.getFieldValue('compName2').realValue : undefined,
      form.getFieldValue('compName3') ? form.getFieldValue('compName3').realValue : undefined,
      form.getFieldValue('compName4') ? form.getFieldValue('compName4').realValue : undefined,
    ];
    setSelectedCompositions(initSelection);
    setAvailableCompositions(
      compositionTypesData
        .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
        .map(val => {
        return { realValue: val, value: messages[lang][val], label: _t(val), disabled: initSelection.includes(val) };
      })
    );
  }, []);

  useEffect(() => {
    setSelectedCompositions(["", "", "", ""]);
    setAvailableCompositions(
      compositionTypesData
        .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
        .map(val => {
        return { realValue: val, value: messages[lang][val], label: _t(val), disabled: false };
      })
    );
  }, [reset])

  const onSelectChange = (index: number, option: OptionType) => {
    const tempRecord: Record<string, any> = {};
    tempRecord[`compName${index}`] = option;
    updateFilter(tempRecord);
    selectedCompositions[index] = option.realValue;
    setSelectedCompositions(selectedCompositions);
    setAvailableCompositions(
      compositionTypesData
        .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
        .map(val => {
        return { realValue: val, value: messages[lang][val], label: _t(val), disabled: selectedCompositions.includes(val) };
      })
    );
  }

  const onAddMore = () => {
    if (count === 4) return;
    setCount(count + 1);
    setArray([...array, count + 1]);
  }

  const onRemove = () => {
    setCount(count - 1);
    const anotherArray = [...array.slice(0, count - 1)];
    setArray(anotherArray);
    const tempRecord: Record<string, any> = {};
    tempRecord[`compName${count}`] = undefined;
    tempRecord[`compMin${count}`] = undefined;
    tempRecord[`compMax${count}`] = undefined;
    updateFilter(tempRecord);
    
    const initSelection = [
      form.getFieldValue('compName1') ? form.getFieldValue('compName1').realValue : undefined,
      count - 1 > 2 && form.getFieldValue('compName2') ? form.getFieldValue('compName2').realValue : undefined,
      count - 1 > 3 && form.getFieldValue('compName3') ? form.getFieldValue('compName3').realValue : undefined,
      count - 1 > 4 && form.getFieldValue('compName4') ? form.getFieldValue('compName4').realValue : undefined,
    ];
    setSelectedCompositions(initSelection);
    console.log(initSelection);
    setAvailableCompositions(
      compositionTypesData
        .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
        .map(val => {
        return { realValue: val, value: messages[lang][val], label: _t(val), disabled: initSelection.includes(val) };
      })
    );
  }

  const onInputChange = (index: string, value: string) => {
    const tempRecord: Record<string, any> = {};
    tempRecord[index] = value;
    updateFilter(tempRecord);
    const initSelection = [
      form.getFieldValue('compName1') ? form.getFieldValue('compName1').realValue : undefined,
      count - 1 > 2 && form.getFieldValue('compName2') ? form.getFieldValue('compName2').realValue : undefined,
      count - 1 > 3 && form.getFieldValue('compName3') ? form.getFieldValue('compName3').realValue : undefined,
      count - 1 > 4 && form.getFieldValue('compName4') ? form.getFieldValue('compName4').realValue : undefined,
    ];
    setSelectedCompositions(initSelection);
    console.log(initSelection);
    setAvailableCompositions(
      compositionTypesData
        .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
        .map(val => {
        return { realValue: val, value: messages[lang][val], label: _t(val), disabled: initSelection.includes(val) };
      })
    );
  }

  return (
    <>
      {array.map((_, index)  => (
        <div className="relative pl-4" key={"composition" + index}>
          { index !== 0 && index === array.length - 1 && <Button 
            type="primary" danger icon={<CloseCircleFilled />} 
            className="absolute top-[3px] left-[-18px]"
            onClick={onRemove}
          /> }
          <Form.Item name={`compName${index + 1}`} className="flex-1" style={{ marginBottom: 4 }}>
            <Select
              styles={{ container: (base) => ({ ...base, fontSize: 16, borderRadius: 4 }) }}
              isSearchable
              options={availableCompositions}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              isOptionDisabled={(option: any) => option.disabled}
              onChange={(value: any) => onSelectChange(index + 1, value)}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <div className="flex gap-x-2">
            <Form.Item name={`compMin${index + 1}`} style={{ marginBottom: 4 }}>
              <InputNumber size="large" placeholder="min" onChange={(value) => onInputChange(`compMin${index + 1}`, value ? value.toString() : "")} />
            </Form.Item>
            <Form.Item name={`compMax${index + 1}`} style={{ marginBottom: 4 }}>
              <InputNumber size="large" placeholder="max" onChange={(value) => onInputChange(`compMax${index + 1}`, value ? value.toString() : "")} />
            </Form.Item>
          </div>
        </div>
      ))}
      <Button onClick={onAddMore} size="large">Add more</Button>
    </>
  )
}

export default BasicCompositionComponent;
