import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import SwitchToSellerPage from '../../components/auth/switchToSeller';
import { _t } from "../../utils/translate";

const AuthBecomeSeller = () => {
  const navigate = useNavigate();

  const [visible, setVisible] = useState<boolean>(false);

  const renderOptionPage = () => (
    <div className="flex flex-col items-center gap-8">
      <div className="mt-24 mb-12 text-center">
        <h3 className="text-3xl md:text-5xl font-bold text-blue-800 mb-2">{_t("register.becomeSeller")}</h3>
        <p className="text-lg md:text-2xl font-semibold text-blue-600">{_t("register.becomeSellerDesc")}</p>
      </div>

      <Button size={'large'} onClick={() => setVisible(true)}>{_t("register.becomeSeller")}</Button>
      <div className="relative w-[350px]">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t border-blue-500"></span>
        </div>
        <div className="relative flex justify-center text-md font-semibold uppercase text-blue-500">
          <span className="bg-white px-3">{_t("login.or")}</span>
        </div>
      </div>
      <Button size={'large'} onClick={() => navigate('/')}>{_t("register.continueAsVisitor")}</Button>
    </div>
  );

  return visible ? <SwitchToSellerPage /> : renderOptionPage();
}

export default AuthBecomeSeller;