import { useEffect, useState } from "react";
import { Checkbox, Form, FormInstance, Space } from "antd";
import { _t } from "../../utils/translate";
import { commercialNames } from "../../utils/constants";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

const BasicCommercialNameComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const [values, setValues ] = useState<string[]> ([]);
  const { lang } = useSelector((state: RootState) => state.auth);
 
  useEffect(() => updateFilter({'commercialName': form.getFieldValue('commercialName')}), []);

  const onChange = (values: any) => {
    setValues(values);
    updateFilter({'commercialName': values});
  }

  return (
    <Form.Item name="commercialName" initialValue={undefined} style={{ height: 150, overflowY: 'auto', marginBottom: 0 }}>
      <Checkbox.Group value={values} onChange={onChange}>
        <Space direction="vertical">
          {commercialNames
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map(type => <Checkbox key={"commercialName" + type} value={type}>{_t(type)}</Checkbox>)}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  )
}

export default BasicCommercialNameComponent;
