import { Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { postData } from "../../utils/utils";
import { _t } from "../../utils/translate";
import { LoadingOutlined } from "@ant-design/icons";
import DuplicateUnitedPage from "../../components/duplicate/United";
import DuplicateMultiColorPage from "../../components/duplicate/MultiColor";

const FabricDuplicate = () => {
  const [searchParams] = useSearchParams();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [fabric, setFabric] = useState<Record<string, any>>({});
  const [curStep, setCurStep] = useState<number>(1);
  const [totalStep, setTotalStep] = useState<number>(1);
  
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) readFabricById(id);

    const ref = searchParams.get("ref");
    if (ref) readFabricByRef(ref);
  }, [])

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const readFabricById = async (id: string) => {
    setIsPageLoading(true);

    try {
      const apiResult = await postData('api/fabric/edit', { id: searchParams.get("id"), method: 'get' });
      const apiFabric = apiResult.fabric;
      setFabric(apiResult.fabric);

      if (apiFabric['colorType'] !== 'form.unified') setTotalStep(1);
      else setTotalStep(2);
    } catch (error: any) {
      console.error(error);
      if (error.data.message) {
        showToast('error', _t(error.data.message))
      }
    } finally {
      setIsPageLoading(false);
    }
  }

  const readFabricByRef = async (id: string) => {
    setIsPageLoading(true);

    try {
      const apiResult = await postData('api/fabric/edit', { ref: searchParams.get("ref"), method: 'get' });
      const apiFabric = apiResult.fabric;
      setFabric(apiResult.fabric);

      if (apiFabric['colorType'] !== 'form.unified') setTotalStep(1);
      else setTotalStep(2);
    } catch (error: any) {
      console.error(error);
      if (error.data.message) {
        showToast('error', _t(error.data.message))
      }
    } finally {
      setIsPageLoading(false);
    }
  }

  return (
    <>
      {contextHolder}
      <Spin spinning={isPageLoading} indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} fullscreen />
      { fabric && fabric['colorType'] === 'form.unified' && <DuplicateUnitedPage fabric={fabric} /> }
      { fabric && fabric['colorType'] !== 'form.unified' && <DuplicateMultiColorPage fabric={fabric} /> }
    </>
  )
}

export default FabricDuplicate;