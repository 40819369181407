import { jwtDecode } from 'jwt-decode';
import type { GetProp, UploadProps } from 'antd';
import axios, { AxiosError } from 'axios';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

const apiUrl = process.env.REACT_APP_API_URL;

export const FABRIC_URI = process.env.REACT_APP_FABRIC_IMAGE_URI;

export function verifyJwtToken(token: string) {
  const decoded = jwtDecode(token);
  if (decoded.exp != null && decoded.exp * 1000 > new Date().getTime()) {
    return decoded;
  } else {
    return null;
  }
}

export const fetchData = async (url: string, query: any) => {
  const queryParams = new URLSearchParams(query).toString();
  const fullUrl = `${apiUrl}/${url}?${queryParams}`;

  try {
    const response = await axios.get(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      // eslint-disable-next-line
      throw {
        data: axiosError.response.data,
        status: axiosError.response.status
      };
    }
  }
}

export const postData = async (url: string, data: any) => {
  try {
    const response = await axios.post(`${apiUrl}/${url}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      // eslint-disable-next-line
      throw {
        data: axiosError.response.data,
        status: axiosError.response.status
      };
    }
  }
}

export const uploadData = async (url: string, data: any) => {
  try {
    const response = await axios.post(`${apiUrl}/${url}`, data);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      // eslint-disable-next-line
      throw {
        data: axiosError.response.data,
        status: axiosError.response.status
      };
    }
  }
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const setAuthToken = (token: string | null) => {
  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

// Set the token on initial load
const token = localStorage.getItem('token');
setAuthToken(token);

// Function to extract the country code from a phone number string
export function parsePhoneNumber(phoneNumberString: string): string {
  const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString("+" + phoneNumberString);
  if (phoneNumber)
    return `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;
  return " - ";
}

export const convertResultFormToApi = (formData: Record<string, any>) => {
  let result: Record<string, any> = {};
  Object.keys(formData).forEach((key) => {
    if (formData[key] === null) return;
    if (Array.isArray(formData[key])) {
      result[key] = formData[key].map((val: any) => typeof val === 'object' && val.realValue ? val.realValue : val);
    } else if (typeof formData[key] === 'object') {
      result[key] = formData[key].realValue ? formData[key].realValue : formData[key].value;
    } else result[key] = formData[key];
  });
  return result;
}

export function formatNumber(num: number): string {
  const hasDecimals = num % 1 !== 0;
  return new Intl.NumberFormat('en-US', {
    useGrouping: true,
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  }).format(num);
}

export const customFilterOption = (option: { value: string }, searchText: string) => {
  if (!searchText) return true;
  return option.value.toLowerCase().startsWith(searchText.toLowerCase());
}