import React, { useEffect, useState } from "react";
import { Checkbox, Form, FormInstance, Space } from "antd";
import { _t } from "../../utils/translate";
import { stepTypesData } from "../../utils/constants";
import { messages } from '../../utils/translate';
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

const BasicFabricUsageComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const [values, setValues ] = useState<string[]>([]);
  const { lang } = useSelector((state: RootState) => state.auth);
 
  useEffect(() => {
    updateFilter({'fabricUsage': form.getFieldValue('fabricUsage')});
  }, []);

  const onChange = (values: string[]) => {
    setValues(values);
    updateFilter({'fabricUsage': values});
  }

  return (
    <Form.Item name="fabricUsage" initialValue={undefined} style={{ height: 150, overflowY: 'auto', marginBottom: 0 }}>
      <Checkbox.Group value={values} onChange={onChange}>
        <Space direction="vertical">
          {stepTypesData
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map(type => <Checkbox key={"basicFabricUsage" + type} value={type}>{_t(type)}</Checkbox>)}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  );
}

export default BasicFabricUsageComponent;
