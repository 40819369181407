import { useEffect, useState } from "react";
import { Form, FormInstance, Radio, Space } from "antd";
import { _t } from "../../utils/translate";

const OtherStructureComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: Boolean }) => {
  const [prevState, setPrevState] = useState<string>("");

  useEffect(() => {
    updateFilter({
      "structure": form.getFieldValue('structure'),
    });
    setPrevState(form.getFieldValue('structure'));
  }, []);

  useEffect(() => {
    setPrevState("");
  }, [reset])

  const handleClick = (value: any) => () => {
    setPrevState(prevState !== "" && prevState === value ? "" : value);
    updateFilter({ 'structure': prevState !== "" && prevState === value ? undefined : value });
  }

  return (
    <>
      <Form.Item name="structure" style={{ marginBottom: 4 }}>
        <Radio.Group size="large">
          <Space direction="vertical">
            <Radio value={'form.knit'} key={'structure' + 'form.knit'} className="text-base" onClick={handleClick('form.knit')}>{_t('form.knit')}</Radio>
            <Radio value={'form.warpWeft'} key={'structure' + 'form.warpWeft'} className="text-base" onClick={handleClick('form.warpWeft')}>{_t('form.warpWeft')}</Radio>
            <Radio value={'form.warpWeftJacquard'} key={'structure' + 'form.warpWeftJacquard'} className="text-base" onClick={handleClick('form.warpWeftJacquard')}>{_t('form.warpWeftJacquard')}</Radio>
            <Radio value={'Nonwoven'} key={'structure' + 'Nonwoven'} className="text-base" onClick={handleClick('Nonwoven')}>{_t('Nonwoven')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default OtherStructureComponent;
