import { Button, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../../redux/authSlice";
import { postData } from "../../utils/utils";
import { _t } from "../../utils/translate";

const SettingDeletePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onDeleteMe = async () => {
    try {
      const apiResult = await postData('api/auth/delete-me', {});
      localStorage.removeItem('token');
      dispatch(setAuth(null));
      navigate('/auth/login');
    } catch (error: any) {
      console.error(error.data);
      if (error.data.message) {
        showToast('error', _t(error.data.message));
      }
    }
  }

  return (
    <div className="h-[300px] flex flex-col items-center justify-around">
      {contextHolder}
      <span className="text-4xl font-bold text-blue-800">
        {_t("Do you want to delete your account?")}
      </span>
      <Button type="primary" danger size="large" onClick={onDeleteMe}>{_t("Yes, delete my account")}</Button>
    </div>
  )
}

export default SettingDeletePage;