import { ColorDropdownType } from "./types"

export const PUBLISH_STEP_GENERAL_INFORMATION = 1
export const PUBLISH_STEP_FABRIC_INFORMATION = 2
export const PUBLISH_STEP_COMMERCIAL_INFORMATION = 3
export const PUBLISH_STEP_COMPOSITION = 4
export const PUBLISH_STEP_SOLID_COLOR = 5
export const PUBLISH_STEP_FABRIC_USUAGE = 6
export const PUBLISH_STEP_PERFORMANCE = 7
export const PUBLISH_STEP_CERTIFICATION = 8
export const PUBLISH_STEP_GENERAL_SPECIFICS = 9

export const stepTypesData = [
  "Bandana",
  "Bermuda shorts",
  "Blazer",
  "Swim trunks",
  "Peacoat",
  "Boxer briefs",
  "Cap",
  "Puffer jacket",
  "Lining",
  "Parka lining",
  "Shirt",
  "Suit",
  "Tie",
  "Kaftan",
  "Work blouse",
  "Blouse",
  "Surgical scrubs",
  "Bib apron",
  "School apron",
  "Tarps",
  "Chair",
  "Sofa",
  "Duvet",
  "Djellaba",
  "Scarf",
  "Vest",
  "Onesie",
  "Hoodies",
  "Shirt collar lining",
  "Suit lining",
  "Joggers",
  "Skirt",
  "Leggings",
  "Lingerie",
  "Swimsuit",
  "Sports jersey",
  "Coat",
  "Bow tie",
  "Work pants",
  "Work jacket",
  "Chef's jacket",
  "Chef's cap",
  "Bed sheet",
  "Armchair",
  "Mattress",
  "Tablecloths",
  "Parasol",
  "Curtain",
  "bed",
  "Mops",
  "Towels",
  "Moroccan lounge",
  "Pillowcase",
  "Tent",
  "Dish towel",
  "Deck chair",
  "City trousers",
  "Sportswear trousers",
  "Parka",
  "Sweater",
  "Polo",
  "Pajamas",
  "Dress",
  "Wedding dress",
  "Pocket bag",
  "Briefs",
  "Overshirt",
  "Sweatshirt",
  "T-shirt",
  "Women's suit",
  "Nurse pants",
  "Pocket lining",
  "Bathrobe",
  "Bath towels",
]

export const compositionTypesData = [
  "Acrylic",
  "Alpaca",
  "Angora",
  "Aramid",
  "Cashmere",
  "Hemp",
  "Chlorofiber",
  "Cotton",
  "Mercerized Cotton",
  "Pima Cotton",
  "Elastane / Spandex / Lycra",
  "Jute",
  "Kermel",
  "Kevlar",
  "Wool",
  "Virgin Wool",
  "Lambswool",
  "Linen",
  "Lurex",
  "Merino",
  "Microfiber",
  "Mohair",
  "Nomex",
  "Nylon",
  "Polyamide",
  "Polyester",
  "Polyethylene",
  "Polyurethane",
  "Raffia",
  "Rayon",
  "Recycled Polyester",
  "Tencel",
  "Twaron",
  "Viscose",
]

export const patternDrawingData: Record<string, string[]> = {
  "Animal": [
    "Fox",
    "Dog",
    "Horse",
    "Birds",
    "Panther",
    "Leopard",
    "Zebra",
    "Giraffe",
    "Panda",
  ],
  "Colorful": ["Graffiti"],
  "Floral": ["Trees", "Flowers"],
  "form.geometric": [
    "Circles",
    "Square",
    "Rectangle",
    "Diamond",
    "Fine stripes",
    "Thick stripes",
    "Mixed fine and thick stripes",
    "Smaller houndstooth",
    "Prince of Wales check",
    "Buffalo check",
    "Scottish plaid",
    "Tartan1",
    "Gingham1",
    "Small dots",
    "Large dots",
  ],
  "Ghotic": ["Skull", "Skeleton"],
  "Urban": ["Building", "Street"],
  "Landscape": ["Forest", "Grass", "Beach"],
  "Children": [
    "Cars",
    "trucks",
    "Bikes",
    "Dinosaurs",
    "Fairies",
    "Princesses",
    "Dancers",
    "Cartoons",
    "Superman",
    "Batman",
    "Panda",
  ],
}

export const commercialNames = [
  "Alcantara",
  "Alpaca",
  "Heavy Canvas",
  "Baghdad",
  "Baggins",
  "Batik",
  "BengaLine",
  "Bouclé",
  "Small Loop Terry",
  "Brocade",
  "Burlington",
  "Cashmere",
  "Canvas",
  "Chambray",
  "Charmeuse",
  "Mesh Chebka",
  "Herringbone",
  "Side 1x1",
  "Crepe",
  "Crepe Georgette",
  "Cretonne",
  "Leather",
  "Suede",
  "Damask",
  "Denim",
  "Lace",
  "Diamond Cloth",
  "Cloth",
  "Sponge",
  "Felt",
  "Net",
  "Lycra Net",
  "Fouta",
  "Fur",
  "Faux Fur",
  "Gabardine",
  "Georgette",
  "Interlock",
  "Jacquard",
  "Jersey",
  "Jute",
  "Jeans",
  "Wool",
  "Lambswool",
  "Lamé",
  "Linen",
  "Madras",
  "Merino",
  "Metis",
  "Milfa",
  "Mignonette",
  "Mohair",
  "Moleskin",
  "Brushed Fleece",
  "Regular Fleece",
  "Invisible Fleece",
  "Muslin",
  "Silk Muslin",
  "Nankeen",
  "Honeycomb",
  "Nubuck",
  "Organdy",
  "Organza",
  "Silk Organza",
  "Ottoman",
  "Oxford",
  "Pashmina",
  "Percale",
  "Houndstooth",
  "Piqué",
  "Poplin",
  "Prince of Wales",
  "Raffia",
  "Rayon, also known as viscose",
  "Resil",
  "Sabra",
  "Sari",
  "Satin",
  "SBM",
  "Serge",
  "Shetland",
  "Faux Leather",
  "Silk",
  "Softshell",
  "Suedine",
  "Taffeta",
  "Tartan",
  "Oilcloth",
  "Tailor's Canvas",
  "Tripure",
  "Tulle",
  "Tweed",
  "Twill",
  "Velvet",
  "Corduroy",
  "Shaved Velvet",
  "Gingham",
  "Vinyl",
  "Viscose",
  "Double-sided Fusible Interlining",
  "Single-sided Fusible Interlining",
  'Wax (or "African fabric")',
  "Sable",
]

export const elasticities = [
  "form.nonStretch",
  "form.monoStretch",
  "form.biStretch",
]

export const armors = [
  "Serge",
  "Jersey",
  "Poplin",
  "Fleece",
  "Herringbone",
  "Piqué",
  "Interlock",
  "Satin",
  "Canvas",
  "Jacquard",
]

export const treatments = {
  "ANTI": [
    "Anti Bacterial",
    "Anti Odor",
    "Anti Static",
    "Anti Stain",
    "Anti Stain Water-Repellent",
    "Anti UV",
    "Anti Waves",
  ],
  "Eco": [
    "Eco Biodegradable or Compostable",
    "Eco Bio-Based Polymers",
    "Eco Biological Material",
    "Eco Chemical-Reduced Impact Finishing",
    "Eco Recycled Material",
    "Eco Traceability",
    "Eco Waterless",
  ],
  "C3": [
    "Climate",
    "Windproof",
    "Water-Repellent",
    "Down Proof",
    "Fireproof",
    "Waterproof",
    "Chlorine and Salt Resistant",
    "Flame retardant",
  ],
  "C4": [
    "Breathable",
    "Easy Care",
    "High Resistance",
    "Impermeable-Breathable",
    "Iron-Free",
    "Multi-Layer",
    "Quick Drying",
    "Reflective",
    "Washable",
  ],
  "C5": [
    "Bio",
    "Food contact",
    "Metal-free",
    "Oeko-Tex",
    "Recycled",
    "Vegetable tanning",
  ],
}

export const certifications = [
  "Authentico",
  "BCI",
  "Bluesign",
  "Capony Style Audit",
  "Cradle to Cradle",
  "Detox",
  "EPD",
  "EU Ecolabel",
  "European Flax",
  "FSC",
  "GOTS",
  "GRS",
  "ICEC",
  "Masters of Linen",
  "Nativa",
  "Oeko-Tex Standard 100",
  "Oeko-Tex Made in Green",
  "Oeko-Tex Step",
  "Oeko-Tex Leather Standard (OTLS)",
  "Origine France Garantie",
  "RCS 100",
  "RCS Blended",
  "Reach",
  "RWS",
  "The Higg Index MSI",
  "USDA Biopreferred, Certified Biobased Product",
  "ZDHC",
]

export const primaryColors: ColorDropdownType[] = [
  { label: "Blue", color: "RGB(0,0,255)" },
  { label: "Green", color: "RGB(0,128,0)" },
  { label: "Yellow", color: "RGB(255,255,0)" },
  { label: "Orange", color: "RGB(255,128,0)" },
  { label: "Red", color: "RGB(255,0,0)" },
  { label: "Pink", color: "RGB(255,192,203)" },
  { label: "Purple", color: "RGB(128,0,128)" },
  { label: "Gray", color: "RGB(128,128,128)" },
  { label: "Brown", color: "RGB(150,75,0)" },
  { label: "Beige", color: "RGB(250,245,220)" },
  { label: "White", color: "RGB(255,255,255)" },
  { label: "Black", color: "RGB(0,0,0)" },
]

export const shadowColors: Record<string, ColorDropdownType[]> = {
  "lightBlue": [
    { label: "Opal", color: "RGB(242,255,255)" },
    { label: "Azure Haze", color: "RGB(240,255,255)" },
    { label: "Dragee", color: "RGB(222,242,255)" },
    { label: "Aquamarine", color: "RGB(121,247,247)" },
    { label: "Sky Blue", color: "RGB(38,197,237)" },
    { label: "Light Azure", color: "RGB(116,208,242)" },
    { label: "Maya", color: "RGB(115,192,250)" },
    { label: "Sky", color: "RGB(120,181,255)" },
    { label: "Smoke", color: "RGB(186,209,224)" },
    { label: "Empire", color: "RGB(142,189,219)" },
    { label: "Charron", color: "RGB(147,177,211)" },
    { label: "Cart", color: "RGB(143,163,199)" },
    { label: "Cerulean", color: "RGB(14,158,232)" },
    { label: "King", color: "RGB(49,142,232)" },
    { label: "Azure", color: "RGB(0,128,255)" },
    { label: "Electric", color: "RGB(43,117,255)" },
    { label: "Denim Blue", color: "RGB(10,112,255)" },
    { label: "Azure 2", color: "RGB(31,129,204)" },
  ],
  "darkBlue": [
    { label: "Royal", color: "RGB(65,105,225)" },
    { label: "Slate", color: "RGB(96,135,180)" },
    { label: "Steel", color: "RGB(58,141,186)" },
    { label: "Woad", color: "RGB(77,124,181)" },
    { label: "Cornflower", color: "RGB(83,114,173)" },
    { label: "Turquoise", color: "RGB(66,91,138)" },
    { label: "Lapis Lazuli", color: "RGB(37,97,156)" },
    { label: "Blue", color: "RGB(0,0,255)" },
    { label: "Egyptian", color: "RGB(17,51,166)" },
    { label: "Sapphire", color: "RGB(2,49,181)" },
    { label: "Cobalt", color: "RGB(34,66,125)" },
    { label: "Smalt", color: "RGB(0,50,153)" },
    { label: "Ultramarine", color: "RGB(43,0,153)" },
    { label: "Petroleum", color: "RGB(29,73,82)" },
    { label: "Klein", color: "RGB(32,22,125)" },
    { label: "Midnight", color: "RGB(0,51,102)" },
    { label: "Night", color: "RGB(15,5,107)" },
    { label: "Navy", color: "RGB(3,34,77)" },
  ],
  "lightGreen": [
    { label: "Water", color: "RGB(177,242,182)" },
    { label: "Opaline", color: "RGB(151,222,197)" },
    { label: "Spring", color: "RGB(0,255,128)" },
    { label: "Primary", color: "RGB(0,255,101)" },
    { label: "Jade", color: "RGB(135,232,144)" },
    { label: "Anise", color: "RGB(161,255,20)" },
    { label: "Pistachio", color: "RGB(191,245,115)" },
    { label: "Lime", color: "RGB(165,209,82)" },
    { label: "Lichen", color: "RGB(134,194,126)" },
    { label: "Almond", color: "RGB(130,196,108)" },
    { label: "Absinthe", color: "RGB(126,222,75)" },
    { label: "Parrot", color: "RGB(58,242,75)" },
    { label: "Meadow", color: "RGB(88,214,60)" },
    { label: "Emerald", color: "RGB(0,214,88)" },
    { label: "Apple", color: "RGB(52,201,36)" },
    { label: "Mint", color: "RGB(22,184,77)" },
    { label: "Leek", color: "RGB(76,166,106)" },
  ],
  "darkGreen": [
    { "label": "Moss", "color": "RGB(103,158,90)" },
    { "label": "Asparagus", "color": "RGB(124,161,92)" },
    { "label": "Khaki", "color": "RGB(121,138,51)" },
    { "label": "Olive", "color": "RGB(111,140,35)" },
    { "label": "Sinople", "color": "RGB(21,148,21)" },
    { "label": "Grass", "color": "RGB(58,158,35)" },
    { "label": "Military", "color": "RGB(89,102,67)" },
    { "label": "Veronese", "color": "RGB(89,112,46)" },
    { "label": "Avocado", "color": "RGB(86,130,83)" },
    { "label": "Mélèse", "color": "RGB(56,112,73)" },
    { "label": "Bottle", "color": "RGB(9,107,9)" },
    { "label": "Hooker", "color": "RGB(27,79,8)" },
    { "label": "Fir", "color": "RGB(9,82,40)" },
    { "label": "Imperial", "color": "RGB(0,87,27)" },
    { "label": "English", "color": "RGB(24,56,30)" },
    { "label": "Fluorescent", "color": "RGB(0,255,0)" },
  ],
  "lightYellow": [
    { label: "Yellow", color: "RGB(255,255,227)" },
    { label: "Canary", color: "RGB(255,255,160)" },
    { label: "Mimosa", color: "RGB(255,250,110)" },
    { label: "Lemon", color: "RGB(247,255,61)" },
    { label: "Topaz", color: "RGB(250,235,115)" },
    { label: "Corn", color: "RGB(255,221,117)" },
    { label: "Butter", color: "RGB(240,228,108)" },
    { label: "Cobalt", color: "RGB(255,240,0)" },
  ],
  "darkYellow": [
    { label: "Mars", color: "RGB(237,209,83)" },
    { label: "Aureolin", color: "RGB(240,211,67)" },
    { label: "Saffron", color: "RGB(242,213,22)" },
    { label: "Yellow Gold", color: "RGB(240,216,7)" },
    { label: "Chick", color: "RGB(247,228,94)" },
    { label: "Straw", color: "RGB(255,227,71)" },
    { label: "Imperial", color: "RGB(255,227,54)" },
    { label: "Wheat", color: "RGB(232,213,49)" },
    { label: "Buttercup", color: "RGB(252,220,18)" },
    { label: "Gold", color: "RGB(255,209,15)" },
    { label: "Mustard", color: "RGB(240,194,0)" },
    { label: "Yellow Ochre", color: "RGB(222,173,40)" },
    { label: "Fluorescent", color: "RGB(252,220,18)" },
  ],
  "lightOrange": [
    { label: "Mandarin", color: "RGB(255,163,71)" },
    { label: "Orange", color: "RGB(250,165,0)" },
    { label: "Gamboge", color: "RGB(240,156,14)" },
    { label: "Salmon", color: "RGB(247,141,84)" },
  ],
  "darkOrange": [
    { label: "Melon", color: "RGB(222,151,22)" },
    { label: "Pumpkin", color: "RGB(222,122,44)" },
    { label: "Apricot", color: "RGB(230,126,48)" },
    { label: "Carrot", color: "RGB(242,107,36)" },
    { label: "Orange", color: "RGB(237,128,17)" },
    { label: "Tangerine", color: "RGB(255,69,28)" },
    { label: "Coral", color: "RGB(232,63,0)" },
    { label: "Burnt Orange", color: "RGB(204,86,0)" },
    { label: "Fluorescent", color: "RGB(255,95,1)" },
  ],
  "lightRed": [
    { label: "Nasturtium", color: "RGB(255,94,77)" },
    { label: "Primary", color: "RGB(255,0,0)" },
    { label: "Fire Red", color: "RGB(255,28,0)" },
    { label: "Terracotta", color: "RGB(204,78,92)" },
    { label: "Vermilion", color: "RGB(252,71,38)" },
    { label: "Grenadine", color: "RGB(232,56,63)" },
    { label: "Crimson", color: "RGB(219,20,59)" },
    { label: "English", color: "RGB(247,35,12)" },
    { label: "Tomato", color: "RGB(222,40,22)" },
    { label: "Madder", color: "RGB(237,17,17)" },
  ],
  "darkRed": [
    { label: "Vermilion", color: "RGB(219,24,2)" }, // Duplicate label
    { label: "Scarlet", color: "RGB(237,0,0)" },
    { label: "Aniline", color: "RGB(235,0,0)" },
    { label: "Cardinal", color: "RGB(184,31,17)" },
    { label: "Crayfish", color: "RGB(189,32,2)" },
    { label: "Cherry", color: "RGB(186,11,11)" },
    { label: "Poppy", color: "RGB(199,8,0)" },
    { label: "Crushed Strawberry", color: "RGB(163,36,36)" },
    { label: "Turk", color: "RGB(168,17,2)" },
    { label: "Carmine", color: "RGB(150,0,24)" },
    { label: "Blood", color: "RGB(133,7,7)" },
    { label: "Bordeaux", color: "RGB(110,7,26)" },
    { label: "Garnet", color: "RGB(110,11,20)" },
    { label: "Burgundy", color: "RGB(107,13,13)" },
    { label: "Ox Blood", color: "RGB(115,8,0)" },
  ],
  "lightPink": [
    { label: "Nymph's Thigh", color: "RGB(255,232,240)" },
    { label: "Linen White", color: "RGB(250,240,230)" },
    { label: "Eggshell", color: "RGB(252,232,225)" },
    { label: "Dragee Pink", color: "RGB(255,191,212)" },
    { label: "Flesh", color: "RGB(255,196,173)" },
    { label: "Peach", color: "RGB(252,189,182)" },
    { label: "Incarnadine", color: "RGB(255,150,161)" },
  ],
  "darkPink": [
    { label: "Pink", color: "RGB(252,109,157)" },
    { label: "Tea Rose", color: "RGB(250,135,107)" },
    { label: "Candy Pink", color: "RGB(250,65,157)" },
    { label: "Fuchsia", color: "RGB(252,63,146)" },
    { label: "Bright Pink", color: "RGB(255,0,128)" },
    { label: "Fuchsia Magenta", color: "RGB(219,0,116)" },
    { label: "Raspberry", color: "RGB(199,44,72)" },
  ],
  "lightPurple": [
    { label: "Linen Grey", color: "RGB(211,204,237)" },
    { label: "Parma", color: "RGB(207,160,232)" },
    { label: "Heliotrope", color: "RGB(222,115,255)" },
    { label: "Mauve", color: "RGB(217,111,255)" },
    { label: "Lilac", color: "RGB(182,102,209)" },
    { label: "Lavender", color: "RGB(152,133,237)" },
    { label: "Byzantine", color: "RGB(189,51,164)" },
  ],
  "darkPurple": [
    { label: "Reddish Purple", color: "RGB(199,22,133)" },
    { label: "Violin", color: "RGB(161,6,132)" },
    { label: "Amethyst", color: "RGB(134,76,166)" },
    { label: "Violet", color: "RGB(103,0,153)" },
    { label: "Web Indigo", color: "RGB(75,0,130)" },
    { label: "Dark Magenta", color: "RGB(128,0,128)" },
    { label: "Purple", color: "RGB(158,14,65)" },
    { label: "Plum", color: "RGB(130,21,83)" },
    { label: "Bishop", color: "RGB(115,62,101)" },
    { label: "Zizolin", color: "RGB(109,2,120)" },
    { label: "Byzantium", color: "RGB(112,42,99)" },
    { label: "Aubergine", color: "RGB(56,0,41)" },
  ],
  "lightGray": [
    { label: "Aluminum", color: "RGB(240,240,240)" },
    { label: "Lead", color: "RGB(220,220,220)" },
    { label: "Silver", color: "RGB(207,207,207)" },
    { label: "Oxidized Tin", color: "RGB(186,186,186)" },
    { label: "Steel", color: "RGB(176,176,176)" },
  ],
  "darkGray": [
    { label: "Mouse", color: "RGB(158,158,158)" },
    { label: "Iron", color: "RGB(133,133,133)" },
    { label: "Gray", color: "RGB(97,97,97)" },
    { label: "Pearl", color: "RGB(49,51,50)" },
    { label: "Anthracite", color: "RGB(28,35,39)" },
  ],
  "lightBrown": [
    { label: "Chestnut", color: "RGB(166,103,38)" },
    { label: "Sunburnt", color: "RGB(140,109,66)" },
    { label: "Umber", color: "RGB(145,109,39)" },
    { label: "Bistre", color: "RGB(133,109,77)" },
    { label: "Tobacco", color: "RGB(158,84,30)" },
    { label: "Tawny", color: "RGB(175,80,89)" },
    { label: "Rust", color: "RGB(153,87,23)" },
    { label: "Auburn", color: "RGB(158,62,13)" },
  ],
  "darkBrown": [
    { label: "Burnt Sienna", color: "RGB(143,83,53)" },
    { label: "Dead Leaf", color: "RGB(153,81,43)" },
    { label: "Hazelnut", color: "RGB(148,86,40)" },
    { label: "Lavalliere", color: "RGB(143,89,34)" },
    { label: "Cinnamon", color: "RGB(125,87,52)" },
    { label: "Mahogany", color: "RGB(135,66,28)" },
    { label: "Brick", color: "RGB(133,46,27)" },
    { label: "Caramel", color: "RGB(125,50,0)" },
    { label: "Chocolate", color: "RGB(89,42,0)" },
    { label: "Walnut", color: "RGB(64,34,4)" },
    { label: "Cachou", color: "RGB(46,26,12)" },
  ],
  "lightBeige": [
    { label: "Beige", color: "RGB(250,245,220)" },
    { label: "Cream", color: "RGB(255,253,208)" },
    { label: "Blond", color: "RGB(250,240,119)" },
    { label: "Champagne", color: "RGB(250,240,182)" },
    { label: "Almond", color: "RGB(239,222,205)" },
    { label: "Banana Mania", color: "RGB(250,231,181)" },
    { label: "Bone", color: "RGB(227,218,201)" },
    { label: "Sand", color: "RGB(237,220,175)" },
    { label: "Ivory", color: "RGB(255,255,240)" },
    { label: "Antique", color: "RGB(250,235,215)" },
    { label: "Ecru", color: "RGB(255,250,230)" },
  ],
  "darkBeige": [
    { label: "Burlywood", color: "RGB(222,184,135)" },
    { label: "Soil", color: "RGB(184,161,120)" },
    { label: "Sepia", color: "RGB(173,137,99)" },
    { label: "Mud", color: "RGB(150,124,68)" },
  ],
  "lightWhite": [{ label: "White", color: "RGB(255,255,255)" }],
  "darkWhite": [{ label: "White", color: "RGB(255,255,255)" }],
  "lightBlack": [{ label: "Black", color: "RGB(0,0,0)" }],
  "darkBlack": [{ label: "Black", color: "RGB(0,0,0)" }],
}
