import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Form, FormInstance, Input, InputNumber, Modal, Radio, RadioChangeEvent, Tabs, TabsProps } from "antd";
import { isBrowser } from "react-device-detect";
import { Check, Palette, Receipt, Ruler, Settings, Tally4 } from "lucide-react";

import FabricMtsDetailsPage from "../fabric/fabricMtsDetails";
import FabricKgsDetailsPage from "../fabric/fabricKgsDetails";

import { primaryColors, shadowColors } from "../../utils/constants";
import { ColorDropdownType, FabricDetailType } from "../../utils/types";
import { FABRIC_URI, uploadData } from "../../utils/utils";
import { _t } from "../../utils/translate";
import ScrollIndicator from "../details/scrollIndicator";

// Fabric Confirm Component
const StepTenComponent = ({ form, details, showToast, update, fileImage, isLoading }:
  { form: FormInstance, details: Record<string, any>, showToast: Function, update: Function, fileImage: File | undefined, isLoading: boolean }
) => {
  const [previewImage, setPreviewImage] = useState<string>("");

  useEffect(() => {
    if (!fileImage) return;

    const reader = new FileReader();
    reader.readAsDataURL(fileImage);
    reader.onload = (event) => {
      if (event.target !== null) {
        setPreviewImage(event.target.result as string);
        form.setFieldValue('image', fileImage);
      }
    }
  }, [fileImage])

  const fabricDetails: any = useMemo(() => {
    const compVal1 = details['compVal1'] ? parseFloat(details['compVal1'].toString()) : 0;
    const compVal2 = details['compVal2'] ? parseFloat(details['compVal2'].toString()) : 0;
    const compVal3 = details['compVal3'] ? parseFloat(details['compVal3'].toString()) : 0;
    const compVal4 = details['compVal4'] ? parseFloat(details['compVal4'].toString()) : 0;

    let result: FabricDetailType = {
      structure: details['structure'],
      commercialName: details['commercialName'],
      fabricUsage: [details['type1'], details['type2']],
      armor: details['armor'],
      intendedUse: details['intendedUse'],
      colorType: details['colorType'],
      coef: details['coef'],
      compositionKeys: [details['compExp1'], details['compExp2'], details['compExp3'], details['compExp4']],
      compositionValues: [compVal1, compVal2, compVal3, compVal4],
      width: parseFloat(details['width']),
      unit: details['unit'],
      elasticity: details['elasticity'],
      volume: details['volume'],
      turbularOpen: details['turbularOpen'],
      ironingPleated: details['ironingPleated'],
      ply: details['ply'],
      colorShade: details['colorShade'],
      currentStock: details['currentStock'],
      quantityAvailableInTwoWeeks: details['quantityAvailableInTwoWeeks'],
      availabilityIn6Weeks: details['availabilityIn6Weeks'],
      grammage: details['grammage'],
      stockCity: details['stockCity'],
      stockState: details['stockState'],
      stockCountry: details['stockCountry'],
      origin: details['origin'],
      treatments: details['treatments'],
      certifications: details['certifications'],
      id: details['id'],
      currency: details['currency'],
      price: details['price'],
      ref: details['ref'],
      principalColor: details['principalColor'],
      image: details['image'] ? details['image'] : "",
      date: new Date().toString(),
      remise: details['remise'] ? details['remise'] : 0,

      pattern: details['pattern'],
      drawing: details['drawing'],
      drawingSurfaceCm: details['drawingSurfaceCm'],
      drawingWidth: details['drawingWidth'],
      drawingHeight: details['drawingHeight'],
      drawingPrincipalColor: details['drawingPrincipalColor'],
      drawingColorShade: details['drawingColorShade'],
      secondPrincipalColor: details['secondPrincipalColor'],
      secondColorShade: details['secondColorShade'],
      sellerName: details['sellerName']
    }
    if (details['type3']) result.fabricUsage.push(details['type3']);
    if (details['type4']) result.fabricUsage.push(details['type4']);
    if (details['type5']) result.fabricUsage.push(details['type5']);

    return result;
  }, [details])

  const shadeColor = useMemo(() => {
    if (!fabricDetails) return "";
    const primaryColor = primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === fabricDetails.principalColor)].label;
    const shadeItems = [...shadowColors[`light${primaryColor}`], ...shadowColors[`dark${primaryColor}`]];
    return shadeItems[shadeItems.findIndex((e: ColorDropdownType) => e.label === fabricDetails.colorShade)].color;
  }, [fabricDetails])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className="flex gap-x-2"><Settings /><span className="text-blue-800 font-bold text-lg">{_t("details.generalDetails")}</span></div>,
      children: fabricDetails?.colorType === 'form.unified' ? <FabricMtsDetailsPage details={fabricDetails} /> : <FabricKgsDetailsPage details={fabricDetails} />
    }
  ];

  const onEdit = () => {
    update('first-step');
  }

  const onPublish = async () => {
    try {
      if (fileImage) {
        update('data-submit');

        let data = new FormData();
        data.append('file', fileImage!);
        const apiResult = await uploadData('api/fabric/file-upload', data);
        form.setFieldValue('image', apiResult.key as string);
        update('update-property', { 'image': apiResult.key as string });

        update('data-submitted');
        update('final-step', { 'image': apiResult.key as string });
      } else {
        update('final-step', {});
      }
    } catch (error: any) {
      console.error(error);
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message));
      } else {
        showToast('error', _t("error.publishError"));
      }
    }
  }

  return (
    <div className="flex flex-col items-center m-5">
      <h1 className="text-2xl mb-3 font-bold text-blue-800">{_t("Recap")}</h1>
      <Card className="w-full max-w-[900px]" bordered={isBrowser} styles={{ body: { padding: isBrowser ? 24 : 0 } }}>
        <div className="flex flex-col md:flex-row gap-x-3">
          <div className="relative">
            {fabricDetails.image
              ? <img className="w-full md:w-[200px] h-[200px] rounded-md border-red-600 mb-3" src={FABRIC_URI + '/' + fabricDetails.image} loading="lazy" />
              // : <div className="w-full md:w-[200px] h-[200px]"><Skeleton.Image className="custom-skeleton-image rounded-md" /></div> }
              : (previewImage === ""
                ? <div className="w-full md:w-[200px] h-[200px] rounded-md border-red-600 mb-3" style={{ backgroundColor: shadeColor }}></div>
                : <img className="w-full md:w-[200px] h-[200px] rounded-md border-red-600 mb-3" src={previewImage} loading="lazy" />)
            }
            {fabricDetails.remise !== 0 && <span className="absolute top-[10px] right-[10px] px-2 py-1 bg-white border border-red-500 rounded-lg text-lg font-semibold text-red-600">-{fabricDetails.remise}%</span>}
          </div>
          <div className="flex-1 flex flex-col justify-between h-[200px] gap-y-3">
            <p className="text-xl font-bold text-blue-800 text-center md:mt-0 md:text-left">{fabricDetails.title}</p>
            <div className="flex flex-col md:flex-row justify-between w-full bg-[#EFF6FF] rounded-md p-5 gap-y-3 text-center" style={{ boxShadow: '1px 1px 2px #1E40AF' }}>
              <div className="flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5">
                <span className="flex gap-x-1 font-semibold text-blue-800"><Receipt size={32} /> {_t("form.priceText")}</span>
                {fabricDetails.remise === 0
                  ? <span>{fabricDetails.price} MAD/{fabricDetails.unit === 'Mts' ? 'm' : 'kg'}</span>
                  : <div className="flex flex-col">
                    <span className="">{(fabricDetails.price * (100 - fabricDetails.remise) / 100).toFixed(1)} MAD/{fabricDetails.unit === 'Mts' ? 'm' : 'kg'}</span>
                    <span className="line-through text-red-500 text-sm">{fabricDetails.price} MAD/{fabricDetails.unit === 'Mts' ? 'm' : 'kg'}</span>
                  </div>}
                {/* <span className="text-blue-600 font-semibold">{fabricDetails.price} <span className="text-base font-normal">MAD/{fabricDetails.unit === 'Mts' ? 'm' : 'kg'}</span></span> */}
              </div>
              <div className="flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5">
                <span className="flex gap-x-1 font-semibold text-blue-800"><Ruler size={32} /> {_t("form.quantity")}</span>
                <span className="text-blue-600 font-semibold">{fabricDetails.currentStock} <span className="text-base font-normal lowercase">{fabricDetails.unit}</span></span>
              </div>
              <div className="flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5">
                <span className="flex gap-x-1 font-semibold text-blue-800"><Tally4 size={32} /> {_t("form.ref")}</span>
                <span className="text-blue-600 font-semibold">{fabricDetails.ref}</span>
              </div>
              <div className="flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5">
                <span className="flex gap-x-1 font-semibold text-blue-800"><Palette size={32} /> {_t("form.color")}</span>
                <span className="w-[100px] h-[30px] rounded-md" style={{ boxShadow: '1px 1px 3px black', backgroundColor: shadeColor }}></span>
              </div>
            </div>
            <div className="h-[48px]"></div>
          </div>
        </div>
        <Tabs items={items} size='large' />
        <div className="flex justify-center gap-x-5 mt-5">
          <div className="flex-1 flex justify-end">
            <Button size="large" onClick={onEdit}>{_t("Edit")}</Button>
          </div>
          <div className="flex-1 flex justify-start">
            <Button size="large" type="primary" onClick={onPublish} loading={isLoading}>{_t("Confirm Publish")}</Button>
          </div>
        </div>
      </Card>
      <ScrollIndicator />
    </div>
  )
}

export default StepTenComponent;