import { useEffect, useState } from "react";
import { Form, FormInstance, Radio, Space } from "antd";
import { _t } from "../../utils/translate";

const AdvancedVolumeComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: Boolean }) => {
  const [prevState, setPrevState] = useState<string>("");

  useEffect(() => {
    updateFilter({
      "volume": form.getFieldValue('volume'),
    });
    setPrevState(form.getFieldValue('volume'));
  }, []);

  useEffect(() => {
    setPrevState("");
  }, [reset])

  const handleClick = (value: any) => () => {
    setPrevState(prevState !== "" && prevState === value ? "" : value);
    updateFilter({ 'volume': prevState !== "" && prevState === value ? undefined : value });
  }

  return (
    <>
      <Form.Item name="volume" style={{ marginBottom: 4 }}>
        <Radio.Group size="large">
          <Space direction="vertical">
            <Radio value={'form.flat'} key={'volume' + 'form.flat'} className="text-base" onClick={handleClick('form.flat')}>{_t('form.flat')}</Radio>
            <Radio value={'form.embossed'} key={'volume' + 'form.embossed'} className="text-base" onClick={handleClick('form.embossed')}>{_t('form.embossed')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default AdvancedVolumeComponent;
