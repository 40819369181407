import { useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ColorDropdownType, FabricDetailType } from "@/utils/types";
import { Divider } from "antd";
import { _t } from "../../utils/translate";
import { primaryColors, shadowColors } from "../../utils/constants";

const FabricKgsDetailsPage = ({ details }: { details: FabricDetailType | undefined }) => {
  const buildArray = (arr: string[]) => (
    arr.map((key, index) => <span key={key}>{_t(key)}{index === arr.length - 1 ? "" : ", "}</span>)
  )

  const buildCertifications = (arr: string[]) => (
    arr.map((key, index) => <span key={key}>{key}{index === arr.length - 1 ? "" : ", "}</span>)
  )

  const backPrimaryColor = details && details.principalColor 
    ? primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === details.principalColor)].color 
    : "-";
  const backShadeColor = useMemo(() => {
    if (!details) return "#FFF";
    const primaryColor = primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === details.principalColor)].label;
    const shadeItems = [...shadowColors[`light${primaryColor}`], ...shadowColors[`dark${primaryColor}`]];
    return shadeItems[shadeItems.findIndex((e: ColorDropdownType) => e.label === details.colorShade)].color;
  }, [details])

  const draw1PrimaryColor = details && details.drawingPrincipalColor 
    ? primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === details.drawingPrincipalColor)].color 
    : "-";
  const draw1ShadeColor = useMemo(() => {
    if (!details || !details.drawingPrincipalColor) return "";
    const primaryColor = primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === details.drawingPrincipalColor)].label;
    const shadeItems = [...shadowColors[`light${primaryColor}`], ...shadowColors[`dark${primaryColor}`]];
    const shadeIndex = shadeItems.findIndex((e: ColorDropdownType) => e.label === details.drawingColorShade);
    return shadeIndex === -1 ? "" : shadeItems[shadeIndex].color;
  }, [details])

  const draw2PrimaryColor = details && details.secondPrincipalColor 
  ? primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === details.secondPrincipalColor)].color 
  : "-";
  const draw2ShadeColor = useMemo(() => {
    if (!details || !details.secondPrincipalColor) return "";
    console.log(details);
    const primaryColor = primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === details.secondPrincipalColor)].label;
    const shadeItems = [...shadowColors[`light${primaryColor}`], ...shadowColors[`dark${primaryColor}`]];
    const shadeIndex = shadeItems.findIndex((e: ColorDropdownType) => e.label === details.secondColorShade);
    return shadeIndex === -1 ? "" : shadeItems[shadeIndex].color;
  }, [details])

  const buildComposition = (keys: string[], values: number[]) => {
    const compositions = values.map((value, index) => {
      if (value === 0) return null;
      return (
        <>{value}% {_t(keys[index])}</>
      );
    }).filter(Boolean);
    return compositions.map((composition, index) => (
      <span key={index}>{composition}{index === compositions.length - 1 ? "" : ", "}</span>
    ));
  };

  const buildInfo1 = () => details ? (
    <>
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.commercialName")}:</span>
        <span className="text-blue-600">{_t(details.commercialName)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.fabricUsage")}:</span>
        <span className="text-right text-blue-600">{buildArray(details.fabricUsage)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.armor")}:</span>
        <span className="text-blue-600">{_t(details.armor)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("Fabric for")}:</span>
        <span className="text-blue-600">{_t(details.intendedUse)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.colorType")}:</span>
        <span className="text-blue-600">{_t(details.colorType)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("details.composition")}:</span>
        <span className="text-right text-blue-600">{buildComposition(details.compositionKeys, details.compositionValues)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.elasticity")}:</span>
        <span className="text-blue-600">{_t(details.elasticity)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.volume")}:</span>
        <span className="text-blue-600">{_t(details.volume)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("Pleated or not pleated")}:</span>
        <span className="text-blue-600">{_t(details.ironingPleated)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.turbularOpen")}:</span>
        <span className="text-blue-600">{_t(details.turbularOpen)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("Single or double faced")}:</span>
        <span className="text-blue-600">{_t(details.ply)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("Knit or Warp and Weft")}:</span>
        <span className="text-blue-600">{_t(details.structure)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.quantity")}:</span>
        <span className="text-blue-600">{details.currentStock} {details.unit}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("details.densityGrammage")}:</span>
        <span className="text-blue-600">{details.grammage} Gr/m2</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.width")}:</span>
        <span className="text-blue-600">{details.width.toFixed(2)} MTS</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.formula")}:</span>
        <span className="text-blue-600">{(details.grammage * details.width * details.coef).toFixed(2)} Gr/ML</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.yield")}:</span>
        <span className="text-blue-600">{(1000 / (details.grammage * details.width * details.coef)).toFixed(2)} Mt/Kg</span>
      </div>
    </>
  ) : <></>;

  const buildInfo2 = () => details ? (
    <>
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.pattern")}:</span>
        <span className="text-blue-600">{_t(details.pattern!)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.drawing")}:</span>
        <span className="text-blue-600 text-right">{buildArray(details.drawing!)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.drawingSurfaceCm")}:</span>
        <span className="text-blue-600">{_t(details.drawingSurfaceCm!)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.drawingWidth")}:</span>
        <span className="text-blue-600">{_t(details.drawingWidth!)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.drawingHeight")}:</span>
        <span className="text-blue-600">{_t(details.drawingHeight!)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800 mr-2">{_t("form.backgroundColor")}:</span>
        <div className="flex-1 flex flex-col gap-y-2">
          <div className="flex justify-between">
            <span className="font-semibold text-blue-800">{_t("Principal")}:</span>
            <div className="flex gap-x-2 text-blue-600">{_t(details.principalColor)} <div className="w-[25px] h-[25px] rounded-md" style={{ backgroundColor: backPrimaryColor, boxShadow: '1px 1px 2px #777' }}></div></div>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-blue-800">{_t("Shade")}:</span>
            <div className="flex gap-x-2 text-blue-600">{_t(details.colorShade)} <div className="w-[25px] h-[25px] rounded-md" style={{ backgroundColor: backShadeColor, boxShadow: '1px 1px 2px #777' }}></div></div>
          </div>
        </div>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800 mr-2 max-w-[150px]">{_t("form.drawingPrincipalColor")}:</span>
        <div className="flex-1 flex flex-col gap-y-2">
          <div className="flex justify-between">
            <span className="font-semibold text-blue-800">{_t("Principal")}:</span>
            {details.drawingPrincipalColor
              ? <div className="flex gap-x-2 text-blue-600">{_t(details.drawingPrincipalColor!)} <div className="w-[25px] h-[25px] rounded-md" style={{ backgroundColor: draw1PrimaryColor, boxShadow: '1px 1px 2px #777' }}></div></div>
              : " - "}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-blue-800">{_t("Shade")}:</span>
            {details.drawingColorShade
              ? <div className="flex gap-x-2 text-blue-600">{_t(details.drawingColorShade!)} <div className="w-[25px] h-[25px] rounded-md" style={{ backgroundColor: draw1ShadeColor, boxShadow: '1px 1px 2px #777' }}></div></div>
              : " - "}
          </div>
        </div>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800 mr-2 max-w-[150px]">{_t("form.drawingSecondaryColor")}:</span>
        <div className="flex-1 flex flex-col gap-y-2">
          <div className="flex justify-between">
            <span className="font-semibold text-blue-800">{_t("Principal")}:</span>
            {details.secondPrincipalColor
              ? <div className="flex gap-x-2 text-blue-600">{_t(details.secondPrincipalColor!)} <div className="w-[25px] h-[25px] rounded-md" style={{ backgroundColor: draw2PrimaryColor, boxShadow: '1px 1px 2px #777' }}></div></div>
              : " - "}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-blue-800">{_t("Shade")}:</span>
            {details.secondColorShade
              ? <div className="flex gap-x-2 text-blue-600">{details.secondColorShade ? _t(details.secondColorShade) : ""} <div className="w-[25px] h-[25px] rounded-md" style={{ backgroundColor: draw2ShadeColor, boxShadow: '1px 1px 2px #777' }}></div></div>
              : " - "}
          </div>
        </div>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("details.fabricCountry")}:</span>
        <span className="text-blue-600">{details.stockCountry}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("details.fabricCity")}</span>
        <span className="text-blue-600">{details.stockCity ? details.stockCity : (details.stockState ? details.stockState : details.stockCountry)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.origin")}:</span>
        <span className="text-blue-600">{details.origin}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("details.treatments")}:</span>
        <span className="text-right text-blue-600">{buildArray(details.treatments)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("details.certifications")}:</span>
        <span className="text-right text-blue-600">{buildCertifications(details.certifications)}</span>
      </div>
    </>
  ) : <></>;

  return (
    <>
      <BrowserView className="flex justify-between text-base">
        <div className="flex-1">
          {buildInfo1()}
        </div>
        <div className="flex justify-center mx-2">
          <Divider type="vertical" className="h-full border-blue-500" />
        </div>
        <div className="flex-1">
          {buildInfo2()}
        </div>
      </BrowserView>
      <MobileView>
        {buildInfo1()}
        <Divider className="my-2" />
        {buildInfo2()}
      </MobileView>
    </>
  );
}

export default FabricKgsDetailsPage;