import { useEffect, useState } from "react";
import { Form, FormInstance, Radio, RadioChangeEvent, Space } from "antd";
import { _t } from "../../utils/translate";

const AdvancedDrawingSpaceComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: Boolean }) => {
  const [prevState, setPrevState] = useState<string>("");

  useEffect(() => {
    updateFilter({
      "drawingSurfaceCm": form.getFieldValue('drawingSurfaceCm'),
    });
    setPrevState(form.getFieldValue('drawingSurfaceCm'));
  }, []);

  useEffect(() => {
    setPrevState("");
  }, [reset])

  const handleClick = (value: any) => () => {
    setPrevState(prevState !== "" && prevState === value ? "" : value);
    updateFilter({ 'drawingSurfaceCm': prevState !== "" && prevState === value ? undefined : value });
  }

  return (
    <Form.Item name="drawingSurfaceCm" style={{ marginBottom: 0 }}>
      <Radio.Group size="large">
        <Space direction="vertical">
          <Radio value={'form.0to3'} key={'drawingSurfaceCm' + 'form.0to3'}  className="text-base" onClick={handleClick('form.0to3')}>{_t('form.0to3')}</Radio>
          <Radio value={"form.4to6"} key={'drawingSurfaceCm' + "form.4to6"} className="text-base" onClick={handleClick('form.4to6')}>{_t("form.4to6")}</Radio>
          <Radio value={"form.7to10"} key={'drawingSurfaceCm' + "form.7to10"} className="text-base" onClick={handleClick('form.7to10')}>{_t("form.7to10")}</Radio>
          <Radio value={"form.11to13"} key={'drawingSurfaceCm' + "form.11to13"} className="text-base" onClick={handleClick('form.11to13')}>{_t("form.11to13")}</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default AdvancedDrawingSpaceComponent;