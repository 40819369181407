
// { label: _t('form.commercialName'), name: "commercialName", type: 'dropdown', values: commercialNames },
//       { label: _t('form.armor'), name: "armor", type: 'dropdown', values: armors },

import { Button, Form, FormInstance } from "antd";
import Select from 'react-select';
import { _t } from "../../utils/translate";
import { armors, commercialNames } from "../../utils/constants";
import { isMobile } from "react-device-detect";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { customFilterOption } from "../../utils/utils";

const StepThreeComponent = ({ form, showToast, update }: { form: FormInstance, showToast: Function, update: Function }) => {
  const { lang } = useSelector((state: RootState) => state.auth);
  
  const onBack = async () => {
    update('prev-step');
  }

  const onNext = async () => {
    try {
      await form.validateFields();
      update('next-step');
    } catch (error) {
      console.error(error);
      showToast('error', _t("error.publishError"));
    }
  }

  return (
    <>
      <Form.Item
        label={_t('form.commercialName')} name={'commercialName'} key={'commercialName'}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError('commercialName').length > 0 ? '1px solid red' : '' }) }}
          placeholder={_t("form.chooseOne")}
          isSearchable
          options={commercialNames
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map((val) => { return { realValue: val, value: messages[lang][val], label: _t(val) }; })}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          filterOption={customFilterOption}
        />
      </Form.Item>
      <Form.Item
        label={_t('form.armor')} name={'armor'} key={'armor'}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError('armor').length > 0 ? '1px solid red' : '' }) }}
          placeholder={_t("form.chooseOne")}
          isSearchable
          options={armors
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map((val) => { return { realValue: val, value: messages[lang][val], label: _t(val), }; })}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          filterOption={customFilterOption}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <div className="flex gap-x-2">
          <Button size="large" onClick={onBack}>{_t("form.back")}</Button>
          <Button size="large" onClick={onNext}>{_t("form.next")}</Button>
        </div>
      </Form.Item>
    </>
  )
}

export default StepThreeComponent;