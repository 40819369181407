import { useEffect, useState } from "react";
import { Button, Dropdown, Form, FormInstance, InputNumber, MenuProps, Radio, RadioChangeEvent, Space } from "antd";
import Select from 'react-select';
import { _t } from "../../utils/translate";
import { primaryColors, shadowColors } from "../../utils/constants";
import { Palette } from "lucide-react";
import { ColorDropdownType } from "@/utils/types";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { customFilterOption } from "../../utils/utils";


const AdvancedDrawingColorTypeComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const { lang } = useSelector((state: RootState) => state.auth);

  const [primaryColor, setPrimaryColor] = useState<string>("");
  const [mode, setMode] = useState<string>("");

  const buildColorItem = (color: ColorDropdownType, index: number) => ({
    label: <div className="flex gap-x-2 justify-between">
      <Palette />
      <span className="text-base font-semibold">{_t(color.label)}</span>
      <span style={{ width: 50, height: 25, borderRadius: 8, backgroundColor: color.color, boxShadow: '0 1px 2px black' }}></span>
    </div>,
    value: messages[lang][color.label],
    realValue: color.label,
    key: index,
  });

  const primaryItems = primaryColors
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));
  const shadeItems = (shadowColors[`${mode}${primaryColor}`] ? shadowColors[`${mode}${primaryColor}`] : [])
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));

  useEffect(() => {
    if (form.getFieldValue('drawingPrincipalColor') && typeof form.getFieldValue('drawingPrincipalColor') === 'string') {
      setPrimaryColor(form.getFieldValue('drawingPrincipalColor'));
      if (form.getFieldValue('color') && form.getFieldValue('drawingColorShade') && typeof form.getFieldValue('drawingColorShade') === 'string') {
        const colorShades = shadowColors[`${form.getFieldValue('drawingColor') === 'form.light' ? 'light' : 'dark'}${form.getFieldValue('drawingPrincipalColor')}`];
        const index = colorShades
          .findIndex((item: ColorDropdownType) => item.label === form.getFieldValue('drawingColorShade'));
        if (index !== -1)
          form.setFieldValue('drawingColorShade', buildColorItem(colorShades[index], index));
      }

      const index = primaryColors.findIndex((item: ColorDropdownType) => item.label === form.getFieldValue('drawingPrincipalColor'));
      if (index !== -1)
        form.setFieldValue('drawingPrincipalColor', buildColorItem(primaryColors[index], index));
    }
    form.getFieldValue('drawingColor') && setMode(form.getFieldValue('drawingColor') === 'form.light' ? 'light' : 'dark');
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    updateFilter({ 'drawingColor': e.target.value, "drawingColorShade": "" });
    setMode(e.target.value === 'form.light' ? 'light' : 'dark');
    form.setFieldValue('drawingColorShade', "");
  }

  const onPrimaryChange = (e: any) => {
    setPrimaryColor(e.realValue);
    updateFilter({ "drawingPrincipalColor": e, "drawingColorShade": "" });
    form.setFieldValue('drawingColorShade', "");
  }

  const onShadeChange = (e: any) => {
    updateFilter({ "drawingColorShade": e });
  }

  const onClearPrimary = () => {
    onClearShade();
    updateFilter({
      "drawingPrincipalColor": "",
      "drawingColor": "",
      "drawingColorShade": "",
    });
    form.setFieldValue('drawingPrincipalColor', "");
    setMode("");
    form.setFieldValue('drawingColor', "");
    setPrimaryColor("");
  }

  const onClearShade = () => {
    updateFilter({
      "drawingColorShade": ""
    });
    form.setFieldValue('drawingColorShade', "");
  }

  return (
    <>
      <div className="flex">
        <Form.Item name="drawingPrincipalColor" style={{ marginBottom: 4 }} className="flex-1">
          <Select
            styles={{ container: (base) => ({ ...base, width: '100%', borderRadius: 4, fontSize: 16 }) }}
            options={primaryItems}
            onChange={onPrimaryChange}
            filterOption={customFilterOption}
          />
        </Form.Item>
        <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearPrimary}>{_t("Clear")}</Button>
      </div>
      <Form.Item name="drawingColor" style={{ marginBottom: 4 }}>
        <Radio.Group size="large" onChange={onChange}>
          <Space direction="horizontal">
            <Radio value={'form.light'} key={'drawingColor' + 'form.light'} className="text-base">{_t('form.light')}</Radio>
            <Radio value={'form.dark'} key={'drawingColor' + 'form.dark'} className="text-base">{_t('form.dark')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <div className="flex">
        <Form.Item name="drawingColorShade" style={{ marginBottom: 4 }} className="flex-1">
          <Select
            styles={{ container: (base) => ({ ...base, width: '100%', borderRadius: 4, fontSize: 16 }) }}
            options={shadeItems} onChange={onShadeChange} noOptionsMessage={() => messages[lang]["Please select primary color"]}
            filterOption={customFilterOption}
          />
        </Form.Item>
        <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearShade}>{_t("Clear")}</Button>
      </div>
    </>
  )
}

export default AdvancedDrawingColorTypeComponent;
