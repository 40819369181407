import { useEffect, useState } from "react";
import { Form, FormInstance, InputNumber, Radio, Space } from "antd";
import { _t } from "../../utils/translate";

const BasicQuantityComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  useEffect(() => {
    updateFilter({
      "currentStockUnit": form.getFieldValue('currentStockUnit'),
      "currentStockMin": form.getFieldValue('currentStockMin'),
      "currentStockMax": form.getFieldValue('currentStockMax')
    });
  }, []);

  const onUnitChange = (e: any) => {
    updateFilter({ 'currentStockUnit': e.target.value })
  }

  const onMinChange = (value: number | null) => {
    updateFilter({'currentStockMin': value});
  }

  const onMaxChange = (value: number | null) => {
    updateFilter({'currentStockMax': value});
  }

  return (
    <>
      <Form.Item name="currentStockUnit" style={{ marginBottom: 4 }}>
        <Radio.Group size="large" onChange={onUnitChange}>
          <Radio value={"Mts"} key={"Mts"} className="text-base">Mts</Radio>
          <Radio value={"Kg"} key={"Kg"} className="text-base">Kg</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="currentStockMin" label={_t("form.min")} style={{ marginBottom: 4 }}>
        <InputNumber size="large" placeholder="min" onChange={onMinChange} className="w-full" />
      </Form.Item>
      <Form.Item name="currentStockMax" label={_t("form.max")} style={{ marginBottom: 0 }}>
        <InputNumber size="large" placeholder="max" onChange={onMaxChange} className="w-full" />
      </Form.Item>
    </>
  )
}

export default BasicQuantityComponent;
