import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, Form, Spin, message } from "antd";

import StepOneComponent from "../../components/publish/Step1";
import StepTwoComponent from "../../components/publish/Step2";
import StepThreeComponent from "../../components/publish/Step3";
import StepFourComponent from "../../components/publish/Step4";
import StepFiveComponent from "../../components/publish/Step5";
import StepSixComponent from "../../components/publish/Step6";
import StepSevenComponent from "../../components/publish/Step7";
import StepEightComponent from "../../components/publish/Step8";
import StepNineComponent from "../../components/publish/Step9";
import StepTenComponent from "../../components/publish/Step10";

import { convertResultFormToApi, fetchData, postData } from "../../utils/utils";
import { _t, messages } from "../../utils/translate";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

const FabricEdit = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { lang } = useSelector((state: RootState) => state.auth);

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [curStep, setCurStep] = useState<number>(1);
  const [result, setResult] = useState<Record<string, any>>({});

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [multiOption, setMultiOption] = useState<string>("form.unified");
  const [refList, setRefList] = useState<any[]>([]);
  const [fileImage, setFileImage] = useState<File>();

  useEffect(() => {
    const id = searchParams.get("id");

    if (id)
      readFabricById(id);
  }, [])

  const getRefList = async () => {
    setIsPageLoading(true);
    try {
      const apiResult = await fetchData('api/auth/get-ref-list', {});

      if (apiResult && apiResult.refs) {
        setRefList(apiResult.refs.map((t: string) => ({ label: t, value: t })));
      }
    } catch (error: any) {
      console.error(error);
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message));
      }
    } finally {
      setIsPageLoading(false);
    }
  }

  const readFabricById = async (id: string) => {
    setIsPageLoading(true);

    try {
      const apiResult = await postData('api/fabric/edit', { id: searchParams.get("id"), method: 'get' });
      // setResult(apiResult.fabric);
      await onRestoreForm(apiResult.fabric);
      await getRefList();
      setRefList(refList.filter(ref => ref !== apiResult.fabric.ref));
    } catch (error: any) {
      console.error(error);
      if (error.data.message) {
        showToast('error', _t(error.data.message))
      }
    } finally {
      setIsPageLoading(false);
    }
  }

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onUpdate = (type: string, data: Record<string, any> | undefined) => {
    switch (type) {
      case 'next-step':
        setResult({ ...result, ...form.getFieldsValue(), ...data });
        setCurStep(curStep + 1);
        break;
      case 'final-step':
        onEdit({ ...result, ...form.getFieldsValue(), ...data });
        break;
      case 'data-submit':
        setIsSubmitting(true);
        break;
      case 'data-submitted':
        setIsSubmitting(false);
        break;
      case 'prev-step':
        setCurStep(curStep - 1);
        break;
      case 'update-property':
        setResult({ ...result, ...data });
        break;
      case 'first-step':
        setCurStep(1);
        break;
      case 'file-image':
        setFileImage(data as File);
        break;
    }
  }

  const onEdit = async (result: Record<string, any>) => {
    showToast('loading', _t("Updating fabric ..."));
    setIsSubmitting(true);
    try {
      console.log(result);
      const apiResult = await postData('api/fabric/edit', { id: searchParams.get("id"), method: 'put', data: convertResultFormToApi(result) });
      showToast('success', _t(apiResult.message));
      setTimeout(() => navigate('/space/fabrics'), 500);
    } catch (error: any) {
      console.error(error);
      if (error.data.message) {
        showToast('error', _t(error.data.message))
      } else {
        showToast('error', _t("An error occured with connecting to the server"));
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const onRestoreForm = async (result: Record<string, any>) => {
    Object.keys(result).forEach((key) => {
      form.setFieldValue(key, result[key]);
    });

    result['salesMethod'] !== undefined && form.setFieldValue('salesMethod', result['salesMethod'] ? 'form.yes' : 'form.no');
    result['commercialName'] !== undefined && form.setFieldValue('commercialName', { realValue: result['commercialName'], value: _t(result['commercialName']), label: _t(result['commercialName']) });
    result['armor'] !== undefined && form.setFieldValue('armor', { realValue: result['armor'], value: _t(result['armor']), label: _t(result['armor']) });

    if (result['compositionKeys']) {
      result['compositionKeys'].forEach((item: any, index: number) => item !== '' && form.setFieldValue(`compExp${index + 1}`, { realValue: item, value: messages[lang][item], label: _t(item) }));
    }
    // result['compExp1'] !== undefined && result['compExp1'] !== "" && form.setFieldValue('compExp1', { realValue: result['compExp1'], value: _t(result['compExp1']), label: _t(result['compExp1']) });
    // result['compExp2'] !== undefined && result['compExp2'] !== "" && form.setFieldValue('compExp2', { realValue: result['compExp2'], value: _t(result['compExp2']), label: _t(result['compExp2']) });
    // result['compExp3'] !== undefined && result['compExp3'] !== "" && form.setFieldValue('compExp3', { realValue: result['compExp3'], value: _t(result['compExp3']), label: _t(result['compExp3']) });
    // result['compExp4'] !== undefined && result['compExp4'] !== "" && form.setFieldValue('compExp4', { realValue: result['compExp4'], value: _t(result['compExp4']), label: _t(result['compExp4']) });

    if (result['compositionValues']) {
      result['compositionValues'].forEach((item: any, index: number) => item !== 0 && form.setFieldValue(`compVal${index + 1}`, item));
    }
    // result['compVal1'] !== undefined && form.setFieldValue('compVal1', result['compVal1']);
    // result['compVal2'] !== undefined && form.setFieldValue('compVal2', result['compVal2']);
    // result['compVal3'] !== undefined && form.setFieldValue('compVal3', result['compVal3']);
    // result['compVal4'] !== undefined && form.setFieldValue('compVal4', result['compVal4']);

    result['pattern'] && form.setFieldValue('pattern', { realValue: result['pattern'], value: messages[lang][result['pattern']], label: _t(result['pattern']) });
    result['drawing'] && form.setFieldValue('drawing', result['drawing'].map((val: string) => ({ realValue: val, value: messages[lang][val], label: _t(val) })));

    result['type1'] !== undefined && form.setFieldValue('type1', { realValue: result['type1'], value: _t(result['type1']), label: _t(result['type1']) });
    result['type2'] !== undefined && form.setFieldValue('type2', { realValue: result['type2'], value: _t(result['type2']), label: _t(result['type2']) });
    result['type3'] !== undefined && form.setFieldValue('type3', { realValue: result['type3'], value: _t(result['type3']), label: _t(result['type3']) });
    result['type4'] !== undefined && form.setFieldValue('type4', { realValue: result['type4'], value: _t(result['type4']), label: _t(result['type4']) });
    result['type5'] !== undefined && form.setFieldValue('type5', { realValue: result['type5'], value: _t(result['type5']), label: _t(result['type5']) });

    if (result['fabricUsage']) {
      result['fabricUsage'].forEach((item: any, index: number) =>
        form.setFieldValue(`type${index + 1}`, { realValue: item, value: _t(item), label: _t(item) }));
    }

    result['stockCountry'] !== undefined && form.setFieldValue('stockCountry', { value: result['stockCountry'], label: result['stockCountry'] });
    result['stockState'] !== undefined && form.setFieldValue('stockState', { value: result['stockState'], label: result['stockState'] });
    result['stockCity'] !== undefined && form.setFieldValue('stockCity', { value: result['stockCity'], label: result['stockCity'] });
    result['origin'] !== undefined && form.setFieldValue('origin', { value: result['origin'], label: result['origin'] });

    setMultiOption(result['colorType']);

    setTimeout(() => setIsPageLoading(false), 500);
  }

  return (
    <>
      {contextHolder}
      <Spin spinning={isPageLoading} indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} fullscreen />
      {curStep === 10
        ? <StepTenComponent
          form={form} details={convertResultFormToApi(result)} showToast={showToast} update={onUpdate}
          fileImage={fileImage} isLoading={isSubmitting}
        />
        : <>
          <div className="flex flex-col items-center">
            <div className="w-full md:w-[768px] px-5">
              <h3 className="font-bold text-3xl text-center mt-6 mb-3 mx-5 text-blue-800">{_t("publish.title")}</h3>
              <p className="font-semibold text-xl text-center mb-3 text-blue-600">{_t("Step")} {curStep}/9: {_t(`publish.step${curStep}Title`)}</p>
            </div>
          </div>
          <Card className="flex justify-center mb-5">
            <Form
              form={form}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              className="w-full md:w-[1280px]"
              onValuesChange={() => setMultiOption(form.getFieldValue('colorType'))}
            >
              {curStep === 1 && <StepOneComponent form={form} showToast={showToast} update={onUpdate} initMultiOption={multiOption} refs={refList} />}
              {curStep === 2 && <StepTwoComponent form={form} showToast={showToast} update={onUpdate} coef={result.turbularOpen !== 'form.turbular' ? 1 : 2} />}
              {curStep === 3 && <StepThreeComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 4 && <StepFourComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 5 && <StepFiveComponent form={form} showToast={showToast} update={onUpdate} colorType={form.getFieldValue('colorType')} />}
              {curStep === 6 && <StepSixComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 7 && <StepSevenComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 8 && <StepEightComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 9 && <StepNineComponent form={form} showToast={showToast} update={onUpdate} isLoading={isSubmitting} />}
            </Form>
          </Card>
        </>}
    </>
  )
}

export default FabricEdit;