import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Lock } from "lucide-react";
import { _t } from "../../utils/translate";
import { postData } from "../../utils/utils";

const AuthResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onSubmit = async () => {
    const values = await form.validateFields();
    if (values['password'] !== values['confirmPassword'] || values['password'].length < 8) {
      showToast('error', _t("error.passwordValidFailedError"));
      form.setFieldValue('password', '');
      form.setFieldValue('confirmPassword', '');
      return;
    }

    try {
      const apiResult = await postData('api/auth/reset-password', { password: values['password'], token: searchParams.get('token') })
      showToast('success', _t(apiResult.message));
      setTimeout(() => {
        navigate('/auth/login');
      }, 500);
    } catch (error: any) {
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message));
      } else {
        showToast('error', _t("An error occured with connecting to the server"));
      }
    }
  }

  return (
    <div className="flex flex-col items-center py-16">
      <div className="w-full md:w-[600px] px-5 flex flex-col items-center gap-6">
        <div className="flex flex-col items-start gap-y-2 text-blue-800">
          <h3 className="text-3xl md:text-5xl font-bold">{_t("Update Password")}</h3>
        </div>
        <p className="text-[20px] text-center font-semibold text-blue-600">{_t("Enter your new password")}</p>
        <Form
          layout={'vertical'}
          form={form}
          onSubmitCapture={onSubmit}
          className="w-full md:w-[480px]"
        >
          <Form.Item name="password" label={_t("form.password")} initialValue={""}>
            <Input.Password size="large" prefix={<Lock />} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
          <Form.Item name="confirmPassword" label={_t("form.confirmPassword")} initialValue={""}>
            <Input.Password size="large" prefix={<Lock />} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button htmlType="submit" className="w-full" size={'large'}>{_t("Update Password")}</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AuthResetPassword;