import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { _t } from "../../utils/translate";

const AuthRegister = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center gap-12">
      <div className="mt-24 mb-12 text-center">
        <h3 className="text-5xl font-bold text-blue-800">{_t("register.welcome")}</h3>
        <p className="text-[30px] font-medium text-blue-600">{_t("register.askChoice")}:</p>
      </div>
      <Button 
        className="w-[272px] h-[54px] text-[20px] border-[3px] border-blue-800 text-blue-800 font-semibold rounded-xl custom-button"
        size={'large'} onClick={() => navigate('/auth/create-seller')}
      >{_t("register.createSeller")}</Button>
      <div className="relative w-[350px]">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t border-blue-500 border-black"></span>
        </div>
        <div className="relative flex justify-center text-md font-semibold uppercase">
          <span className="bg-white px-3 text-blue-600">{_t("login.or")}</span>
        </div>
      </div>
      <Button 
        className="w-[272px] h-[54px] text-[20px] border-[3px] border-blue-800 text-blue-800 font-semibold rounded-xl custom-button"
        size={'large'} onClick={() => navigate('/auth/create-visitor')}
      >{_t("register.createVisitor")}</Button>
      <div className="text-md font-semibold">
        <span className="mr-1 text-blue-800">{_t("register.wondering")}?</span>
        <Link to={'/auth/login'} className="underline text-blue-300 transition-all duration-300">{_t("header.signIn")}</Link>
      </div>
    </div>
  )
}

export default AuthRegister;