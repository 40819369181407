import { useEffect, useState } from "react";
import { Button, ColorPicker, Dropdown, Form, FormInstance, Menu, MenuProps, Radio, RadioChangeEvent } from "antd";
import Select, { MultiValue } from 'react-select';
import { isMobile } from "react-device-detect";
import { _t } from "../../utils/translate";
import { ColorDropdownType, FormDataType, OptionType } from "../../utils/types";
import { patternDrawingData, primaryColors, shadowColors } from "../../utils/constants";
import { Palette } from "lucide-react";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { customFilterOption } from "../../utils/utils";

const StepFiveComponent = ({ form, showToast, update, colorType }: { form: FormInstance, showToast: Function, update: Function, colorType: string }) => {
  const { lang } = useSelector((state: RootState) => state.auth);
  const [primaryColor, setPrimaryColor] = useState<string>("");
  const [mode, setMode] = useState<string>("light");
  const [pattern, setPattern] = useState<OptionType>({ label: "", value: "", realValue: "" });

  const [backMode, setBackMode] = useState<string>("light");
  const [backPrimaryColor, setBackPrimaryColor] = useState<string>("");
  const [draw1Mode, setDraw1Mode] = useState<string>("light");
  const [draw1PrimaryColor, setDraw1PrimaryColor] = useState<string>("");
  const [draw2Mode, setDraw2Mode] = useState<string>("light");
  const [draw2PrimaryColor, setDraw2PrimaryColor] = useState<string>("");

  const [drawings, setDrawings] = useState<MultiValue<OptionType>>([]);

  const buildColorItem = (color: ColorDropdownType, index: number) => ({
    label: <div className="flex gap-x-2 justify-between">
      <Palette />
      <span className="text-base font-semibold">{_t(color.label)}</span>
      <span style={{ width: 50, height: 24, borderRadius: 8, backgroundColor: color.color, boxShadow: '0 1px 2px black' }}></span>
    </div>,
    realValue: color.label,
    value: messages[lang][color.label],
    key: index,
  });

  const primaryItems = primaryColors
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));

  // Unified Mode
  const shadeItems = (shadowColors[`${mode}${primaryColor}`] ? shadowColors[`${mode}${primaryColor}`] : [])
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));

  // Printed Mode
  const backShadeItems = (shadowColors[`${backMode}${backPrimaryColor}`] ? shadowColors[`${backMode}${backPrimaryColor}`] : [])
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));
  const draw1ShadeItems = (shadowColors[`${draw1Mode}${draw1PrimaryColor}`] ? shadowColors[`${draw1Mode}${draw1PrimaryColor}`] : [])
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));
  const draw2ShadeItems = (shadowColors[`${draw2Mode}${draw2PrimaryColor}`] ? shadowColors[`${draw2Mode}${draw2PrimaryColor}`] : [])
    .sort((a: ColorDropdownType, b: ColorDropdownType) => messages[lang][a.label].localeCompare(messages[lang][b.label]))
    .map((color, index) => buildColorItem(color, index));

  useEffect(() => {
    setPattern(form.getFieldValue('pattern'));
    setDrawings(form.getFieldValue('drawing'));

    // if (form.getFieldValue('pattern') && patternDrawingData[form.getFieldValue('pattern').realValue]) {
    //   setDrawings(patternDrawingData[form.getFieldValue('pattern').realValue]
    //     .map((val) => ({ realValue: val, value: messages[lang][val], label: _t(val) })));
    // }

    if (form.getFieldValue('colorType') === 'form.unified') {
      const primaryColor = form.getFieldValue('principalColor')
        ? (typeof form.getFieldValue('principalColor') === 'string'
          ? form.getFieldValue('principalColor')
          : form.getFieldValue('principalColor').realValue)
        : "";
      setPrimaryColor(primaryColor);
      const mode = form.getFieldValue('color') === 'form.light' ? 'light' : 'dark';
      setMode(mode);

      const primaryIndex = primaryColors.findIndex((e: ColorDropdownType) => e.label === primaryColor);
      if (primaryIndex !== -1) form.setFieldValue('principalColor', buildColorItem(primaryColors[primaryIndex], primaryIndex));

      const shadeColors = shadowColors[`${mode}${primaryColor}`];
      if (shadeColors) {
        const shadeIndex = shadeColors.findIndex((e: ColorDropdownType) => e.label === form.getFieldValue('colorShade'));
        if (shadeIndex !== -1) form.setFieldValue('colorShade', buildColorItem(shadeColors[shadeIndex], shadeIndex));
      }
    }

    if (form.getFieldValue('colorType') !== 'form.unified') {
      const principalColor = form.getFieldValue('principalColor')
        ? (typeof form.getFieldValue('principalColor') === 'string' ? form.getFieldValue('principalColor') : form.getFieldValue('principalColor').realValue)
        : "";
      const drawingPrincipalColor = form.getFieldValue('drawingPrincipalColor')
        ? (typeof form.getFieldValue('drawingPrincipalColor') === 'string' ? form.getFieldValue('drawingPrincipalColor') : form.getFieldValue('drawingPrincipalColor').realValue)
        : "";
      const secondPrincipalColor = form.getFieldValue('secondPrincipalColor')
        ? (typeof form.getFieldValue('secondPrincipalColor') === 'string' ? form.getFieldValue('secondPrincipalColor') : form.getFieldValue('secondPrincipalColor').realValue)
        : "";
      setBackPrimaryColor(principalColor);
      setDraw1PrimaryColor(drawingPrincipalColor);
      setDraw2PrimaryColor(secondPrincipalColor);
      const backMode = form.getFieldValue('color') === 'form.light' ? 'light' : 'dark';
      const draw1Mode = form.getFieldValue('drawingColor') === 'form.light' ? 'light' : 'dark';
      const draw2Mode = form.getFieldValue('secondColor') === 'form.light' ? 'light' : 'dark';
      setBackMode(backMode);
      setDraw1Mode(draw1Mode);
      setDraw2Mode(draw2Mode);

      const backPrimaryIndex = primaryColors.findIndex((e: ColorDropdownType) => e.label === form.getFieldValue('principalColor'));
      if (backPrimaryIndex !== -1) form.setFieldValue('principalColor', buildColorItem(primaryColors[backPrimaryIndex], backPrimaryIndex));
      const draw1PrimaryIndex = primaryColors.findIndex((e: ColorDropdownType) => e.label === form.getFieldValue('drawingPrincipalColor'));
      if (draw1PrimaryIndex !== -1) form.setFieldValue('drawingPrincipalColor', buildColorItem(primaryColors[draw1PrimaryIndex], draw1PrimaryIndex));
      const draw2PrimaryIndex = primaryColors.findIndex((e: ColorDropdownType) => e.label === form.getFieldValue('secondPrincipalColor'));
      if (draw2PrimaryIndex !== -1) form.setFieldValue('secondPrincipalColor', buildColorItem(primaryColors[draw2PrimaryIndex], draw2PrimaryIndex));

      const backShadeColors = shadowColors[`${backMode}${principalColor}`];
      if (backShadeColors) {
        const shadeIndex = backShadeColors.findIndex((e: ColorDropdownType) => e.label === form.getFieldValue('colorShade'));
        if (shadeIndex !== -1) form.setFieldValue('colorShade', buildColorItem(backShadeColors[shadeIndex], shadeIndex));
      }
      const draw1ShadeColors = shadowColors[`${draw1Mode}${drawingPrincipalColor}`];
      if (draw1ShadeColors) {
        const shadeIndex = draw1ShadeColors.findIndex((e: ColorDropdownType) => e.label === form.getFieldValue('drawingColorShade'));
        if (shadeIndex !== -1) form.setFieldValue('drawingColorShade', buildColorItem(draw1ShadeColors[shadeIndex], shadeIndex));
      }
      const draw2ShadeColors = shadowColors[`${draw2Mode}${secondPrincipalColor}`];
      if (draw2ShadeColors) {
        const shadeIndex = draw2ShadeColors.findIndex((e: ColorDropdownType) => e.label === form.getFieldValue('secondColorShade'));
        if (shadeIndex !== -1) form.setFieldValue('secondColorShade', buildColorItem(draw2ShadeColors[shadeIndex], shadeIndex));
      }
    }
  }, [])

  const onBack = async () => {
    update('prev-step');
  }

  const onNext = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      update('next-step');
    } catch (error) {
      console.error(error);
      showToast('error', _t("error.publishError"));
    }
  }

  const onModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value === 'form.light' ? 'light' : 'dark');
    form.setFieldValue('colorShade', "");
  }

  const onPrimaryChange = (e: any) => {
    setPrimaryColor(e.realValue);
    form.setFieldValue('colorShade', "");
  }

  const onBackChange = (e: any) => {
    setBackPrimaryColor(e.realValue);
    form.setFieldValue('colorShade', "");
  }

  const onBackModeChange = (e: any) => {
    setBackMode(e.target.value === 'form.light' ? 'light' : 'dark');
    form.setFieldValue('colorShade', "");
  }

  const onDraw1Change = (e: any) => {
    setDraw1PrimaryColor(e.realValue);
    form.setFieldValue('drawingColorShade', "");
  }

  const onDraw1ModeChange = (e: any) => {
    setDraw1Mode(e.target.value === 'form.light' ? 'light' : 'dark');
    form.setFieldValue('drawingColorShade', "");
  }

  const onDraw2Change = (e: any) => {
    setDraw2PrimaryColor(e.realValue);
    form.setFieldValue('secondColorShade', "");
  }

  const onDraw2ModeChange = (e: any) => {
    setDraw2Mode(e.target.value === 'form.light' ? 'light' : 'dark');
    form.setFieldValue('secondColorShade', "");
  }

  const onClearUnifiedPrimary = () => {
    onClearUnifiedShade();
    form.setFieldValue("principalColor", "");
    setPrimaryColor("");
  }

  const onClearUnifiedShade = () => {
    form.setFieldValue("colorShade", "");
  }

  const onClearPrintedPrimary = () => {
    onClearPrintedShade();
    form.setFieldValue("principalColor", "");
    setBackPrimaryColor("");
  }

  const onClearPrintedShade = () => {
    form.setFieldValue("colorShade", "");
  }

  const onClearPrintedDrawingPrimary = () => {
    onClearPrintedDrawingShade();
    form.setFieldValue("drawingPrincipalColor", "");
    setDraw1PrimaryColor("");
  }

  const onClearPrintedDrawingShade = () => {
    form.setFieldValue("drawingColorShade", "");
  }

  const onClearPrintedSecondPrimary = () => {
    onClearPrintedSecondShade();
    form.setFieldValue("secondPrincipalColor", "");
    setDraw2PrimaryColor("");
  }

  const onClearPrintedSecondShade = () => {
    form.setFieldValue("secondColorShade", "");
  }

  const buildUnified = () => (
    <>
      <Form.Item label={_t("form.principalColor")} style={{ marginBottom: 0 }}>
        <div className="flex gap-x-2">
          <Form.Item
            name={"principalColor"} key={"principalColor"}
            rules={[{ required: true, message: '' }]} initialValue={""}
          >
            <Select
              styles={{ container: (base) => ({ ...base, width: 300, borderRadius: 4, fontSize: 16 }) }}
              options={primaryItems}
              onChange={onPrimaryChange}
              placeholder=""
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearUnifiedPrimary}>{_t("Clear")}</Button>
        </div>
      </Form.Item>
      <Form.Item
        label={_t("form.color")} name={"color"} key={"color"}
        initialValue={"form.light"}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large" onChange={onModeChange}>
          <Radio value={"form.light"} key={"form.light"} className="text-base">{_t("form.light")}</Radio>
          <Radio value={"form.dark"} key={"form.dark"} className="text-base">{_t("form.dark")}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={_t("form.colorShade")} style={{ marginBottom: 0 }}>
        <div className="flex gap-x-2">
          <Form.Item
            name={"colorShade"} key={"colorShade"}
            rules={[{ required: true, message: '' }]} initialValue={""}
          >
            <Select
              styles={{ container: (base) => ({ ...base, width: 300, borderRadius: 4, fontSize: 16 }) }}
              options={shadeItems}
              placeholder=""
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearUnifiedShade}>{_t("Clear")}</Button>
        </div>
      </Form.Item>
    </>
  );

  const onDrawingChange = (options: MultiValue<OptionType>) => {
    setDrawings(options);
  }

  const buildPrinted = () => (
    <>
      <Form.Item
        label={_t("form.pattern")} name={"pattern"} key={"pattern"}
        initialValue={pattern}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          isSearchable placeholder=""
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("pattern").length > 0 ? '1px solid red' : '' }) }}
          options={Object.keys(patternDrawingData)
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map((val, index) => ({ realValue: val, value: messages[lang][val], label: _t(val), key: 'pattern' + index }))}
          onChange={(val: any) => {
            setPattern(val);
            setDrawings([]);
            form.setFieldValue('drawing', "");
          }}
          filterOption={customFilterOption}
        />
      </Form.Item>
      <Form.Item
        label={_t("form.drawing")} name={"drawing"} key={"drawing"}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          isSearchable isMulti closeMenuOnSelect={false} placeholder=""
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("drawing").length > 0 ? '1px solid red' : '' }) }}
          options={(pattern && patternDrawingData[pattern.realValue] ? patternDrawingData[pattern.realValue] : [])
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map((val) => { return { realValue: val, value: messages[lang][val], label: _t(val) }; })}
          isOptionDisabled={() => drawings.length >= 3}
          onChange={onDrawingChange}
          filterOption={customFilterOption}
        />
      </Form.Item>
      <Form.Item
        label={_t("form.drawingHeight")} name={"drawingHeight"} key={"drawingHeight"}
        initialValue={"form.0to6"}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={"form.0to6"} key={"form.0to6"} className="text-base">{_t("form.0to6")}</Radio>
          <Radio value={"form.7to12"} key={"form.7to12"} className="text-base">{_t("form.7to12")}</Radio>
          <Radio value={"form.13to20"} key={"form.13to20"} className="text-base">{_t("form.13to20")}</Radio>
          <Radio value={"form.20to30"} key={"form.20to30"} className="text-base">{_t("form.20to30")}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={_t("form.drawingWidth")} name={"drawingWidth"} key={"drawingWidth"}
        initialValue={"form.0to6"}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={"form.0to6"} key={"form.0to6"} className="text-base">{_t("form.0to6")}</Radio>
          <Radio value={"form.7to12"} key={"form.7to12"} className="text-base">{_t("form.7to12")}</Radio>
          <Radio value={"form.13to20"} key={"form.13to20"} className="text-base">{_t("form.13to20")}</Radio>
          <Radio value={"form.20to30"} key={"form.20to30"} className="text-base">{_t("form.20to30")}</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={_t("form.drawingSurfaceCm")} name={"drawingSurfaceCm"} key={"drawingSurfaceCm"}
        initialValue={"form.0to3"}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large">
          <Radio value={"form.0to3"} key={"form.0to3"} className="text-base">{_t("form.0to3")}</Radio>
          <Radio value={"form.4to6"} key={"form.4to6"} className="text-base">{_t("form.4to6")}</Radio>
          <Radio value={"form.7to10"} key={"form.7to10"} className="text-base">{_t("form.7to10")}</Radio>
          <Radio value={"form.11to13"} key={"form.11to13"} className="text-base">{_t("form.11to13")}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={_t("form.backgroundColor")} style={{ marginBottom: 0 }}>
        <div className="flex gap-x-2">
          <Form.Item
            label={_t("form.principalColor")} name="principalColor" initialValue={""}
            rules={[{ required: true, message: '' }]}
          >
            <Select placeholder=""
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("principalColor").length > 0 ? '1px solid red' : '' }) }}
              options={primaryItems}
              onChange={onBackChange}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearPrintedPrimary}>{_t("Clear")}</Button>
        </div>
        <Form.Item
          label={_t("form.color")} name={"color"} key={"color"}
          rules={[{ required: true, message: '' }]} initialValue={"form.light"}
        >
          <Radio.Group size="large" onChange={onBackModeChange}>
            <Radio value={"form.light"} key={"form.light"} className="text-base">{_t("form.light")}</Radio>
            <Radio value={"form.dark"} key={"form.dark"} className="text-base">{_t("form.dark")}</Radio>
          </Radio.Group>
        </Form.Item>
        <div className="flex gap-x-2">
          <Form.Item
            label={_t("form.colorShade")} name="colorShade" initialValue={""}
            rules={[{ required: true, message: '' }]}
          >
            <Select placeholder=""
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("colorShade").length > 0 ? '1px solid red' : '' }) }}
              options={backShadeItems}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearPrintedShade}>{_t("Clear")}</Button>
        </div>
      </Form.Item>
      <Form.Item label={_t("form.drawingPrincipalColor")} style={{ marginBottom: 0 }}>
        <div className="flex gap-x-2">
          <Form.Item
            label={_t("form.principalColor")} name="drawingPrincipalColor" initialValue={""}
            rules={[{ required: true, message: '' }]}
          >
            <Select placeholder=""
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("drawingPrincipalColor").length > 0 ? '1px solid red' : '' }) }}
              options={primaryItems}
              onChange={onDraw1Change}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearPrintedDrawingPrimary}>{_t("Clear")}</Button>
        </div>
        <Form.Item
          label={_t("form.color")} name={"drawingColor"} key={"drawingColor"}
          rules={[{ required: true, message: '' }]} initialValue={"form.light"}
        >
          <Radio.Group size="large" onChange={onDraw1ModeChange}>
            <Radio value={"form.light"} key={"form.light"} className="text-base">{_t("form.light")}</Radio>
            <Radio value={"form.dark"} key={"form.dark"} className="text-base">{_t("form.dark")}</Radio>
          </Radio.Group>
        </Form.Item>
        <div className="flex gap-x-2">
          <Form.Item
            label={_t("form.colorShade")} name="drawingColorShade" initialValue={""}
            rules={[{ required: true, message: '' }]}
          >
            <Select placeholder=""
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("drawingColorShade").length > 0 ? '1px solid red' : '' }) }}
              options={draw1ShadeItems}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearPrintedDrawingShade}>{_t("Clear")}</Button>
        </div>
      </Form.Item>
      <Form.Item label={_t("form.drawingSecondaryColor")}>
        <div className="flex gap-x-2">
          <Form.Item
            label={_t("form.principalColor")} name="secondPrincipalColor" initialValue={""}
          >
            <Select placeholder=""
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("secondPrincipalColor").length > 0 ? '1px solid red' : '' }) }}
              options={primaryItems}
              onChange={onDraw2Change}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearPrintedSecondPrimary}>{_t("Clear")}</Button>
        </div>
        <Form.Item
          label={_t("form.color")} name={"secondColor"} key={"secondColor"}
          initialValue={"form.light"}
        >
          <Radio.Group size="large" onChange={onDraw2ModeChange}>
            <Radio value={"form.light"} key={"form.light"} className="text-base">{_t("form.light")}</Radio>
            <Radio value={"form.dark"} key={"form.dark"} className="text-base">{_t("form.dark")}</Radio>
          </Radio.Group>
        </Form.Item>
        <div className="flex gap-x-2">
          <Form.Item
            label={_t("form.colorShade")} name="secondColorShade" initialValue={""}
          >
            <Select placeholder=""
              styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError("secondColorShade").length > 0 ? '1px solid red' : '' }) }}
              options={draw2ShadeItems}
              filterOption={customFilterOption}
            />
          </Form.Item>
          <Button type="link" className="text-base text-red-600 underline mb-1" onClick={onClearPrintedSecondShade}>{_t("Clear")}</Button>
        </div>
      </Form.Item>
    </>
  );

  return (
    <div>
      {colorType === 'form.unified'
        ? buildUnified()
        : buildPrinted()}
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <div className="flex gap-x-2">
          <Button size="large" onClick={onBack}>{_t("form.back")}</Button>
          <Button size="large" onClick={onNext}>{_t("form.next")}</Button>
        </div>
      </Form.Item>
    </div>
  );
}

export default StepFiveComponent;