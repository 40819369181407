import { Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import SpaceEditUser from "../../components/space/EditUser";
import { fetchData } from "../../utils/utils";
import { _t } from "../../utils/translate";
import { LoadingOutlined } from "@ant-design/icons";

const EditUserPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Record<string, any>>({});

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    setIsLoading(true);
    try {
      const apiResult = await fetchData('api/auth/get_user', { id: searchParams.get('id') });
      setUser(apiResult.user);
    } catch (error: any) {
      if (error.data.message) {
        showToast('error', _t(error.data.message));
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onRefresh = () => navigate(-1);

  return (
    <div className='p-2 md:p-10'>
      {contextHolder}
      {isLoading
        ? <Spin indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} fullscreen />
        : <SpaceEditUser user={user} showToast={showToast} onRefresh={onRefresh} />}
    </div>
  );
}

export default EditUserPage;