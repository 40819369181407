import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { User, AuthState, ResearchFiltersType } from "./types";

const initialState: AuthState = {
  auth: null,
  lang: 'fr',
  researchFilters: {} as ResearchFiltersType,
  researchText: "",
  researchPage: 0,
  isFilterEnabled: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<User | null>) => {
      state.auth = action.payload;
    },
    updateAuth: (state, action: PayloadAction<User | null>) => {
      state.auth = { ...state.auth, ...action.payload };
    },
    setLanguage: (state, action: PayloadAction<'en' | 'fr'>) => {
      state.lang = action.payload;
    },
    setResearchFilters: (state, action: PayloadAction<ResearchFiltersType>) => {
      state.researchFilters = action.payload;
    },
    setResearchText: (state, action: PayloadAction<string>) => {
      state.researchText = action.payload;
    },
    setResearchPage: (state, action: PayloadAction<number>) => {
      state.researchPage = action.payload;
    },
    setFilterEnabled: (state, action: PayloadAction<boolean>) => {
      state.isFilterEnabled = action.payload;
    },
    clearFilterInfo: (state) => {
      state.researchFilters = {};
      state.researchText = "";
      state.researchPage = 1;
      state.isFilterEnabled = false;
    }
  },
});

export const { 
  setAuth, updateAuth, setLanguage, 
  setResearchFilters, setResearchText, setResearchPage,
  setFilterEnabled, clearFilterInfo
} = authSlice.actions;
export const authReducer = authSlice.reducer;