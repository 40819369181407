import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { RootState } from "@/redux/store";
import { setAuth, setLanguage } from '../../redux/authSlice';

import Header from "./header";
import Footer from "./footer";

import { _t, messages } from '../../utils/translate';
import { ConfigProvider } from 'antd';
import { isMobile } from 'react-device-detect';

const noAuthRoutes = ["/", "/auth/login", "/auth/register", "/auth/create-seller", "/auth/create-visitor", "/auth/reset-password", "/fabrics/research", "/fabrics/details"];
const visitorRoutes = ["/", "/auth/settings", "/auth/becomeSeller", "/auth/reset-password", "/fabrics/research", "/fabrics/details"];
const sellerRoutes = ["/", "/auth/settings", "/space/fabrics", "/auth/reset-password", "/space/history", "/fabrics/publish", "/fabrics/edit", "/fabrics/duplicate", "/fabrics/research", "/fabrics/details"];
const adminRoutes = ["/", "/auth/settings", "/space/fabrics", "/auth/reset-password", "/space/users", "/space/users/edit", "/fabrics/publish", "/fabrics/edit", "/fabrics/duplicate", "/fabrics/research", "/fabrics/details"];

const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, lang } = useSelector((state: RootState) => state.auth);

  const { pathname } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode(token);
      if (!(decodedToken.exp && decodedToken.exp * 1000 > Date.now())) {
        localStorage.removeItem('token');
        dispatch(setAuth(null));
        navigate('/auth/login');
      }
    }

    if (!lang) {
      dispatch(setLanguage('en'));
    }
  }, []);

  useEffect(() => {
    if (auth === null && !noAuthRoutes.includes(pathname)) {
      navigate('/auth/login');
    } else if (auth && auth.type === 'VISITOR' && !visitorRoutes.includes(pathname)) {
      navigate('/');
    } else if (auth && auth.type === 'SELLER' && !sellerRoutes.includes(pathname)) {
      navigate('/');
    } else if (auth && auth.type === 'ADMIN' && !adminRoutes.includes(pathname)) {
      navigate('/');
    }
  }, [pathname])

  useEffect(() => {
    const setVhUnit = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set the initial value
    setVhUnit();

    // Add event listener on resize
    window.addEventListener('resize', setVhUnit);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', setVhUnit);
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Plus Jakarta Sans"
        },
        components: {
          Form: {
            labelColor: '#1E3A8A',
          },
          Button: {
            defaultHoverBg: '#1E3A8A',
            defaultBorderColor: '#1E3A8A',
            defaultHoverBorderColor: '#1E3A8A',
            defaultColor: '#1E3A8A',
            defaultHoverColor: 'white',
            // colorPrimary: '#1E3A8A',
            // colorPrimaryHover: 'white',
            colorPrimary: '#1E3A8A',
            colorPrimaryBorder: '#1E3A8A',
            colorPrimaryBg: 'white',
            colorPrimaryText: 'red',
            colorPrimaryBgHover: 'red',
            // colorPrimaryText: 'red',
            // colorPrimaryBgHover: 'white',
            fontWeight: 600,
          },
          Input: {
            // colorBorder: '#93C5FD',
            // colorBgContainer: '#EFF6FF',
            // colorIcon: '#1E3A8A',
          },
          InputNumber: {
            // colorBorder: '#93C5FD',
            // colorBgContainer: '#EFF6FF',
            // colorIcon: '#1E3A8A',
          },
          Collapse: {
            headerBg: '#F9FAFB',
            contentBg: 'transparent',
            colorText: '#1E40AF',
            colorTextLabel: '#1E40AF',
          }
        },
      }}
    >
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div className="relative flex flex-col full-height-content">
          <Header />
          <main className={`flex-1 flex flex-col pt-[62px] relative px-5`}>
            <Outlet />
          </main>
          <Footer />
        </div>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default MainLayout;
