import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Button, Drawer, Avatar } from "antd";

// import { useUser } from '@auth0/nextjs-auth0/client';
import { Menu, ChevronRight, CircleUserRound, Package, Database, Home, Info, Headset, Globe, LogOut, Search, CircleX } from "lucide-react"
import type { LucideIcon } from "lucide-react";

import { RootState } from "@/redux/store";
import { setAuth, setLanguage } from "../../redux/authSlice";
import { _t } from "../../utils/translate";

const HeaderMobileView = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { auth, lang } = useSelector((state: RootState) => state.auth);

  const [open, setOpen] = useState(false);

  // const { user } = useUser();

  const goTo = (link: string) => {
    onClose();
    navigate(link);
  }

  const buildMenuItem = (MenuIcon: LucideIcon, text: any, link: string) => {
    return (
      <Button type={'text'} onClick={() => goTo(link)}>
        <div className="flex justify-between w-full">
          <div className="flex gap-x-2 items-center">
            <MenuIcon color={'#1E3A8A'} />
            <span className="text-base font-semibold text-blue-800">{text}</span>
          </div>
          <div><ChevronRight color="#1E3A8A" /></div>
        </div>
      </Button>
    )
  }

  const switchLanguage = () => {
    dispatch(setLanguage(lang === 'en' ? 'fr' : 'en'))
  }

  const onLogOut = () => {
    onClose();
    localStorage.removeItem('token');
    dispatch(setAuth(null));
    navigate('/auth/login');
  }

  const showDrawer = () => {
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link to={'/'} className="text-blue-800 font-semibold text-2xl select-none">
        Solotissu
      </Link>
      <Button className="text-black" type="link" size="large" onClick={showDrawer}><Menu /></Button>
      <Drawer
        title=''
        closable={true}
        onClose={onClose}
        open={open}
        placement="top"
        height={'100%'}
        closeIcon={<></>}
        extra={<CircleX color="#1E3A8A" size={30} strokeWidth={1} onClick={onClose} />}
        styles={{ header: { borderBottom: 'none' } }}
      >
        <div className="flex flex-col gap-y-2">
          {auth
            ? <>
              <div className="mx-3 flex flex-row gap-x-3 items-center mb-5">
                <Avatar size={48} src="https://github.com/shadcn.png" className="bg-white text-black font-semibold border-black">JD</Avatar>
                <div className="flex flex-col font-semibold text-base">
                  <span>{_t("menu.hello")},</span>
                  <span>{auth.fullName}</span>
                </div>
              </div>
              {buildMenuItem(CircleUserRound, _t("menu.myProfile"), "/auth/settings")}
              {auth.type === 'SELLER' && buildMenuItem(Package, _t("menu.myFabrics"), "/space/fabrics")}
              {auth.type === 'SELLER' && buildMenuItem(Database, _t("menu.myHistory"), "/space/history")}
              {auth.type === 'SELLER' && <Button className="mx-10 my-5 rounded-xl" size="large" onClick={() => goTo('/fabrics/publish')}>{_t("home.publishFabric")}</Button>}

              {auth.type === 'ADMIN' && buildMenuItem(Package, _t("menu.fabrics"), "/space/fabrics")}
              {auth.type === 'ADMIN' && buildMenuItem(Package, _t("menu.users"), "/space/users")}

              {auth.type === 'ADMIN' && <div className="border-t border-gray-400 m-5"></div>}

              {auth.type === 'VISITOR' && <Button className="mx-10 my-5 rounded-xl" size="large" onClick={() => goTo('/auth/becomeSeller')}>{_t("header.becomeSeller")}</Button>}

              {buildMenuItem(Home, _t("header.home"), "/")}
              {buildMenuItem(Search, _t("header.research"), "/fabrics/research")}
              {/* {buildMenuItem(Info, _t("header.aboutUs"), "/about")}
              {buildMenuItem(Headset, _t("header.contactUs"), "/contact")} */}
              <Button type="text" className="justify-start mt-5" onClick={switchLanguage}>
                <div className="flex gap-x-2">
                  <Globe color="#1E3A8A" />
                  <span className="text-base text-blue-800">{_t("language")}</span>
                </div>
              </Button>
              <Button type="text" className="justify-start" onClick={onLogOut}>
                <div className="flex gap-x-2">
                  <LogOut color="#1E3A8A" />
                  <span className="text-base text-blue-800">{_t("menu.logout")}</span>
                </div>
              </Button>
            </>
            : <>
              <p className="mx-4 font-bold text-xl text-[#172554]">{_t("register.welcome")}</p>
              <p className="mx-4 font-medium text-base text-blue-800">{_t("register.welcomeDesc")}</p>
              <Button 
                className="mt-12 mx-10 rounded-xl text-blue-800 font-semibold border-[#1E3A8A]" 
                size="large" onClick={() => goTo('/auth/login')}
              >{_t("header.signIn")}</Button>
              <Button 
                className="mx-10 mb-12 rounded-xl text-blue-800 font-semibold border-[#1E3A8A]" 
                size="large" onClick={() => goTo('/auth/register')}
              >{_t("header.startForFree")}</Button>
              {buildMenuItem(Home, _t("header.home"), "/")}
              {buildMenuItem(Search, _t("header.research"), "/fabrics/research")}
              {/* {buildMenuItem(Info, _t("header.aboutUs"), "/about")}
              {buildMenuItem(Headset, _t("header.contactUs"), "/contact")} */}
              <Button type="text" className="justify-start mt-5" onClick={switchLanguage}>
                <div className="flex gap-x-2">
                  <Globe color="#1E3A8A" />
                  <span className="text-base text-blue-800 font-semibold">{_t("language")}</span>
                </div>
              </Button>
            </>
          }
        </div>
      </Drawer>
    </>
  )
}

export default HeaderMobileView;