import { Button, Checkbox, Form, FormInstance } from "antd";
import { _t } from "../../utils/translate";
import { certifications } from "../../utils/constants";
import { isDesktop, isMobile } from "react-device-detect";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const StepEightComponent = ({ form, showToast, update }: { form: FormInstance, showToast: Function, update: Function }) => {
  const { lang } = useSelector((state: RootState) => state.auth);

  const resortedCertifications = useMemo(() => {
    let tmp: string[] = certifications, len: number = certifications.length;
    tmp.sort((a: string, b: string) => a.localeCompare(b))
    // tmp = ["Undetermined certification", ...tmp]

    if (isDesktop) {
      let res: string[] = [];
      let len1: number = Math.floor(len / 3) + (len % 3 > 0 ? 1 : 0);
      let len2: number = Math.floor(len / 3) + (len % 3 > 1 ? 1 : 0);
      let len3: number = Math.floor(len / 3) + (len % 3 > 2 ? 1 : 0);
      for (let i = 0; i < len1; ++i) {
        res.push(tmp[i]);
        if (i < len2) res.push(tmp[i + len1]);
        if (i < len3) res.push(tmp[i + len1 + len2]);
      }

      return res;
    } else {
      return tmp;
    }
  }, [certifications])

  const onBack = async () => {
    update('prev-step');
  }

  const onNext = async () => {
    try {
      await form.validateFields();
      update('next-step');
    } catch (error) {
      console.error(error);
      showToast('error', _t("error.publishError"));
    }
  }

  return (
    <>
      <Form.Item
        label={_t("form.certifications")} name={"certifications"} key={"certifications"}
        initialValue={[]}
        rules={[{ required: true, message: '' }]}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24, offset: isMobile ? 0 : 0 }}
      >
        <Checkbox.Group>
          <div className="flex flex-col gap-y-2">
            <div className={isMobile ? "custom-mobile-checkbox" : "custom-desktop-checkbox"}>
              {resortedCertifications.map((value, index) => (
                <Checkbox value={value} key={'certification' + index}>
                  <div className='mb-1 text-base'>{_t(value)}</div>
                </Checkbox>
              ))}
            </div>
            {/* <Checkbox.Group className={isMobile ? "custom-mobile-checkbox" : "custom-desktop-checkbox"}
              options={resortedCertifications
                .map(value => ({ label: <div className="mb-1 text-base">{_t(value)}</div>, value }))}
            /> */}
            <div className="flex flex-col items-start md:items-center">
              <p className='text-xl text-blue-700 font-bold mb-3'>
                {_t("form.other")}
              </p>
              <Checkbox value={'Undetermined treatment'}>
                <div className='mb-1 text-base'>{_t('Undetermined treatment')}</div>
              </Checkbox>
            </div>
          </div>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <div className="flex gap-x-2">
          <Button size="large" onClick={onBack}>{_t("form.back")}</Button>
          <Button size="large" onClick={onNext}>{_t("form.next")}</Button>
        </div>
      </Form.Item>
    </>
  )
}

export default StepEightComponent;