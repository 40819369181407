import { useEffect, useState } from "react";
import { Form, FormInstance, InputNumber, Radio } from "antd";
import { _t } from "../../utils/translate";

const BasicPriceComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const [priceUnit, setPriceUnit] = useState("EUR");
  const [quantityUnit, setQuantityUnit] = useState("form.yes");

  useEffect(() => {
    updateFilter({
      "priceUnit": form.getFieldValue('priceUnit'),
      "quantityUnit": form.getFieldValue('quantityUnit'),
      "priceMin": form.getFieldValue('priceMin'),
      "priceMax": form.getFieldValue('priceMax')
    });
  }, []);

  const onPriceUnitChange = (e: any) => {
    setPriceUnit(e.target.value);
    updateFilter({ 'priceUnit': e.target.value })
  }

  const onQuantityUnitChange = (e: any) => {
    setQuantityUnit(e.target.value);
    updateFilter({ 'quantityUnit': e.target.value })
  }

  const onMinChange = (value: number | null) => {
    updateFilter({'priceMin': value});
  }

  const onMaxChange = (value: number | null) => {
    updateFilter({'priceMax': value});
  }

  return (
    <>
      <Form.Item name="priceUnit" style={{ marginBottom: 4 }}>
        <Radio.Group size="large" onChange={onPriceUnitChange} defaultValue={'EUR'}>
          <Radio value={"EUR"} key={"EUR"} className="text-base">EUR</Radio>
          <Radio value={"MAD"} key={"MAD"} className="text-base">MAD</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="quantityUnit" style={{ marginBottom: 4 }}>
        <Radio.Group size="large" onChange={onQuantityUnitChange} defaultValue={'form.yes'} className="uppercase">
          <Radio value={"form.yes"} key={"form.yes"} className="text-base">{_t("form.yes")}</Radio>
          <Radio value={"form.no"} key={"form.no"} className="text-base">{_t("form.no")}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="priceMin" label={_t("form.min")} style={{ marginBottom: 4 }}>
        <InputNumber size="large" addonAfter={<span className="uppercase">{priceUnit}/{_t(quantityUnit)}</span>} onChange={onMinChange} />
      </Form.Item>
      <Form.Item name="priceMax" label={_t("form.max")} style={{ marginBottom: 0 }}>
        <InputNumber size="large" addonAfter={<span className="uppercase">{priceUnit}/{_t(quantityUnit)}</span>} onChange={onMaxChange} />
      </Form.Item>
    </>
  )
}

export default BasicPriceComponent;
