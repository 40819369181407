import HeaderMobileView from './header-mobile';
import HeaderDesktopView from './header-desktop';
import { BrowserView, MobileView } from 'react-device-detect';

const Header = () => {
  return (
    <header className='fixed w-full bg-[#F9FAFB] z-10'>
      <BrowserView className="sticky border border-b-[#D1D5DB] px-[31.74px] py-[10px] flex items-center justify-between">
        <HeaderDesktopView />
      </BrowserView>
      <MobileView className="sticky border border-b-[#D1D5DB] px-[31.74px] py-[10px] flex items-center justify-between">
        <HeaderMobileView />
      </MobileView>
    </header>
  )
}

export default Header;