import { useEffect, useState } from "react";
import { Button, Form, FormInstance, InputNumber } from "antd";
import Select from 'react-select';
import { OptionType } from "../../utils/types";
import { compositionTypesData } from "../../utils/constants";
import { _t } from "../../utils/translate";
import { isMobile } from "react-device-detect";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { customFilterOption } from "../../utils/utils";

const StepFourComponent = ({ form, showToast, update }: { form: FormInstance, showToast: Function, update: Function }) => {
  const [availableCompositions, setAvailableCompositions] = useState<OptionType[]>([]);
  const [selectedCompositions, setSelectedCompositions] = useState<string[]>(["", "", "", ""]);
  const [values, setValues] = useState<number[]>([0, 0, 0, 0]);
  const [sum, setSum] = useState<number>(0);
  const { lang } = useSelector((state: RootState) => state.auth);

  const getSum = (arr: number[]) => {
    return arr.reduce((acc: number, cur: number) => {
      return acc + cur;
    }, 0)
  }

  useEffect(() => {
    const initCompositions = [
      form.getFieldValue("compExp1") ? form.getFieldValue("compExp1").value : "",
      form.getFieldValue("compExp2") ? form.getFieldValue("compExp2").value : "",
      form.getFieldValue("compExp3") ? form.getFieldValue("compExp3").value : "",
      form.getFieldValue("compExp4") ? form.getFieldValue("compExp4").value : "",
    ];
    setSelectedCompositions(initCompositions);
    const values = [
      form.getFieldValue("compVal1"),
      form.getFieldValue("compVal2"),
      form.getFieldValue("compVal3"),
      form.getFieldValue("compVal4"),
    ];
    setValues(values);
    setSum(getSum(values.filter(val => val && val.toString() !== "")));
    setAvailableCompositions(compositionTypesData.map((val: string) => ({
      realValue: val, value: messages[lang][val], label: _t(val), disabled: initCompositions.includes(val)
    })));
  }, [])

  const onSelectChange = (index: number, option: any) => {
    selectedCompositions[index] = option.realValue;
    setSelectedCompositions(selectedCompositions);
    setAvailableCompositions(compositionTypesData.map((val: string) => ({
      realValue: val, value: messages[lang][val], label: _t(val), disabled: selectedCompositions.includes(val)
    })));
  }

  const onValueChange = (index: number, value: number) => {
    values[index - 1] = value;
    setValues(values);
    setSum(getSum(values.filter(val => val && val.toString() !== "")));
  }

  const onBack = async () => {
    update('prev-step');
  }

  const onNext = async () => {
    try {
      const values = await form.validateFields();

      if (sum !== 100) {
        form.setFields([
          { name: "compVal1", errors: [''] },
          { name: "compVal2", errors: [''] },
          { name: "compVal3", errors: [''] },
          { name: "compVal4", errors: [''] },
        ]);
        throw "error.publishCompositionError";
      }

      if (values['compVal1'] !== '' && values['compExp1'] === '') {
        form.setFields([{ name: 'compExp1', errors: [''] }]);
        throw "error.publishError";
      }

      if (values['compVal2'] !== '' && values['compExp2'] === '') {
        form.setFields([{ name: 'compExp2', errors: [''] }]);
        throw "error.publishError";
      }

      if (values['compVal3'] !== '' && values['compExp3'] === '') {
        form.setFields([{ name: 'compExp3', errors: [''] }]);
        throw "error.publishError";
      }

      if (values['compVal4'] !== '' && values['compExp4'] === '') {
        form.setFields([{ name: 'compExp4', errors: [''] }]);
        throw "error.publishError";
      }

      update('next-step');
    } catch (error) {
      if (typeof error === "string") {
        showToast('error', _t(error));
      } else {
        showToast('error', _t("error.publishError"));
      }
    }
  }

  return <>
    {
      [1, 2, 3, 4].map((index: number) => (
        <Form.Item label={_t("Material")} style={{ marginBottom: 0 }} key={'custom-panel' + index}>
          <div className="flex gap-x-2">
            <Form.Item name={`compExp${index}`} initialValue={""}>
              <Select
                styles={{
                  container: (base) => ({
                    ...base, width: 200, fontSize: 16,
                    borderRadius: 4,
                    border: form.getFieldError(`compExp${index}`).length > 0 ? '1px solid red' : ''
                  })
                }}
                isSearchable placeholder=""
                options={availableCompositions.sort((a: OptionType, b: OptionType) => messages[lang][a.realValue].localeCompare(messages[lang][b.realValue]))}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                isOptionDisabled={(option: any) => option.disabled}
                onChange={(value: any) => onSelectChange(index, value)}
                // onChange={(val: any) => { setDrawing(val); form.setFieldValue('drawing', ""); }}
                isDisabled={sum >= 100 && !(form.getFieldValue(`compVal${index}`) > 0)}
                filterOption={customFilterOption}
              />
            </Form.Item>
            <Form.Item name={`compVal${index}`} initialValue={''}>
              <InputNumber size="large" suffix={"%"} min={0} max={100} onChange={(value: any) => onValueChange(index, value)} disabled={sum >= 100 && !(form.getFieldValue(`compVal${index}`) > 0)} />
            </Form.Item>
          </div>
        </Form.Item>
        // <div key={'custom-panel' + index} className="flex justify-center flex-col md:flex-row">
        //   <span className="text-blue-700 text-lg font-semibold mr-3 h-[32px]">{_t("Add the material and its percentage")}:</span>
        //   <div className="flex justify-center gap-x-2" key={'custom-panel-exp' + index}>
        //     <Form.Item name={`compExp${index}`} initialValue={""}>
        //       <Select
        //         styles={{
        //           container: (base) => ({
        //             ...base, width: 200, fontSize: 16,
        //             borderRadius: 4,
        //             border: form.getFieldError(`compExp${index}`).length > 0 ? '1px solid red' : ''
        //           })
        //         }}
        //         isSearchable placeholder=""
        //         options={availableCompositions.sort((a: OptionType, b: OptionType) => messages[lang][a.realValue].localeCompare(messages[lang][b.realValue]))}
        //         getOptionLabel={(option: any) => option.label}
        //         getOptionValue={(option: any) => option.value}
        //         isOptionDisabled={(option: any) => option.disabled}
        //         onChange={(value: any) => onSelectChange(index, value)}
        //         // onChange={(val: any) => { setDrawing(val); form.setFieldValue('drawing', ""); }}
        //         isDisabled={sum >= 100 && !(form.getFieldValue(`compVal${index}`) > 0)}
        //       />
        //     </Form.Item>
        //   {/* </div>
        //   <div key={'custom-panel-val' + index} className="ml-3"> */}
        //     <Form.Item name={`compVal${index}`} initialValue={''}>
        //       <InputNumber size="large" suffix={"%"} min={0} max={100} onChange={(value: any) => onValueChange(index, value)} disabled={sum >= 100 && !(form.getFieldValue(`compVal${index}`) > 0)} />
        //     </Form.Item>
        //   </div>
        // </div>
      ))
    }
    <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
      <div className="flex gap-x-2">
        <Button size="large" onClick={onBack}>{_t("form.back")}</Button>
        <Button size="large" onClick={onNext}>{_t("form.next")}</Button>
      </div>
    </Form.Item>
  </>
}

export default StepFourComponent;