import { useEffect } from "react";
import { Form, FormInstance } from "antd";
import Select from 'react-select';
import { _t } from "../../utils/translate";
import { OptionType } from "@/utils/types";
import { ICity } from "country-state-city";
import { customFilterOption } from "../../utils/utils";

const MoreCityComponent = ({ form, updateFilter, cities }: { form: FormInstance, updateFilter: Function, cities: ICity[] | undefined }) => {
  useEffect(() => {
    updateFilter({
      "stockCity": form.getFieldValue('stockCity'),
    });
  }, []);

  const onSelectChange = (newOption: OptionType) => {
    updateFilter({ "stockCity": newOption });
  }

  if (cities === undefined) {
    return <span className="flex text-center">{_t("Please choose Country of stock")}</span>;
  }

  return cities && cities.length ? (
    <Form.Item name="stockCity" className="flex-1" style={{ marginBottom: 4 }}>
      <Select
        isSearchable
        placeholder=""
        styles={{ container: (base) => ({ ...base, width: '100%', borderRadius: 4, fontSize: 16 }) }}
        options={(cities ? cities : []).map((city) => { return { value: city.name, label: city.name }; })}
        getOptionLabel={(option: any) => option.label}
        getOptionValue={(option: any) => option.value}
        onChange={onSelectChange}
        filterOption={customFilterOption}
      />
    </Form.Item>
  ) : <span className="flex text-center">{_t("The selected country does not have any cities.")}</span>;
}

export default MoreCityComponent;
