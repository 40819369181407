import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Row, Skeleton } from "antd";
import { RootState } from "@/redux/store";
import { SellerDetailType } from "@/utils/types";
import { _t } from "../../utils/translate";
import { useEffect, useState } from "react";
import { fetchData, parsePhoneNumber } from "../../utils/utils";
import { BrowserView, MobileView } from "react-device-detect";

const SellerDetailsPage = ({ details }: { details: SellerDetailType | undefined }) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);

  const buildContact = () => details ? (
    <>
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.fullName")}:</span>
        <span className="text-blue-600">{details.fullName}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.directPhone")}:</span>
        <span className="text-blue-600">{parsePhoneNumber(details.sellerPhone)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.sellerPhone")}:</span>
        <span className="text-blue-600">{parsePhoneNumber(details.phone)}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.email")}:</span>
        <span className="text-blue-600">{details.email}</span>
      </div>
    </>
  ) : "";

  const buildLocation = () => details ? (
    <>
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.city")}:</span>
        <span className="text-blue-600">{details.city ? details.city : " - "}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.state")}:</span>
        <span className="text-blue-600">{details.state ? details.state : " - "}</span>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <span className="font-semibold text-blue-800">{_t("form.country")}:</span>
        <span className="text-blue-600">{details.country ? details.country : " - "}</span>
      </div>
    </>
  ) : "";

  const MainComponent = () => {
    return !details
      ? <></>
      : (
        <>
          <BrowserView className="flex justify-between text-base">
            <div className="flex-1 flex flex-col">
              {buildContact()}
            </div>
            <div className="flex justify-center mx-2">
              <Divider type="vertical" className="h-full border-blue-500" />
            </div>
            <div className="flex-1 flex flex-col">
              {buildLocation()}
            </div>
          </BrowserView>
          <MobileView>
            {buildContact()}
            <Divider className="my-2" />
            {buildLocation()}
          </MobileView>
        </>
      )
  };

  const ErrorComponent = () => (
    <div className="flex flex-col items-center">
      <h1 className="text-xl my-12 text-[#1E40AF] font-semibold">{_t("details.sellerTitle")}</h1>
      <Button size="large" onClick={() => navigate('/auth/login')}>{_t("header.signIn")}</Button>
      <div className="relative w-[350px] my-3">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t border-black"></span>
        </div>
        <div className="relative flex justify-center text-base font-semibold uppercase">
          <span className="bg-white px-3">{_t("login.or")}</span>
        </div>
      </div>
      <Button className="hover:text-red-600" size="large" onClick={() => navigate('/auth/register')}>{_t("header.signUp")}</Button>
    </div>
  )

  if (!auth) return <ErrorComponent />;

  return <MainComponent />;
}

export default SellerDetailsPage;