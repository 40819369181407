import { useEffect, useState } from "react";
import { Checkbox, Form, FormInstance, Space } from "antd";
import { _t } from "../../utils/translate";
import { certifications } from "../../utils/constants";
// import { messages } from '../../utils/translate';
// import { RootState } from "@/redux/store";
// import { useSelector } from "react-redux";

const CertificationsComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const [values, setValues ] = useState<string[]> ([]);
  // const { lang } = useSelector((state: RootState) => state.auth);
 
  useEffect(() => updateFilter({'certifications': form.getFieldValue('certifications')}), []);

  const onChange = (values: any) => {
    setValues(values);
    updateFilter({'certifications': values});
  }

  return (
    <Form.Item name="certifications" initialValue={undefined} style={{ height: 150, overflowY: 'auto', marginBottom: 0 }}>
      <Checkbox.Group value={values} onChange={onChange}>
        <Space direction="vertical">
          {["Undetermined certification", ...certifications.sort((a: string, b: string) => a.localeCompare(b))]
            .map(type => <Checkbox key={"certifications" + type} value={type}>{_t(type)}</Checkbox>)}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  )
}

export default CertificationsComponent;
