import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import { Button, Card, Modal, Skeleton, message } from "antd"
// import { AlarmClock, Building2, CircleDollarSign, Ruler, User, User2 } from "lucide-react";

// import { formatDistanceToNow } from "date-fns";
// import { enUS, fr } from 'date-fns/locale'
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import { ColorDropdownType, FabricDataType } from "../../utils/types";
import { _t } from "../../utils/translate";
import { FABRIC_URI, postData, formatNumber } from "../../utils/utils";
import { primaryColors, shadowColors } from "../../utils/constants";
import { CircleUserRound, User2 } from "lucide-react";
import { useDispatch } from "react-redux";

import { setFilterEnabled } from "../../redux/authSlice";

const FabricCard = ({ item, onRefresh }: { item: FabricDataType, onRefresh: Function }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth, lang } = useSelector((state: RootState) => state.auth);

  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();

  const onDetails = () => {
    dispatch(setFilterEnabled(true));
    navigate(`/fabrics/details?id=${item.id}`);
    // window.open(`/fabrics/details?id=${item.id}`, '_blank');
  }

  const shadeColor = useMemo(() => {
    const primaryColor = primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === item.principalColor)].label;
    const shadeItems = [...shadowColors[`light${primaryColor}`], ...shadowColors[`dark${primaryColor}`]];
    return shadeItems[shadeItems.findIndex((e: ColorDropdownType) => e.label === item.colorShade)].color;
  }, [item])

  const fabricLocation = useMemo(() => {
    let result: string[] = [];
    if (item.stockCity) result.push(item.stockCity);
    // if (item.stockState) result.push(item.stockState);
    if (item.stockCountry) result.push(item.stockCountry);
    return result.join(", ");
  }, [item]);

  const fyield = useMemo(() => {
    return 1000 / (item.grammage * item.width * item.coef);
  }, [item])

  const priceMadMts = item.price * (100 - item.remise) / 100 * (item.currency.toUpperCase() === 'MAD' ? 1 : 10) / (item.unit === 'Mts' ? 1 : fyield);

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const showDeleteConfirm = () => {
    setIsDeleteVisible(true);
  }

  const onDeleteFabric = async () => {
    setIsDeleteVisible(false);
    try {
      const apiResult = await postData('api/fabric/delete', { id: item.id });
      showToast('success', _t("details.deletedFabric"));

      onRefresh();
    } catch (error) {
      console.error(error);
      showToast('error', _t("details.deleteFabricError"));
    }
  }

  return (
    <>
      <Card
        className="h-full"
        styles={{
          body: {
            padding: 0,
            boxShadow: '0 1px 5px #021263',
            borderRadius: 8,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        {contextHolder}
        <div className="relative">
          {item.image
            ? <img className="w-full rounded-md rounded-b-none border-red-600" style={{ aspectRatio: 1 }} src={FABRIC_URI + '/' + item.image} />
            : <div className="w-full rounded-md rounded-b-none border-red-600" style={{ backgroundColor: shadeColor, aspectRatio: 1 }}></div>}
          <div className="absolute top-2 left-2 font-semibold text-lg flex items-center gap-x-2">
            <span className="bg-white p-2 rounded-md">{item.ref}</span>
            {auth && auth.type === 'ADMIN' &&
              <Button size="large"
                onClick={() => showDeleteConfirm()}
                danger icon={<DeleteOutlined />}
              />
            }
          </div>
          {item.remise !== 0 &&
            <span className="absolute top-[10px] right-[8px] px-2 py-[6px] bg-white border border-red-500 rounded-lg text-lg font-semibold text-red-600">
              -{item.remise}%
            </span>}
          {auth && auth.type === 'ADMIN' && (
            <div className="absolute bottom-2 right-2 rounded-md bg-white font-semibold text-md p-2">
              {item.sellerName}
            </div>
          )}
        </div>
        <div className="flex-1 flex flex-col justify-between">
          {/* <div className="flex flex-col gap-2 p-3">
            <div className="flex justify-between items-start text-lg h-[48px]">
              <span className="font-semibold overflow-hidden truncate">{item.ref}</span>
              <div className="flex items-start gap-x-1 font-semibold">
                {item.remise === 0
                  ? <span>{item.price} MAD/{item.unit === 'Mts' ? 'm' : 'kg'}</span>
                  : <div className="flex flex-col">
                    <span className="">{(item.price * (100 - item.remise) / 100).toFixed(1)} MAD/{item.unit === 'Mts' ? 'm' : 'kg'}</span>
                    <span className="line-through text-red-500 text-sm text-right">{item.price} MAD/{item.unit === 'Mts' ? 'm' : 'kg'}</span>
                  </div>}
              </div>
            </div>
            <div className="flex justify-between text-lg">
              <div className="flex gap-2 items-center">
                <Ruler />
                <span className="font-bold">{item.currentStock}</span> {item.unit === 'Mts' ? 'm' : 'kg'}
              </div>
              <div style={{ width: 40, height: 30, backgroundColor: shadeColor, boxShadow: '0 0 5px black', borderRadius: 10 }}></div>
            </div>
            {auth && auth.type === 'ADMIN' && (
              <div className="flex gap-2 items-center  text-lg">
                <User2 />
                <span className="font-semibold">{item.sellerName}</span>
              </div>
            )}
            <div className="flex gap-3 justify-between items-center">
              <div className="flex gap-2 items-center w-full">
                <Building2 />
                <span className="flex-1 overflow-hidden truncate">{fabricLocation}</span>
              </div>
            </div>
            <div className="flex gap-3 justify-between items-center">
              <div className="flex gap-2 w-full">
                <AlarmClock />
                <span className="flex-1 overflow-hidden capitalize-first-letter">
                  {formatDistanceToNow(new Date(item.date.toString()), { locale: lang === 'en' ? enUS : fr, addSuffix: true })}
                </span>
              </div>
            </div>
          </div> */}
          <div className="relative w-full mt-2">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-blue-500"></span>
            </div>
            <div className="relative flex justify-center text-sm font-semibold">
              <span className="bg-white px-3 text-blue-600 text-lg">{_t("form.quantity")}</span>
            </div>
          </div>
          <div className="grid grid-cols-3 p-3 text-blue-700 font-semibold">
            <div className="flex flex-col items-center gap-x-1 mb-1">
              <span className="text-lg">
                {formatNumber(Number.isInteger(item.currentStock) ? item.currentStock : item.currentStock)}
              </span>
              <span className="text-xs underline text-center">{_t("Available")}</span>
            </div>
            <div className="flex flex-col items-center gap-x-1 mb-1">
              <span className="text-lg">
                {formatNumber(Number.isInteger(item.quantityAvailableInTwoWeeks) ? item.quantityAvailableInTwoWeeks : item.quantityAvailableInTwoWeeks)}
              </span>
              <span className="text-xs underline text-center">{_t("In 2 weeks")}</span>
            </div>
            <div className="flex flex-col items-center gap-x-1 mb-1">
              <span className="text-lg">
                {formatNumber(Number.isInteger(item.availabilityIn6Weeks) ? item.availabilityIn6Weeks : item.availabilityIn6Weeks)}
              </span>
              <span className="text-xs underline text-center">{_t("In 5 weeks")}</span>
            </div>
          </div>
          <div className="relative w-full">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-blue-500"></span>
            </div>
            <div className="relative flex justify-center text-sm font-semibold">
              <span className="bg-white px-3 text-blue-600 text-lg">{_t("form.price")}</span>
            </div>
          </div>
          <div className="grid grid-cols-2 p-3 text-blue-700 font-semibold">
            <div className="flex justify-center items-end gap-x-1 mb-5">
              <span className="text-lg">
                {formatNumber(Number.isInteger(priceMadMts) ? priceMadMts : priceMadMts)}
              </span>
              <span className="text-xs underline">MAD/mts</span>
            </div>
            <div className="flex justify-center items-end gap-x-1 mb-5">
              <span className="text-lg">
                {formatNumber(Number.isInteger(priceMadMts * fyield) ? (priceMadMts * fyield) : (priceMadMts * fyield))}
              </span>
              <span className="text-xs underline">MAD/kg</span>
            </div>
            <div className="flex justify-center items-end gap-x-1 mb-1">
              <span className="text-lg">
                {formatNumber(Number.isInteger(priceMadMts * 0.1) ? (priceMadMts * 0.1) : (priceMadMts * 0.1))}
              </span>
              <span className="text-xs underline">EUR/mts</span>
            </div>
            <div className="flex justify-center items-end gap-x-1 mb-1">
              <span className="text-lg">
                {formatNumber(Number.isInteger(priceMadMts * fyield * 0.1) ? (priceMadMts * fyield * 0.1) : (priceMadMts * fyield * 0.1))}
              </span>
              <span className="text-xs underline">EUR/kg</span>
            </div>
          </div>
          <Button className="m-3 mt-0" size="large" onClick={onDetails}>{_t("form.seeDetails")}</Button>
        </div>
      </Card>
      <Modal
        title={<div className="text-lg"><ExclamationCircleFilled className="mr-2" /> {_t("Confirm")}</div>}
        onCancel={() => setIsDeleteVisible(false)}
        open={isDeleteVisible} centered footer="">
        <div className="flex flex-col gap-3">
          <span className="text-base">{_t("Are you sure you want to remove this fabric?")}</span>
          <div className="flex justify-end gap-3">
            <Button size="large" type="primary" danger onClick={() => onDeleteFabric()}>{_t("Delete")}</Button>
            <Button size="large" onClick={() => setIsDeleteVisible(false)}>{_t("details.close")}</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FabricCard;