import { useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Form, Modal, Spin, message } from "antd";
import Select from 'react-select';

import StepOneComponent from "../../components/publish/Step1";
import StepTwoComponent from "../../components/publish/Step2";
import StepThreeComponent from "../../components/publish/Step3";
import StepFourComponent from "../../components/publish/Step4";
import StepFiveComponent from "../../components/publish/Step5";
import StepSixComponent from "../../components/publish/Step6";
import StepSevenComponent from "../../components/publish/Step7";
import StepEightComponent from "../../components/publish/Step8";
import StepNineComponent from "../../components/publish/Step9";
import StepTenComponent from "../../components/publish/Step10";

import { convertResultFormToApi, customFilterOption, fetchData, postData, uploadData } from "../../utils/utils";
import { _t, messages } from "../../utils/translate";
import { Check } from "lucide-react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { isMobile } from "react-device-detect";

const FabricEdit = () => {
  const navigate = useNavigate();
  const { lang } = useSelector((state: RootState) => state.auth);

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [curStep, setCurStep] = useState<number>(-1);
  const [result, setResult] = useState<Record<string, any>>({});

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [isContinueModalOpen, setIsContinueModalOpen] = useState<boolean>(false);
  const [draft, setDraft] = useState<Record<string, any>>({});
  const [isNavigationBlocked, setIsNavigationBlocked] = useState<boolean>(true);
  const [nextLocation, setNextLocation] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, showSuccessModal] = useState<boolean>(false);
  const [multiOption, setMultiOption] = useState<string>("form.unified");

  const [publishedId, setPublishedId] = useState<number>(-1);
  const [refList, setRefList] = useState<any[]>([]);
  const [totalRefList, setTotalRefList] = useState<any[]>([]);
  const [dupRef, setDupRef] = useState<string>("");
  const [fileImage, setFileImage] = useState<File>();

  useEffect(() => {
    getRefList();
  }, [])

  const getRefList = async () => {
    setIsPageLoading(true);
    try {
      const apiResult = await fetchData('api/auth/get-ref-list', {});

      if (apiResult && apiResult.refs) {
        setRefList(apiResult.refs.map((t: string) => ({ label: t, value: t })));
        setTotalRefList(apiResult.totalRefs.map((t: string) => ({ label: t, value: t })));
        apiResult.refs.length && setDupRef(apiResult.refs[0]);
      }
    } catch (error: any) {
      console.error(error);
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message));
      }
    } finally {
      setIsPageLoading(false);
    }
  }

  const restoreDraft = async () => {
    setCurStep(1);
    setIsPageLoading(true);
    try {
      const apiResult = await fetchData('api/auth/restore_fabric', {});

      if (apiResult && apiResult.fabric && apiResult.fabric !== "") {
        setIsContinueModalOpen(true);

        setDraft(JSON.parse(apiResult.fabric));
      }
    } catch (error: any) {
      console.error(error);
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message));
      }
    } finally {
      setIsPageLoading(false);
    }
  }

  useBlocker(({ currentLocation, nextLocation }) => {
    if (curStep < 2 || currentLocation.pathname !== '/fabrics/publish') return false;

    setNextLocation(nextLocation.pathname);
    setIsSaveModalOpen(true);
    return isNavigationBlocked;
  });

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onUpdate = (type: string, data: Record<string, any> | File | undefined) => {
    switch (type) {
      case 'next-step':
        setResult({ ...result, ...form.getFieldsValue(), ...data });
        setCurStep(curStep + 1);
        break;
      case 'final-step':
        onPublish({ ...result, ...data });
        break;
      case 'data-submit':
        setIsSubmitting(true);
        break;
      case 'data-submitted':
        setIsSubmitting(false);
        break;
      case 'prev-step':
        setCurStep(curStep - 1);
        break;
      case 'update-property':
        setResult({ ...result, ...data });
        break;
      case 'first-step':
        setCurStep(1);
        break;
      case 'file-image':
        setFileImage(data as File);
        break;
    }
  }

  const onPublish = async (result: Record<string, any>) => {
    setIsSubmitting(true);
    try {
      const updatedResult = { ...result, ...form.getFieldsValue() };
      const apiResult = await postData('api/fabric/create', JSON.stringify(convertResultFormToApi(updatedResult)));
      setPublishedId(apiResult.id);
      showSuccessModal(true);
    } catch (error: any) {
      console.error(error);
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message))
      } else {
        showToast('error', _t("An error occured with connecting to the server"));
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const saveDraft = async () => {
    setIsSaveModalOpen(false);
    setIsNavigationBlocked(false);
    showToast('loading', _t("error.saveDraft"));

    try {
      const fileImage = await form.getFieldValue('image');
      if (fileImage) {
        let data = new FormData();
        data.append('file', fileImage!);
        const apiResult = await uploadData('api/fabric/file-upload', data);
        form.setFieldValue('image', apiResult.key);
      }

      const values = await form.getFieldsValue();
      const updatedResult = { ...result, ...values };
      // if (updatedResult['drawing']) updatedResult['drawing'] = updatedResult['drawing'].map((t: any) => t.value);

      await postData('api/auth/save_fabric', { fabric: JSON.stringify({ ...draft, ...convertResultFormToApi(updatedResult) }) });
      showToast('success', _t("error.savedDraft"));
    } catch (error) {
      console.error('SaveDraft :: ', error);
    } finally {
      navigate(nextLocation);
    }
  }

  const dontSaveDraft = async () => {
    setIsSaveModalOpen(false);
    setIsNavigationBlocked(false);
    try {
      await postData('api/auth/clear_fabric', {});
      form.resetFields();
    } catch (error) {
      console.error('Clear draft ::', error);
    } finally {
      setTimeout(() => navigate(nextLocation), 500);
    }
  }

  const continueDraft = async () => {
    Object.keys(draft).forEach((key) => {
      form.setFieldValue(key, draft[key]);
    });

    draft['salesMethod'] !== undefined && form.setFieldValue('salesMethod', draft['salesMethod'] !== 'form.no' ? 'form.yes' : 'form.no');
    draft['commercialName'] !== undefined && form.setFieldValue('commercialName', { realValue: draft['commercialName'], value: _t(draft['commercialName']), label: _t(draft['commercialName']) });
    draft['armor'] !== undefined && form.setFieldValue('armor', { realValue: draft['armor'], value: _t(draft['armor']), label: _t(draft['armor']) });

    draft['compExp1'] !== undefined && draft['compExp1'] !== "" && form.setFieldValue('compExp1', { realValue: draft['compExp1'], value: _t(draft['compExp1']), label: _t(draft['compExp1']) });
    draft['compExp2'] !== undefined && draft['compExp2'] !== "" && form.setFieldValue('compExp2', { realValue: draft['compExp2'], value: _t(draft['compExp2']), label: _t(draft['compExp2']) });
    draft['compExp3'] !== undefined && draft['compExp3'] !== "" && form.setFieldValue('compExp3', { realValue: draft['compExp3'], value: _t(draft['compExp3']), label: _t(draft['compExp3']) });
    draft['compExp4'] !== undefined && draft['compExp4'] !== "" && form.setFieldValue('compExp4', { realValue: draft['compExp4'], value: _t(draft['compExp4']), label: _t(draft['compExp4']) });

    draft['compVal1'] !== undefined && form.setFieldValue('compVal1', draft['compVal1']);
    draft['compVal2'] !== undefined && form.setFieldValue('compVal2', draft['compVal2']);
    draft['compVal3'] !== undefined && form.setFieldValue('compVal3', draft['compVal3']);
    draft['compVal4'] !== undefined && form.setFieldValue('compVal4', draft['compVal4']);

    draft['pattern'] && form.setFieldValue('pattern', { realValue: draft['pattern'], value: messages[lang][draft['pattern']], label: _t(draft['pattern']) });
    draft['drawing'] && form.setFieldValue('drawing', draft['drawing'].filter((val: any) => val).map((val: string) => ({ realValue: val, value: messages[lang][val], label: _t(val) })));

    draft['type1'] !== undefined && form.setFieldValue('type1', { realValue: draft['type1'], value: _t(draft['type1']), label: _t(draft['type1']) });
    draft['type2'] !== undefined && form.setFieldValue('type2', { realValue: draft['type2'], value: _t(draft['type2']), label: _t(draft['type2']) });
    draft['type3'] !== undefined && form.setFieldValue('type3', { realValue: draft['type3'], value: _t(draft['type3']), label: _t(draft['type3']) });
    draft['type4'] !== undefined && form.setFieldValue('type4', { realValue: draft['type4'], value: _t(draft['type4']), label: _t(draft['type4']) });
    draft['type5'] !== undefined && form.setFieldValue('type5', { realValue: draft['type5'], value: _t(draft['type5']), label: _t(draft['type5']) });

    draft['stockCountry'] !== undefined && form.setFieldValue('stockCountry', { value: draft['stockCountry'], label: draft['stockCountry'] });
    draft['stockState'] !== undefined && form.setFieldValue('stockState', { value: draft['stockState'], label: draft['stockState'] });
    draft['stockCity'] !== undefined && form.setFieldValue('stockCity', { value: draft['stockCity'], label: draft['stockCity'] });
    draft['origin'] !== undefined && form.setFieldValue('origin', { value: draft['origin'], label: draft['origin'] });

    setMultiOption(draft['colorType']);

    setIsContinueModalOpen(false);
  }

  const startNewDraft = async () => {
    setIsContinueModalOpen(false);
    await postData('api/auth/clear_fabric', {});
  }

  // const onSeePreview = () => {
  //   setIsNavigationBlocked(false);
  //   setTimeout(() => navigate(publishedId === -1 ? '/' : `/fabrics/details?id=${publishedId}`), 200);
  // }

  // const onMyFabrics = () => {
  //   setIsNavigationBlocked(false);
  //   setTimeout(() => navigate(publishedId === -1 ? '/' : `/space/fabrics`), 200);
  // }

  const onPublishAnother = () => {
    showSuccessModal(false);
    setCurStep(-1);
  }

  const onFinish = () => {
    showSuccessModal(false);
    setIsNavigationBlocked(false);
    setTimeout(() => navigate('/'), 200);
  }

  const buildBody = () => {
    let bodyComponent: React.ReactNode = <></>;
    switch (curStep) {
      case -1:
        bodyComponent = (
          <div className="flex flex-col items-center gap-y-5">
            <p className="font-semibold text-xl text-center mb-5 text-blue-600">{_t("register.askChoice")}:</p>
            <Button type="primary" size="large" onClick={() => restoreDraft()}>{_t("Publish a new fabric")}</Button>
            <div className="relative w-[350px] my-3">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t border-blue-600"></span>
              </div>
              <div className="relative flex justify-center text-md font-semibold uppercase">
                <span className="bg-white text-blue-600 px-3">{_t("login.or")}</span>
              </div>
            </div>
            <Button
              type="primary" size="large" className="w-[300px] h-[64px] text-center"
              onClick={() => setCurStep(0)}
            >
              <span className="whitespace-pre-wrap">
                {_t("Publish an existing reference with different color")}
              </span>
            </Button>
          </div>
        );
        break;
      case 0:
        bodyComponent = (
          <div className="flex flex-col items-center gap-y-5">
            <p className="font-semibold text-xl text-center mb-5 text-blue-600">{_t("Please enter the reference")}:</p>
            <Form>
              <Form.Item label={_t("form.ref")}>
                <Select
                  isSearchable
                  placeholder=""
                  styles={{ container: (base) => ({ ...base, width: 300, borderRadius: 4, fontSize: 16 }) }}
                  options={refList}
                  getOptionValue={(t: any) => t.value}
                  value={{ label: dupRef, value: dupRef }}
                  onChange={(t: any) => setDupRef(t.value)}
                  filterOption={customFilterOption}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
                <div className="flex gap-x-2">
                  <Button size="large" onClick={() => setCurStep(-1)}>{_t("form.back")}</Button>
                  <Button size="large" onClick={() => navigate(`/fabrics/duplicate?ref=${dupRef}`)}>{_t("form.next")}</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        );
        break;
      default:
        bodyComponent = (
          <Card className="flex justify-center mb-5">
            <Form
              form={form}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              className="w-full md:w-[1280px]"
            >
              {curStep === 1 && <StepOneComponent form={form} showToast={showToast} update={onUpdate} initMultiOption={multiOption} refs={totalRefList} />}
              {curStep === 2 && <StepTwoComponent form={form} showToast={showToast} update={onUpdate} coef={result.turbularOpen !== 'form.turbular' ? 1 : 2} />}
              {curStep === 3 && <StepThreeComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 4 && <StepFourComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 5 && <StepFiveComponent form={form} showToast={showToast} update={onUpdate} colorType={form.getFieldValue('colorType')} />}
              {curStep === 6 && <StepSixComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 7 && <StepSevenComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 8 && <StepEightComponent form={form} showToast={showToast} update={onUpdate} />}
              {curStep === 9 && <StepNineComponent form={form} showToast={showToast} update={onUpdate} isLoading={isSubmitting} />}
            </Form>
          </Card>
        );
        break;
    }

    return bodyComponent;
  }

  return (
    <>
      {contextHolder}
      <Spin spinning={isPageLoading} indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} fullscreen />
      {
        curStep === 10
          ? <StepTenComponent
            form={form} details={convertResultFormToApi(result)} showToast={showToast} update={onUpdate}
            fileImage={fileImage} isLoading={isSubmitting}
          />
          : <>
            <div className="flex flex-col items-center">
              <div className="w-full md:w-[768px] px-5">
                <h3 className="font-bold text-3xl text-center mt-6 mb-3 mx-5 text-blue-800">{_t("publish.title")}</h3>
                {curStep >= 1
                  ? <p className="font-semibold text-xl text-center mb-5 text-blue-600">{_t("Step")} {curStep}/9: {_t(`publish.step${curStep}Title`)}</p>
                  : ""}
              </div>
            </div>
            <div className="flex justify-center">
              {buildBody()}
            </div>
          </>
      }
      <Modal title={_t("form.saveDraft")} open={isSaveModalOpen} centered onCancel={() => setIsSaveModalOpen(false)} footer={<></>}>
        <div className="flex flex-col items-center">
          <Button size="large" onClick={saveDraft}>{_t("form.saveDraft")}</Button>
          <div className="relative w-[350px] my-3">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-black"></span>
            </div>
            <div className="relative flex justify-center text-md font-semibold uppercase">
              <span className="bg-white px-3">{_t("login.or")}</span>
            </div>
          </div>
          <Button className="hover:text-red-600" size="large" onClick={dontSaveDraft}>{_t("form.dontSave")}</Button>
        </div>
      </Modal>
      <Modal title={_t("form.continueOrNew")} open={isContinueModalOpen} centered closable={false} footer={<></>}>
        <div className="flex flex-col items-center">
          <Button size="large" onClick={continueDraft}>{_t("form.continueDraft")}</Button>
          <div className="relative w-[350px] my-3">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-black"></span>
            </div>
            <div className="relative flex justify-center text-md font-semibold uppercase">
              <span className="bg-white px-3">{_t("login.or")}</span>
            </div>
          </div>
          <Button className="hover:text-red-600" size="large" onClick={startNewDraft}>{_t("form.startNewDraft")}</Button>
        </div>
      </Modal>
      <Modal open={isSuccess} centered footer={<></>} closable={false}>
        <div className="flex flex-col items-center gap-y-2">
          <Check size={64} color="#00ff9d" />
          <span className="text-2xl font-bold text-blue-700 text-center">{_t("error.fabricPublished")}</span>
          <div className="flex gap-x-3 mt-3">
            <Button type="primary" size="large" onClick={onPublishAnother}>{_t("Publish another fabric")}</Button>
            <Button type="primary" size="large" onClick={onFinish}>{_t("Finish")}</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FabricEdit;