import { Button, Form, FormInstance, Input, InputNumber, Radio, RadioChangeEvent } from "antd";
import { isMobile } from "react-device-detect";
import { elasticities } from "../../utils/constants";
import { _t } from "../../utils/translate";
import { postData } from "../../utils/utils";
import { useEffect, useState } from "react";

const StepTwoComponent = ({ form, showToast, update, coef }: { form: FormInstance, showToast: Function, update: Function, coef: number }) => {
  const [formula, setFormula] = useState<number>(0);
  const [fyield, setFYield] = useState<number>(0);
  const [salesMethod, setSalesMethod] = useState<string>("form.yes");

  useEffect(() => {
    onRecalculateFormula();
    setSalesMethod(form.getFieldValue('salesMethod'))
  }, [])

  const onBack = async () => {
    update('prev-step');
  }

  const onNext = async () => {
    try {
      const values = await form.validateFields();

      let errorFields = [];
      if (values['rollLength'] === 0) {
        errorFields.push({ name: 'rollLength', errors: [''] });
      }
      if (values['salesMethod'] === 'form.yes' && values['minimumSaleLength'] === 0) {
        errorFields.push({ name: 'minimumSaleLength', errors: [''] });
      }
      if (values['grammage'] === 0) {
        errorFields.push({ name: 'grammage', errors: [''] });
      }
      if (values['width'] === 0) {
        errorFields.push({ name: 'width', errors: [''] });
      }
      form.setFields(errorFields);
      if (errorFields.length) throw "";

      update('next-step');
    } catch (error) {
      console.error(error);
      showToast('error', _t("error.publishError"));
    }
  }

  const onSalesMethodChange = (e: RadioChangeEvent) => {
    setSalesMethod(e.target.value);
  }

  const onRecalculateFormula = () => {
    const grammage = form.getFieldValue('grammage') ? form.getFieldValue('grammage').toString() : "";
    const grammageWithDots = grammage.replace(/,/g, '.');
    const sanitizedGrammage = grammageWithDots.replace(/[^0-9.]/g, '').replace(/(\..*?)\./g, '$1');
    form.setFieldValue('grammage', sanitizedGrammage);

    const width = form.getFieldValue('width') ? form.getFieldValue('width').toString() : "";
    const widthWithDots = width.replace(/,/g, '.');
    let sanitizedWidth = widthWithDots.replace(/[^0-9.]/g, '').replace(/(\..*?)\./g, '$1');

    form.setFieldValue('width', sanitizedWidth);

    if (!sanitizedWidth || !sanitizedGrammage) setFormula(0);
    else {
      setFormula(parseFloat(sanitizedGrammage) * parseFloat(sanitizedWidth) * coef);
      setFYield(1000 / (parseFloat(sanitizedGrammage) * parseFloat(sanitizedWidth) * coef));
    }
  }

  return (
    <>
      <Form.Item
        label={_t('form.salesMethod')} name={'salesMethod'} key={'salesMethod'}
        initialValue={'form.yes'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large" onChange={onSalesMethodChange}>
          <Radio value={'form.yes'} key={'form.yes'} className="text-base">{_t('form.yes')}</Radio>
          <Radio value={'form.no'} key={'form.no'} className="text-base">{_t('form.no')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t('form.rollLength')}</span>}
        name={'rollLength'} key={'rollLength'}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={salesMethod === 'form.yes' ? 'Mts' : 'Kgs'} />
      </Form.Item>
      <Form.Item
        label={<span style={{ maxWidth: 225, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t('form.minimumSaleLength')}</span>}
        name={'minimumSaleLength'} key={'minimumSaleLength'}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={salesMethod === 'form.yes' ? 'Mts' : 'Kgs'} />
      </Form.Item>
      <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t('form.grammage')}</span>}
        name={'grammage'} key={'grammage'}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <Input size="large" style={{ width: 300 }} addonAfter={'Gr/m2'} onChange={onRecalculateFormula} />
      </Form.Item>
      <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t('form.width')}</span>}
        name={'width'} key={'width'}
        initialValue={''}
        rules={[
          { required: true, message: '' },
          {
            type: 'number',
            min: 0.4,
            max: 4,
            transform: value => parseFloat(value),
            message: _t('Width should be between 0.4 and 4 meters')
          },
        ]}
      >
        <Input size="large" style={{ width: 300 }} addonAfter={'Mts'} onChange={onRecalculateFormula} />
      </Form.Item>
      <Form.Item
        label={_t('form.formula')} name={'formula'} key={'formula'}
      >
        <label className="text-base">
          {Number.isInteger(formula) ? formula.toFixed(0) : formula.toFixed(2)} Gr/ML
        </label>
      </Form.Item>
      <Form.Item
        label={_t('form.yield')} name={'yield'} key={'yield'}
      >
        <label className="text-base">
          {Number.isInteger(fyield) ? fyield.toFixed(0) : fyield.toFixed(2)} Mt/Kg
        </label>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <div className="flex gap-x-2">
          <Button size="large" onClick={onBack}>{_t("form.back")}</Button>
          <Button size="large" onClick={onNext}>{_t("form.next")}</Button>
        </div>
      </Form.Item>
    </>
  )
}

export default StepTwoComponent;