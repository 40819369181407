import { useEffect, useMemo, useState } from "react";
import { Button, Form, FormInstance } from "antd";
import Select from "react-select";
import { _t } from "../../utils/translate";
import { stepTypesData } from "../../utils/constants";
import { OptionType } from "../../utils/types";
import { isMobile } from "react-device-detect";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { customFilterOption } from "../../utils/utils";

const StepSixComponent = ({ form, showToast, update }: { form: FormInstance, showToast: Function, update: Function }) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>(["", "", "", "", ""]);
  // const [availableTypes, setAvailableTypes] = useState<OptionType[]>([]);
  const { lang } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const initSelectedTypes = [
      form.getFieldValue('type1') ? form.getFieldValue('type1').realValue : "",
      form.getFieldValue('type2') ? form.getFieldValue('type2').realValue : "",
      form.getFieldValue('type3') ? form.getFieldValue('type3').realValue : "",
      form.getFieldValue('type4') ? form.getFieldValue('type4').realValue : "",
      form.getFieldValue('type5') ? form.getFieldValue('type5').realValue : "",
    ];
    setSelectedTypes(initSelectedTypes);
  }, [])

  const availableTypes = useMemo(() => {
    return stepTypesData.map(val => ({
      realValue: val, value: messages[lang][val], label: _t(val), disabled: selectedTypes.includes(val)
    }));
  }, [lang, selectedTypes])

  const onSelectChange = (index: number, newValue: OptionType) => {
    const newSelectedTypes = [...selectedTypes]; // Create a new array
    newSelectedTypes[index - 1] = newValue.realValue;
    setSelectedTypes(newSelectedTypes);
  }

  const onBack = async () => {
    update('prev-step');
  }

  const onNext = async () => {
    try {
      await form.validateFields();
      update('next-step');
    } catch (error) {
      console.error(error);
      showToast('error', _t("error.publishError"));
    }
  }

  return (
    <>
      {[1, 2, 3, 4, 5].map((index) => (
        <Form.Item
          label={_t(`form.type${index}`)} name={`type${index}`} key={`type${index}`}
          rules={[{ required: index < 3, message: '' }]}
        >
          <Select
            styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError(`type${index}`).length > 0 ? '1px solid red' : '' }) }}
            isSearchable placeholder=""
            options={availableTypes.sort((a: OptionType, b: OptionType) => messages[lang][a.realValue].localeCompare(messages[lang][b.realValue]))}
            getOptionValue={(option: any) => option.value}
            isOptionDisabled={(option: any) => option.disabled}
            onChange={(newValue) => onSelectChange(index, newValue)}
            filterOption={customFilterOption}
          />
        </Form.Item>
      ))}
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <div className="flex gap-x-2">
          <Button size="large" onClick={onBack}>{_t("form.back")}</Button>
          <Button size="large" onClick={onNext}>{_t("form.next")}</Button>
        </div>
      </Form.Item>
    </>
  )
}

export default StepSixComponent;