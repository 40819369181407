import { useEffect, useState } from "react";
import { Form, FormInstance, Radio, RadioChangeEvent, Space } from "antd";
import { _t } from "../../utils/translate";

const AdvancedDrawingWidthComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: Boolean }) => {
  const [prevState, setPrevState] = useState<string>("");

  useEffect(() => {
    updateFilter({
      "drawingWidth": form.getFieldValue('drawingWidth'),
    });
    setPrevState(form.getFieldValue('drawingWidth'));
  }, []);

  useEffect(() => {
    setPrevState("");
  }, [reset])

  const handleClick = (value: any) => () => {
    setPrevState(prevState !== "" && prevState === value ? "" : value);
    updateFilter({ 'drawingWidth': prevState !== "" && prevState === value ? undefined : value });
  }

  return (
    <>
      <Form.Item name="drawingWidth" style={{ marginBottom: 0 }}>
        <Radio.Group size="large">
          <Space direction="vertical">
            <Radio value={"form.0to6"} key={'drawingWidth' + "form.0to6"} className="text-base" onClick={handleClick('form.0to6')}>{_t("form.0to6")}</Radio>
            <Radio value={"form.7to12"} key={'drawingWidth' + "form.7to12"} className="text-base" onClick={handleClick('form.7to12')}>{_t("form.7to12")}</Radio>
            <Radio value={"form.13to20"} key={'drawingWidth' + "form.13to20"} className="text-base" onClick={handleClick('form.13to20')}>{_t("form.13to20")}</Radio>
            <Radio value={"form.20to30"} key={'drawingWidth' + "form.20to30"} className="text-base" onClick={handleClick('form.20to30')}>{_t("form.20to30")}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default AdvancedDrawingWidthComponent;