import { useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Button, Form, Input, InputNumber, message } from "antd"
import Select from "react-select"
import {
  Building,
  CornerRightUp,
  Home,
  ListOrdered,
  Phone,
  UsersRound,
  Building2,
} from "lucide-react"
import PhoneInput from "react-phone-input-2"
import { isBrowser } from "react-device-detect"
import { customFilterOption, postData, setAuthToken } from "../../utils/utils"
import { _t } from "../../utils/translate"
import { setAuth, updateAuth } from "../../redux/authSlice"
import { City, Country, State } from "country-state-city"
import { useSelector } from "react-redux"
import { RootState } from "@/redux/store"

const SwitchToSellerPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { auth } = useSelector((state: RootState) => state.auth)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedCountry, setSelectedCountry] = useState<string>("")
  const [selectedState, setSelectedState] = useState<string>("")

  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const countries = Country.getAllCountries()

  const states = useMemo(() => {
    if (!form.getFieldValue("country")) return []

    const country = countries.find(
      (c) => c.name === form.getFieldValue("country").value
    )
    form.setFieldValue("state", "")
    form.setFieldValue("city", "")
    return country ? State.getStatesOfCountry(country.isoCode) : []
  }, [form.getFieldValue("country")])

  const cities = useMemo(() => {
    if (!form.getFieldValue("country")) return []

    const country = countries.find(
      (c) => c.name === form.getFieldValue("country").value
    )
    const state = states.find(
      (c) => c.name === form.getFieldValue("state").value
    )
    form.setFieldValue("city", "")
    return country && state
      ? City.getCitiesOfState(country.isoCode, state.isoCode)
      : []
  }, [form.getFieldValue("state")])

  const buildFormField = (
    label: React.ReactNode,
    key: string,
    prefix: any,
    info?: string
  ) => {
    return (
      <Form.Item
        name={key}
        label={label}
        className='w-full text-base'
        rules={[{ required: key !== "state", message: "" }]}
      >
        {info === undefined && (
          <Input
            type={key === "email" ? "email" : "text"}
            size='large'
            prefix={prefix}
          />
        )}
        {info === "password" && <Input.Password size='large' prefix={prefix} />}
        {info === "number" && (
          <InputNumber className='w-full' size='large' prefix={prefix} />
        )}
        {info === "phone" && (
          <PhoneInput
            country={"ma"}
            inputStyle={{
              width: "100%",
              height: 40,
              borderRadius: 8,
              fontSize: 16,
            }}
            buttonStyle={{ borderRadius: 8 }}
          />
        )}
        {info === "country" && (
          <Select
            isSearchable
            placeholder=''
            styles={{
              container: (base) => ({
                ...base,
                width: "100%",
                borderRadius: 4,
                border:
                  form.getFieldError(key).length > 0 ? "1px solid red" : "",
                fontSize: 16,
              }),
            }}
            options={countries.map((country) => {
              return { value: country.name, label: country.name }
            })}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            filterOption={customFilterOption}
          />
        )}
        {info === "state" && (
          <Select
            isSearchable
            placeholder=''
            styles={{
              container: (base) => ({
                ...base,
                width: "100%",
                borderRadius: 4,
                border:
                  form.getFieldError(key).length > 0 ? "1px solid red" : "",
                fontSize: 16,
              }),
            }}
            options={states.map((state) => {
              return { value: state.name, label: state.name }
            })}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            filterOption={customFilterOption}
          />
        )}
        {info === "city" && (
          <Select
            isSearchable
            placeholder=''
            styles={{
              container: (base) => ({
                ...base,
                width: "100%",
                borderRadius: 4,
                border:
                  form.getFieldError(key).length > 0 ? "1px solid red" : "",
                fontSize: 16,
              }),
            }}
            options={cities.map((city) => {
              return { value: city.name, label: city.name }
            })}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            filterOption={customFilterOption}
          />
        )}
      </Form.Item>
    )
  }

  const showToast = (
    type: "loading" | "success" | "error",
    content: React.ReactNode
  ) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2,
    })
  }

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      const values = await form.validateFields()
      try {
        const result = await postData("api/auth/become_seller", values)
        showToast("success", _t("error.becomeSeller"))
        if (form.getFieldValue("phone")) {
          dispatch(updateAuth({ phone: form.getFieldValue("phone") }))
        }
        if (form.getFieldValue("city")) {
          dispatch(updateAuth({ city: form.getFieldValue("city") }))
        }
        dispatch(updateAuth({ type: "SELLER" }))
        setAuthToken(result.token);
        navigate("/")
      } catch (error: any) {
        if (error.data.message) {
          showToast("error", _t(error.data.message))
        } else {
          showToast("error", _t("error.registerFailed"))
        }
      }
    } catch (error) {
      showToast("error", _t("error.publishError"))
    } finally {
      setIsLoading(false)
    }
  }

  const onFormChange = () => {
    setSelectedCountry(form.getFieldValue("country"))
    setSelectedState(form.getFieldValue("state"))
  }

  return (
    <>
      {contextHolder}
      <div className='flex flex-col items-start md:items-center py-16 px-5'>
        <div className='flex flex-col items-start md:items-center gap-6 w-full md:w-[720px]'>
          <div className='flex flex-col items-start md:items-center gap-y-2 mb-2'>
            <h3 className='text-xl md:text-5xl font-bold text-blue-800'>
              {_t("register.switchSeller")}
            </h3>
          </div>
          <Form
            layout={"horizontal"}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 16 }}
            form={form}
            className='w-full'
            onValuesChange={onFormChange}
            onSubmitCapture={onSubmit}
          >
            {auth &&
              !auth.phone &&
              buildFormField(
                _t("form.sellerPhone"),
                "phone",
                <Phone width={20} height={20} />,
                "phone"
              )}
            {buildFormField(
              _t("form.directPhone"),
              "sellerPhone",
              <Phone width={20} height={20} />,
              "phone"
            )}
            {buildFormField(
              _t("form.uniqueBusinessId"),
              "businessId",
              <Building2 width={20} height={20} />
            )}
            {buildFormField(
              _t("form.streetNumber"),
              "streetNumber",
              <ListOrdered width={20} height={20} />,
              "number"
            )}
            {buildFormField(
              _t("form.streetName"),
              "streetName",
              <CornerRightUp width={20} height={20} />
            )}
            {buildFormField(
              _t("form.neighborhood"),
              "neighborhood",
              <UsersRound width={20} height={20} />
            )}
            {buildFormField(
              _t("form.country"),
              "country",
              <Home width={20} height={20} />,
              "country"
            )}
            {buildFormField(
              _t("form.state"),
              "state",
              <Home width={20} height={20} />,
              "state"
            )}
            {cities.length
              ? buildFormField(
                _t("form.city"),
                "city",
                <Home width={20} height={20} />,
                "city"
              )
              : ""}
            {buildFormField(
              _t("form.postalCode"),
              "postalCode",
              <ListOrdered width={20} height={20} />
            )}
            <Form.Item wrapperCol={{ offset: isBrowser ? 10 : 0, span: 16 }}>
              <Button
                htmlType='submit'
                loading={isLoading}
                size='large'
                className='w-full'
              >
                {_t("register.button")}
              </Button>
            </Form.Item>
            <p className='text-center text-base font-semibold text-blue-800'>
              {_t("register.wondering")}?
              <Link to={"/auth/login"} className='ml-1 text-blue-300'>
                {_t("header.signIn")}
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </>
  )
}

export default SwitchToSellerPage
