import { useEffect, useState } from "react";
import { Input, Pagination, PaginationProps, Radio, RadioChangeEvent, Table, message } from "antd";
import { ArrowDown, ArrowUp } from "lucide-react";
import SpaceHeader from "../../components/space/space-header";
import SpaceEditUser from "../../components/space/EditUser";
import { _t } from "../../utils/translate";
import { UserDataType } from "@/utils/types";
import { fetchData, parsePhoneNumber } from "../../utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";

const columns = [
  { title: _t("form.fullName"), dataIndex: 'fullName', key: 'name' },
  { title: _t("form.email"), dataIndex: 'email', key: 'name' },
  { title: _t("details.type"), dataIndex: 'type', key: 'name' },
  { title: _t("form.phone"), dataIndex: 'phone', key: 'name', render: (text: string) => parsePhoneNumber(text) },
  { title: _t("form.numPosts"), dataIndex: 'numPosts', key: 'name' },
  { title: _t("form.registerDate"), dataIndex: 'registerDate', key: 'name', render: (text: string) => new Date(text).toLocaleDateString('en-GB') },
];

const SpaceUsers = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [users, setUsers] = useState<UserDataType[]>([]);
  const [searchText, setSearchText] = useState<string>(searchParams.get('searchText') || "");
  const [sortByDate, setSortByDate] = useState<boolean>(searchParams.get('sortByDate') === "true" || false);
  const [sortByUserType, setSortByUserType] = useState<string>(searchParams.get('sortByUserType') || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initPage = searchParams.get('page');
  const [page, setPage] = useState<number>(initPage ? parseInt(initPage, 10) : 1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [editUser, setEditUser] = useState<Record<string, any>>({});

  const [messageApi, contextHolder] = message.useMessage();

  const pageSize = 10;

  useEffect(() => {
    setSearchParams({ 
      page: page.toString(), 
      searchText, 
      sortByDate: sortByDate.toString(), 
      sortByUserType 
    });
  }, [page, searchText, sortByDate, sortByUserType, setSearchParams]);

  useEffect(() => {
    loadUsers(page, searchText, sortByDate, sortByUserType);
  }, []);

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const loadUsers = async (page: number, searchText: string, sortByDate: boolean, sortByUserType: string) => {
    setIsLoading(true);
    try {
      const apiResult = await fetchData('api/auth/get_users', {
        searchText, sortByDate, sortByUserType,
        from: (page - 1) * pageSize,
        to: page * pageSize - 1
      });
      console.log(apiResult);
      setUsers(apiResult.users);
      setTotalCount(apiResult.total);
    } catch (error: any) {
      if (error && error.data && error.data.message) {
        showToast('error', _t(error.data.message));
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const onSearchChange = (e: any) => {
    setSearchText(e.target.value);
  }

  const onSearch = (value: any) => {
    setSearchText(value);
    loadUsers(page, searchText, sortByDate, sortByUserType);
  }

  const onSortDate = (e: RadioChangeEvent) => {
    setSortByDate(e.target.value);
    loadUsers(page, searchText, e.target.value, sortByUserType);
  }

  const onSortUserType = (e: RadioChangeEvent) => {
    setSortByUserType(e.target.value);
    loadUsers(page, searchText, sortByDate, e.target.value);
  }

  const onPaginationChange: PaginationProps['onChange'] = (page: number) => {
    setPage(page);

    loadUsers(page, searchText, sortByDate, sortByUserType);
  };

  const onRowClick = (record: Record<string, any>) => {
    navigate(`/space/users/edit?id=${record['id']}`);
    // setEditUser(record);
  }

  const onRefresh = () => {
    setEditUser({});
    loadUsers(page, searchText, sortByDate, sortByUserType);
  }

  return (
    <div className='p-2 md:p-10'>
      {contextHolder}
      {Object.keys(editUser).length !== 0
        ? <SpaceEditUser user={editUser} showToast={showToast} onRefresh={onRefresh} />
        : <>
          <SpaceHeader />
          <div className="py-3 flex gap-x-5 flex-col md:flex-row">
            <div className="flex flex-col gap-y-5">
              <Input.Search size="large" style={{ width: 300 }} value={searchText} onChange={onSearchChange} onSearch={onSearch} />
              <div className="flex flex-row md:flex-col gap-4">
                <div className="flex flex-col gap-y-3">
                  <p className="font-semibold text-lg text-blue-700">{_t("Sort by Date")}:</p>
                  <Radio.Group value={sortByDate} onChange={onSortDate} className="flex flex-col gap-y-3">
                    <Radio value={true}><div className="flex items-center text-base font-semibold text-blue-400">{_t("form.old2New")} <ArrowUp /></div></Radio>
                    <Radio value={false}><div className="flex items-center text-base font-semibold text-blue-400">{_t("form.new2Old")} <ArrowDown /></div></Radio>
                  </Radio.Group>
                </div>
                <div className="flex flex-col gap-y-3">
                  <p className="font-semibold text-lg text-blue-700">{_t("Sort by User Type")}:</p>
                  <Radio.Group value={sortByUserType} onChange={onSortUserType} className="flex flex-col gap-y-3">
                    <Radio value={'SELLER'}><div className="flex items-center text-base font-semibold text-blue-400">{_t("details.seller")} <ArrowUp /></div></Radio>
                    <Radio value={'VISITOR'}><div className="flex items-center text-base font-semibold text-blue-400">{_t("details.visitor")} <ArrowDown /></div></Radio>
                    <Radio value={''}><div className="flex items-center text-base font-semibold text-blue-400">{_t("details.both")} <ArrowDown /></div></Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div className="flex-1 text-base mt-2">
              <Table
                className="custom-table w-full overflow-auto cursor-pointer" bordered size="large"
                columns={columns} dataSource={users} loading={isLoading} pagination={false}
                onRow={(record, index) => {
                  return {
                    onClick: () => onRowClick(record)
                  }
                }}
              />
              <div className="flex justify-center mt-3">
                <Pagination current={page} total={totalCount} pageSize={pageSize} onChange={onPaginationChange} />
              </div>
            </div>
          </div>
        </>}
    </div>
  )
}

export default SpaceUsers;