import { Button, Checkbox, Form, FormInstance } from "antd"
import { _t } from "../../utils/translate"
import { treatments } from "../../utils/constants"
import { isDesktop, isMobile } from "react-device-detect"
import { messages } from "../../utils/translate"
import { RootState } from "@/redux/store"
import { useSelector } from "react-redux"
import { useCallback, useMemo } from "react"

const StepSevenComponent = ({
  form,
  showToast,
  update,
}: {
  form: FormInstance
  showToast: Function
  update: Function
}) => {
  const { lang } = useSelector((state: RootState) => state.auth)

  const resortedTreatments = useMemo(() => {
    if (isDesktop) {
      const tmpAnti = treatments["ANTI"],
        tmpEco = treatments["Eco"]
      const tmpC3 = treatments["C3"],
        tmpC4 = treatments["C4"],
        tmpC5 = treatments["C5"]
      tmpAnti.sort((a: string, b: string) =>
        messages[lang][a].localeCompare(messages[lang][b])
      )
      tmpEco.sort((a: string, b: string) =>
        messages[lang][a].localeCompare(messages[lang][b])
      )
      tmpC3.sort((a: string, b: string) =>
        messages[lang][a].localeCompare(messages[lang][b])
      )
      tmpC4.sort((a: string, b: string) =>
        messages[lang][a].localeCompare(messages[lang][b])
      )
      tmpC5.sort((a: string, b: string) =>
        messages[lang][a].localeCompare(messages[lang][b])
      )

      return {
        "ANTI": tmpAnti,
        "Eco": tmpEco,
        "C3": tmpC3,
        "C4": tmpC4,
        "C5": tmpC5,
      }
    } else {
      let tmp: string[] = treatments["ANTI"].concat(
        treatments["Eco"],
        treatments["C3"],
        treatments["C4"],
        treatments["C5"]
      )
      tmp.sort((a: string, b: string) =>
        messages[lang][a].localeCompare(messages[lang][b])
      )

      return tmp
    }
  }, [treatments, lang, isDesktop])

  const onBack = async () => {
    update("prev-step")
  }

  const onNext = async () => {
    try {
      await form.validateFields()
      update("next-step")
    } catch (error) {
      console.error(error)
      showToast("error", _t("error.publishError"))
    }
  }

  const buildChecksInColumn = () => {
    const tmpAnti = treatments["ANTI"],
      tmpEco = treatments["Eco"]
    const tmpC3 = treatments["C3"],
      tmpC4 = treatments["C4"],
      tmpC5 = treatments["C5"]
    tmpAnti.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpEco.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpC3.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpC4.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpC5.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )

    return (
      <div className="flex flex-col items-center">
        <div className='flex gap-x-2'>
          <div className='flex flex-col'>
            <p className='text-xl text-blue-700 font-bold mb-3'>
              {_t("form.anti")}
            </p>
            {tmpAnti.map((value, index) => (
              <Checkbox value={value} key={'Anti' + index}>
                <div className='mb-1 text-base'>{_t(value)}</div>
              </Checkbox>
            ))}
          </div>
          <div className='flex flex-col'>
            <p className='text-xl text-blue-700 font-bold mb-3'>
              {_t("form.eco")}
            </p>
            {tmpEco.map((value, index) => (
              <Checkbox value={value} key={'Eco' + index}>
                <div className='mb-1 text-base'>{_t(value)}</div>
              </Checkbox>
            ))}
          </div>
          <div className='flex flex-col'>
            <p className='text-xl text-blue-700 font-bold mb-3'>
              {_t("form.c3")}
            </p>
            {tmpC3.map((value, index) => (
              <Checkbox value={value} key={'C3' + index}>
                <div className='mb-1 text-base'>{_t(value)}</div>
              </Checkbox>
            ))}
          </div>
          <div className='flex flex-col'>
            <p className='text-xl text-blue-700 font-bold mb-3'>
              {_t("form.c4")}
            </p>
            {tmpC4.map((value, index) => (
              <Checkbox value={value} key={'C4' + index}>
                <div className='mb-1 text-base'>{_t(value)}</div>
              </Checkbox>
            ))}
          </div>
          <div className='flex flex-col'>
            <p className='text-xl text-blue-700 font-bold mb-3'>
              {_t("form.c5")}
            </p>
            {tmpC5.map((value, index) => (
              <Checkbox value={value} key={'C5' + index}>
                <div className='mb-1 text-base'>{_t(value)}</div>
              </Checkbox>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <p className='text-xl text-blue-700 font-bold mb-3'>
              {_t("form.other")}
            </p>
          <Checkbox value={'Undetermined treatment'}>
            <div className='mb-1 text-base'>{_t('Undetermined treatment')}</div>
          </Checkbox>
        </div>
      </div>
    )
  }

  const buildChecksInRow = useCallback(() => {
    const tmpAnti = treatments["ANTI"],
      tmpEco = treatments["Eco"]
    const tmpC3 = treatments["C3"],
      tmpC4 = treatments["C4"],
      tmpC5 = treatments["C5"]
    tmpAnti.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpEco.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpC3.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpC4.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )
    tmpC5.sort((a: string, b: string) =>
      messages[lang][a].localeCompare(messages[lang][b])
    )

    return (
      <div className='flex flex-col gap-y-2'>
        <div className='flex flex-col'>
          <p className='text-xl text-blue-700 font-bold mb-3'>
            {_t("form.anti")}
          </p>
          {tmpAnti.map((value, index) => (
            <Checkbox value={value} key={'Anti'+index}>
              <div className='mb-1 text-base'>{_t(value)}</div>
            </Checkbox>
          ))}
        </div>
        <div className='flex flex-col'>
          <p className='text-xl text-blue-700 font-bold mb-3'>
            {_t("form.eco")}
          </p>
          {tmpEco.map((value, index) => (
            <Checkbox value={value} key={'Eco'+index}>
              <div className='mb-1 text-base'>{_t(value)}</div>
            </Checkbox>
          ))}
        </div>
        <div className='flex flex-col'>
          <p className='text-xl text-blue-700 font-bold mb-3'>
            {_t("form.c3")}
          </p>
          {tmpC3.map((value, index) => (
            <Checkbox value={value} key={'C3'+index}>
              <div className='mb-1 text-base'>{_t(value)}</div>
            </Checkbox>
          ))}
        </div>
        <div className='flex flex-col'>
          <p className='text-xl text-blue-700 font-bold mb-3'>
            {_t("form.c4")}
          </p>
          {tmpC4.map((value, index) => (
            <Checkbox value={value} key={'C4'+index}>
              <div className='mb-1 text-base'>{_t(value)}</div>
            </Checkbox>
          ))}
        </div>
        <div className='flex flex-col'>
          <p className='text-xl text-blue-700 font-bold mb-3'>
            {_t("form.c5")}
          </p>
          {tmpC5.map((value, index) => (
            <Checkbox value={value} key={'C5'+index}>
              <div className='mb-1 text-base'>{_t(value)}</div>
            </Checkbox>
          ))}
        </div>
        <div className="flex flex-col">
          <p className='text-xl text-blue-700 font-bold mb-3'>
              {_t("form.other")}
            </p>
          <Checkbox value={'Undetermined treatment'}>
            <div className='mb-1 text-base'>{_t('Undetermined treatment')}</div>
          </Checkbox>
        </div>
      </div>
    )
  }, [treatments, lang])

  return (
    <>
      <Form.Item
        label={_t("form.treatments")}
        name={"treatments"}
        key={"treatments"}
        initialValue={[]}
        rules={[{ required: true, message: "" }]}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Checkbox.Group className='w-full justify-center'>
          {isDesktop ? buildChecksInColumn() : buildChecksInRow()}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <div className='flex gap-x-2'>
          <Button size='large' onClick={onBack}>
            {_t("form.back")}
          </Button>
          <Button size='large' onClick={onNext}>
            {_t("form.next")}
          </Button>
        </div>
      </Form.Item>
    </>
  )
}

export default StepSevenComponent
