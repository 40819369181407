import { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Lock } from "lucide-react";
import { isBrowser } from "react-device-detect";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { postData } from "../../utils/utils";
import { _t } from "../../utils/translate";

const SettingPasswordPage = () => {
  const [form] = Form.useForm();

  const { auth } = useSelector((state: RootState) => state.auth);

  const [messageApi, contextHolder] = message.useMessage();
  
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onSave = async () => {
    const values = await form.validateFields();
    if (values['new-password'] !== values['confirm-password'] || values['new-password'].length < 8) {
      showToast('error', _t("error.passwordValidFailedError"));
      form.setFields([
        { name: 'new-password', errors: [''] },
        { name: 'confirm-password', errors: [''] },
      ]);
    } else {
      setIsSaving(true);
      try {
        const apiResult = await postData('api/auth/change_password', { oldPass: values['cur-password'] ? values['cur-password'] : "", newPass: values['new-password'] });

        if (apiResult) {
          showToast('success', _t("error.updatedInfo"))
        }
      } catch (error: any) {
        if (error.data.message) {
          showToast('error', _t(error.data.message));
        } else {
          showToast('error', _t("error.updatedInfoFailed"))
        }
      } finally {
        setIsSaving(false);
      }

      form.resetFields();
    }
  }

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
      >
        {auth && !auth.socialSign && <Form.Item
          label={_t("form.curPassword")} name="cur-password" key='cur-pass'
          initialValue={""}
          rules={[{ required: true, message: '' }]}
        >
          <Input.Password style={{ width: 300 }} size="large" prefix={<Lock />} />
        </Form.Item>}
        <Form.Item
          label={_t("form.new-Password")} name="new-password" key='new-pass'
          initialValue={""}
          rules={[{ required: true, message: '' }]}
        >
          <Input.Password style={{ width: 300 }} size="large" prefix={<Lock />} />
        </Form.Item>
        <Form.Item
          label={_t("form.confirmPassword")} name="confirm-password" key='confirm-pass'
          initialValue={""}
          rules={[{ required: true, message: '' }]}
        >
          <Input.Password style={{ width: 300 }} size="large" prefix={<Lock />} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: isBrowser ? 8 : 0 }}>
          <Button size="large" onClick={onSave} loading={isSaving}>{_t("form.save")}</Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default SettingPasswordPage;