import { useEffect, useState } from "react";
import { Form, FormInstance, Radio, Space } from "antd";
import { _t } from "../../utils/translate";

const OtherIntendedUseComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: Boolean }) => {
  const [prevState, setPrevState] = useState<string>("");
  useEffect(() => {
    updateFilter({
      "intendedUse": form.getFieldValue('intendedUse'),
    });
    setPrevState(form.getFieldValue('intendedUse'));
  }, []);

  useEffect(() => {
    setPrevState("");
  }, [reset])

  const handleClick = (value: any) => () => {
    setPrevState(prevState !== "" && prevState === value ? "" : value);
    updateFilter({ 'intendedUse': prevState !== "" && prevState === value ? undefined : value });
  }

  return (
    <>
      <Form.Item name="intendedUse" style={{ marginBottom: 4 }}>
        <Radio.Group size="large">
          <Space direction="vertical">
            <Radio value={'form.clothing'} key={'intendedUse' + 'form.clothing'} className="text-base" onClick={handleClick('form.clothing')}>{_t('form.clothing')}</Radio>
            <Radio value={'form.interFurnish'} key={'intendedUse' + 'form.interFurnish'} className="text-base" onClick={handleClick('form.interFurnish')}>{_t('form.interFurnish')}</Radio>
            <Radio value={'form.outFurnish'} key={'intendedUse' + 'form.outFurnish'} className="text-base" onClick={handleClick('form.outFurnish')}>{_t('form.outFurnish')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default OtherIntendedUseComponent;
