import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button, Form, Input, Modal, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, GoogleOutlined } from "@ant-design/icons";
import { Facebook, Lock, Mail } from "lucide-react";

import { jwtDecode } from "jwt-decode";
import type { User } from "@/redux/types";
import { setAuth } from "../../redux/authSlice";
import { postData, setAuthToken } from "../../utils/utils";
import { _t } from "../../utils/translate";

import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
  objectType,
} from 'reactjs-social-login';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
const REDIRECT_URI = process.env.REDIRECT_URI;

interface DecodeToken {
  data: string
  exp: number;
  iat: number;
}

const AuthLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGoogleSigning, setIsGoogleSigning] = useState<boolean>(false);
  // const [isFacebookSigning, setIsFacebookSigning] = useState<boolean>(false);
  const [isForgotPassword, showForgotPassword] = useState<boolean>(false);
  const [forgotEmail, setForgotEmail] = useState<string>("");

  const [messageApi, contextHolder] = message.useMessage();

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const result = await postData('api/auth/login', form.getFieldsValue());
      setIsLoading(false);

      const { data } = jwtDecode<DecodeToken>(result.token);
      const userInfo: User = JSON.parse(data);
      dispatch(setAuth({
        email: userInfo.email,
        fullName: userInfo.fullName,
        phone: userInfo.phone,
        type: userInfo.type,
        city: userInfo.city,
        socialSign: userInfo.socialSign,
      }));
      setAuthToken(result.token);
      navigate('/');
    } catch (error: any) {
      form.resetFields();
      messageApi.error({
        key: 'notify-error',
        type: 'error',
        content: _t("error.loginFailed"),
        duration: 2,
      });
      setIsLoading(false);
    }
  }

  const onSocialLogin = async (provider: string, data: any) => {
    const email = data.email;

    try {
      const result = await postData('api/auth/social-login', { email });

      const { data } = jwtDecode<DecodeToken>(result.token);
      const userInfo: User = JSON.parse(data);
      dispatch(setAuth({
        email: userInfo.email,
        fullName: userInfo.fullName,
        phone: userInfo.phone,
        type: userInfo.type,
        city: userInfo.city,
        socialSign: userInfo.socialSign,
      }));
      setAuthToken(result.token);
      navigate('/');
    } catch (error: any) {
      form.resetFields();
      if (error.data && error.data.message) {
        messageApi.error({
          key: 'notify-error',
          type: 'error',
          content: _t(error.data.message),
          duration: 2,
        });
      } else {
        messageApi.error({
          key: 'notify-error',
          type: 'error',
          content: _t("error.loginFailed"),
          duration: 2,
        });
      }
    } finally {
      if (provider === 'google') setIsGoogleSigning(false);
      // if (provider === 'facebook') setIsFacebookSigning(false);
    }
  }

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onSendForgotLink = async () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(forgotEmail)) {
      try {
        await postData('api/auth/forgot-password', { email: forgotEmail });
        showToast('success', _t("Please check your inbox"));
      } catch (error: any) {
        if (error.data && error.data.message) {
          showToast('error', _t(error.data.message));
        } else {
          showToast('error', _t("An error occured with connecting to the server"));
        }
      } finally {
        showForgotPassword(false);
      }
    }
  }

  return (
    <>
      {contextHolder}
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="w-full md:w-[500px] px-5 flex flex-col items-center gap-6">
          <div className="flex flex-col justify-between text-center gap-y-4">
            <h3 className="text-2xl md:text-5xl font-bold text-blue-800">{_t("login.title")}</h3>
            <p className="text-xl font-medium leading-[30px] text-center text-blue-600">{_t("login.desc")}</p>
          </div>
          <div className="flex items-center gap-x-[22px] mt-5">
            <LoginSocialGoogle
              client_id={GOOGLE_CLIENT_ID || ""}
              onLoginStart={() => setIsGoogleSigning(true)}
              redirect_uri={REDIRECT_URI}
              // typeResponse="idToken"
              scope="openid profile email https://www.googleapis.com/auth/user.phonenumbers.read"
              ux_mode="popup"
              onResolve={({ provider, data }: IResolveParams) => {
                console.log(provider, data);
                onSocialLogin(provider, data!)
              }}
              onReject={err => {
                setIsGoogleSigning(false);
                console.log(err);
              }}
            >
              <Button
                className="w-[121px] h-[48px] border border-[#1E3A8A] text-base font-semibold leading-[24px] text-center custom-button text-base font-semibold leading-6 text-center"
                size="large" icon={<GoogleOutlined />} loading={isGoogleSigning}
              >Google</Button>
            </LoginSocialGoogle>
            {/*<LoginSocialFacebook
              appId={FACEBOOK_CLIENT_ID || ''}
              fieldsProfile={
                'id,name,picture,email'
              }
              redirect_uri={REDIRECT_URI}
              onResolve={({ provider, data }: IResolveParams) => {
                onSocialLogin(provider, data);
              }}
              onReject={err => {
                setIsFacebookSigning(false);
                console.log(err);
              }}
            >
              <Button
                className="w-[138px] h-[48px] border border-[#1E3A8A] text-base font-semibold leading-[24px] text-center custom-button flex items-center"
                type="primary" size="large" icon={<Facebook className="w-5 h-5" />} loading={isFacebookSigning}
              >Facebook</Button>
            </LoginSocialFacebook>*/}
          </div>
          <div className="relative w-full">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-[#1D4ED8]"></span>
            </div>
            <div className="relative flex justify-center text-base font-semibold uppercase">
              <span className="bg-white px-3 text-blue-600 text-base font-medium leading-6 text-center">{_t("login.or")}</span>
            </div>
          </div>
          <p className="font-semibold text-xl text-blue-600">{_t("login.subHeader")}</p>
          <Form
            layout={'vertical'}
            form={form}
            onSubmitCapture={onSubmit}
            className="w-full md:w-[380px]"
          >
            <Form.Item name="email" label={_t("form.email")}>
              <Input type="email" prefix={<Mail />} size="large" className="border-2" />
            </Form.Item>
            <Form.Item name="password" label={_t("form.password")} style={{ marginBottom: 0 }}>
              <Input.Password
                className="border-2"
                size="large" prefix={<Lock />}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item>
              <div className="flex flex-col text-base text-right">
                <Button type="link" className="my-4 text-base text-right text-[#60A5FA] font-medium leading-5" onClick={() => showForgotPassword(true)}>{_t("login.forgotPassword")}</Button>
                <Button
                  type="primary" htmlType="submit" size={'large'} loading={isLoading}
                  className="border border-[#1E3A8A] text-base font-semibold leading-[24px] text-center custom-button text-base font-semibold text-center rounded-[12px] h-[43px]"
                >{_t("login.button")}</Button>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="text-center font-semibold text-base">
                <span className="mr-1 text-blue-800">{_t("login.notRegistered")}?</span>
                <Link to={'/auth/register'} className="text-[#60A5FA] underline">{_t("login.registerForFree")}.</Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        title={<div className="text-2xl text-center text-blue-800">{_t("login.forgotPassword")}</div>}
        onCancel={() => showForgotPassword(false)}
        open={isForgotPassword} centered footer="" width={540}>
        <div className="flex flex-col gap-3 text-base">
          <span className="font-semibold text-blue-500">{_t("Please enter your email address to receive a password reset link.")}</span>
          <span className="font-semibold text-blue-800">{_t("form.email")}:</span>
          <Input type="email" size="large" value={forgotEmail} onChange={(e: any) => setForgotEmail(e.target.value)} />
          <div className="flex justify-center gap-3">
            <Button size="large" onClick={() => showForgotPassword(false)}>{_t("Cancel")}</Button>
            <Button size="large" onClick={onSendForgotLink}>{_t("Send")}</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AuthLogin;