
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input, InputNumber, message } from 'antd';
import { CircleUser, Mail, Phone, Lock, Facebook } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import { isBrowser } from 'react-device-detect';
import { postData } from '../../utils/utils';
import { _t } from '../../utils/translate';
import { GoogleOutlined } from '@ant-design/icons';

import {
  LoginSocialGoogle,
  // LoginSocialFacebook,
  IResolveParams,
  objectType,
} from 'reactjs-social-login';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
const REDIRECT_URI = process.env.REDIRECT_URI;

const CreateVisitorPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(1);
  const [token, setToken] = useState<string>("");
  const [values, setValues] = useState<Record<string, any>>({});

  const [isGoogleSigning, setIsGoogleSigning] = useState<boolean>(false);
  // const [isFacebookSigning, setIsFacebookSigning] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const buildFormField = (label: React.ReactNode, key: string, prefix: any, info?: string) => {
    return (
      <Form.Item name={key} label={label} className="w-full text-base" rules={[{ required: true, message: '' }]}>
        {info === undefined && <Input type={key === 'email' ? 'email' : 'text'} size="large" prefix={prefix} />}
        {info === "password" && <Input.Password size="large" prefix={prefix} />}
        {info === "number" && <InputNumber className='w-full' size="large" prefix={prefix} />}
        {info === "phone" &&
          <PhoneInput country={'ma'} inputStyle={{
            width: '100%', height: 40, borderRadius: 8,
            outline: form.getFieldError(key).length > 0 ? '1px solid red' : '',
            border: form.getFieldError(key).length > 0 ? 'none' : '1px solid #CACACA',
            fontSize: 16
          }} buttonStyle={{ borderRadius: 8 }} />}
      </Form.Item>
    )
  }

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onNext = async () => {
    setToken("");
    try {
      const values = await form.validateFields();
      if (values['password'] !== values['confirmPassword'] || values['password'].length < 8) {
        showToast('error', _t("error.passwordValidFailedError"));
        form.setFieldValue('password', '');
        form.setFieldValue('confirmPassword', '');
        return;
      }
      setValues(values);
      onResend();
      setTab(2);
    } catch (error) {
      showToast('error', _t("error.publishError"));
    }
  }

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await postData('api/auth/register', values);

      showToast('success', _t("error.registered"));
      setTimeout(() => navigate('/auth/login'), 2000);
    } catch (error: any) {
      if (error.data.message) {
        showToast('error', _t(error.data.message));
      } else {
        showToast('error', _t("error.registerFailed"));
      }
      form.setFieldValue("emailVerification", "");
      setTab(1);
    } finally {
      setIsLoading(false);
    }
  }

  const onResend = async () => {
    try {
      const email = form.getFieldValue('email');
      const fullName = form.getFieldValue('fullName');
      const apiResult = await postData('api/auth/send-verification', { email, fullName });
      setToken(apiResult.token);
    } catch (error: any) {
      console.error(error.data);
      if (error.data && error.data.message) {
        showToast('error', _t(error.data.message));
      } else {
        showToast('error', _t("An error occured with connecting to the server"));
      }
    }
  }

  const onValidate = async () => {
    try {
      const code = form.getFieldValue('emailVerification');
      await postData('api/auth/verify-code', { token, code });
      // setIsVerifiedEmail(true);
      // form.submit();
      await onSubmit();
    } catch (error: any) {
      console.error(error.data);
      if (error.data.message) {
        showToast('error', _t(error.data.message));
      }
    }
  }

  const onSocialRegister = async (provider: string, data: any, phone: string) => {
    setIsGoogleSigning(true);

    const email = data.email;
    const fullName = data.name;
    try {
      await postData('api/auth/social-register', { email, fullName, phone });

      showToast('success', _t("error.registered"));
      setTimeout(() => navigate('/auth/login'), 2000);
    } catch (error: any) {
      if (error.data.message) {
        showToast('error', _t(error.data.message));
      } else {
        showToast('error', _t("error.registerFailed"));
      }
    } finally {
      setIsGoogleSigning(false);
    }
  }

  const buildDetails = () => (
    <>
      {buildFormField(_t("form.fullName"), "fullName", <CircleUser width={20} height={20} />)}
      {buildFormField(_t("form.email"), "email", <Mail width={20} height={20} />)}
      {buildFormField(_t("form.phone"), "phone", <Phone width={20} height={20} />, "phone")}
      {buildFormField(_t("form.password"), "password", <Lock width={20} height={20} />, "password")}
      {buildFormField(_t("form.confirmPassword"), "confirmPassword", <Lock width={20} height={20} />, "password")}
      <Form.Item>
        <Button onClick={onNext} className="w-full" size="large">{_t("form.next")}</Button>
      </Form.Item>
      <div className="text-base text-center">
        <span className="mr-1 text-blue-800 font-medium">{_t("register.wondering")}?</span>
        <Link to={'/auth/login'} className="underline text-blue-300 font-medium">{_t("header.signIn")}</Link>
      </div>
    </>
  )

  const buildVerification = () => (
    <>
      {buildFormField(_t("form.emailVerificiation"), "emailVerification", <Mail width={20} height={20} />, "number")}
      <Form.Item>
        <div className="w-full flex gap-x-5">
          <Button block type="default" size="large" onClick={onResend}>{_t("form.resend")}</Button>
          <Button block type="primary" size="large" onClick={onValidate}>{_t("register.button")}</Button>
        </div>
      </Form.Item>
      {/* <Form.Item>
        <Button
          size='large' htmlType='submit' loading={isLoading} className='w-full'
          disabled={!isVerifiedEmail}
        >{_t("register.button")}</Button>
      </Form.Item> */}
    </>
  )

  const onResolve = async (provider: string, data: objectType) => {
    try {
      const userInfoResponse = await fetch('https://people.googleapis.com/v1/people/me?personFields=phoneNumbers', {
        headers: {
          Authorization: `Bearer ${data.access_token}`,
        },
      });

      if (!userInfoResponse.ok) {
        throw new Error(`Error fetching user info: ${userInfoResponse.statusText}`);
      }

      const userInfo = await userInfoResponse.json();
      console.log('User Info:', userInfo);

      const phoneNumber = userInfo.phoneNumbers ? userInfo.phoneNumbers[0].canonicalForm : null;
      console.log('Phone Number:', phoneNumber);

      onSocialRegister(provider, data, phoneNumber);

      // Handle phone number as needed
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsGoogleSigning(false);
    }
  }

  return (
    <>
      {contextHolder}
      <div className="flex flex-col items-start md:items-center py-16 px-5 md:px-0">
        <div className="flex flex-col items-start md:items-center gap-6 w-full md:w-[720px]">
          <div className="flex flex-col items-start md:items-center gap-y-2">
            <h3 className="text-xl md:text-5xl font-bold text-blue-800">{_t("register.title")}</h3>
            <p className="text-[20px] font-semibold text-blue-600">{_t("register.desc")}</p>
          </div>
          <div className="flex justify-center gap-x-2 w-full">
            <LoginSocialGoogle
              client_id={GOOGLE_CLIENT_ID || ""}
              onLoginStart={() => setIsGoogleSigning(true)}
              redirect_uri={REDIRECT_URI}
              // typeResponse="idToken"
              scope="openid profile email https://www.googleapis.com/auth/user.phonenumbers.read"
              ux_mode="popup"
              onResolve={({ provider, data }: IResolveParams) => {
                onResolve(provider, data!);
              }}
              onReject={err => {
                setIsGoogleSigning(false);
                console.log(err);
              }}
            >
              <Button
                className="w-[121px] h-[48px] flex items-center"
                size="large" icon={<GoogleOutlined />} loading={isGoogleSigning}
              >Google</Button>
            </LoginSocialGoogle>
            {/* <LoginSocialFacebook
              appId={FACEBOOK_CLIENT_ID || ''}
              fieldsProfile={
                'id,name,picture,email'
              }
              redirect_uri={REDIRECT_URI}
              onResolve={({ provider, data }: IResolveParams) => {
                onSocialRegister(provider, data);
              }}
              onReject={err => {
                setIsFacebookSigning(false);
                console.log(err);
              }}
            >
              <Button
                className="w-[138px] h-[48px] flex items-center"
                size="large" icon={<Facebook className="w-5 h-5" />} loading={isFacebookSigning}
              >Facebook</Button>
            </LoginSocialFacebook> */}
          </div>
          <div className="relative w-full md:w-[500px]">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-blue-500"></span>
            </div>
            <div className="relative flex justify-center text-sm font-semibold uppercase">
              <span className="bg-white px-3 text-blue-600 text-lg">{_t("login.or")}</span>
            </div>
          </div>
          <p className="font-semibold text-2xl text-blue-800">{_t("register.step")} {tab} {_t("register.of")} 2:</p>
          {tab === 1 &&
            <>
              <Form
                layout={'vertical'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={form}
                className='w-full md:w-[380px]'
                onSubmitCapture={onSubmit}
              >{buildDetails()}
              </Form>
            </>}
          {tab === 2 && <Form
            layout={'vertical'}
            // labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }}
            form={form}
            className='w-full md:w-[380px]'
            onSubmitCapture={onSubmit}
          >{buildVerification()}
          </Form>}
        </div>
      </div >
    </>
  )
}

export default CreateVisitorPage;