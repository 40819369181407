import { FormattedMessage } from 'react-intl';

import English from '../translations/en.json';
import French from '../translations/fr.json';

export interface LocaleMessages {
  en: { [key: string]: string };
  fr: { [key: string]: string };
};

export const messages: LocaleMessages = {
  'en': English,
  'fr': French
};

export const _t = (id: string) => <FormattedMessage id={id} />;