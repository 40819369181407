import { Card, Skeleton } from "antd";
import { _t } from "../../utils/translate";

const DetailsLoadingComponent = () => (
  <div className="w-full md:w-[800px] pt-5">
    <h1 className="text-2xl text-center font-bold text-blue-800 w-full mb-3">{_t("research.title")}</h1>
    <Card>
      <div className="flex gap-x-3 mb-3">
        <div className="w-[200px] h-[150px]">
          <Skeleton.Image className="custom-skeleton-image rounded-md" active={true} />
        </div>
        <Skeleton active />
      </div>
      <Skeleton className="my-5" active />
      <Skeleton active />
    </Card>
  </div>
);


export default DetailsLoadingComponent;