import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { Button } from "antd";
import { Database, Package, Settings } from 'lucide-react';
import { _t } from '../../utils/translate';
import { BrowserView, MobileView } from 'react-device-detect';

const SpaceHeader = () => {
  const { auth } = useSelector((state: RootState) => state.auth);

  if (!auth) return <></>;

  return (
    <>
      <BrowserView className='flex md:gap-x-2'>
        {auth.type === 'SELLER' && <>
          <Link to="/space/fabrics">
            <Button icon={<div><Package /></div>} size='large' className='inline-flex gap-x-2'>{_t("menu.myFabrics")}</Button>
          </Link>
          <Link to="/space/history">
            <Button icon={<div><Database /></div>} size='large' className='inline-flex gap-x-2'>{_t("menu.myHistory")}</Button>
          </Link>
        </>}
        {auth.type === 'ADMIN' && <>
          <Link to="/space/fabrics">
            <Button icon={<div><Package /></div>} size='large' className='inline-flex gap-x-2'>{_t("menu.fabrics")}</Button>
          </Link>
          <Link to="/space/users">
            <Button icon={<div><Database /></div>} size='large' className='inline-flex gap-x-2'>{_t("menu.users")}</Button>
          </Link>
        </>
        }
        <Link to="/auth/settings">
          <Button icon={<div><Settings /></div>} size='large' className='inline-flex gap-x-2'>{_t("menu.settings")}</Button>
        </Link>
      </BrowserView>
      <MobileView></MobileView>
    </>
  )
}

export default SpaceHeader;