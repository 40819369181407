import { useMemo, useState } from "react"

import { Button, Form, Input, InputNumber, message } from "antd"
import Select from "react-select"
import {
  Building,
  CircleUser,
  CornerRightUp,
  Home,
  ListOrdered,
  Lock,
  Mail,
  Phone,
  UsersRound,
  Building2,
} from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import { customFilterOption, postData } from "../../utils/utils"
import { _t } from "../../utils/translate"

import PhoneInput from "react-phone-input-2"
import { City, Country, State } from "country-state-city"

const CreateSellerPage = () => {
  const navigate = useNavigate()
  const [tab, setTab] = useState<number>(1)
  const [fields, setFields] = useState<Record<string, any>>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [token, setToken] = useState<string>("")

  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const [selectedCountry, setSelectedCountry] = useState<string>("")
  const [selectedState, setSelectedState] = useState<string>("")

  const countries = Country.getAllCountries()

  const states = useMemo(() => {
    if (!form.getFieldValue("country")) return []

    const country = countries.find(
      (c) => c.name === form.getFieldValue("country").value
    )
    form.setFieldValue("state", "")
    form.setFieldValue("city", "")
    return country ? State.getStatesOfCountry(country.isoCode) : []
  }, [selectedCountry])

  const cities = useMemo(() => {
    if (!form.getFieldValue("country")) return []

    const country = countries.find(
      (c) => c.name === form.getFieldValue("country").value
    )
    const state = states.find(
      (c) => c.name === form.getFieldValue("state").value
    )
    form.setFieldValue("city", "")
    return country && state
      ? City.getCitiesOfState(country.isoCode, state.isoCode)
      : []
  }, [selectedState])

  const buildFormField = (
    label: React.ReactNode,
    key: string,
    prefix: any,
    info?: string
  ) => {
    return (
      <Form.Item
        key={key}
        name={key}
        label={label}
        className='w-full text-base'
        rules={[{ required: info !== "city", message: "" }]}
      >
        {info === undefined && (
          <Input
            type={key === "email" ? "email" : "text"}
            size='large'
            prefix={prefix}
          />
        )}
        {info === "password" && <Input.Password size='large' prefix={prefix} />}
        {info === "number" && (
          <InputNumber className='w-full' size='large' prefix={prefix} />
        )}
        {info === "phone" && (
          <PhoneInput
            country={"ma"}
            inputStyle={{
              width: "100%",
              height: 40,
              borderRadius: 8,
              outline:
                form.getFieldError(key).length > 0 ? "1px solid red" : "",
              border:
                form.getFieldError(key).length > 0
                  ? "none"
                  : "1px solid #CACACA",
              fontSize: 16,
            }}
            buttonStyle={{ borderRadius: 8 }}
          />
        )}
        {info === "country" && (
          <Select
            isSearchable
            placeholder=''
            styles={{
              container: (base) => ({
                ...base,
                width: "100%",
                borderRadius: 4,
                border:
                  form.getFieldError(key).length > 0 ? "1px solid red" : "",
                fontSize: 16,
              }),
            }}
            options={countries.map((country) => {
              return { value: country.name, label: country.name }
            })}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            filterOption={customFilterOption}
          />
        )}
        {info === "state" && (
          <Select
            isSearchable
            placeholder=''
            styles={{
              container: (base) => ({
                ...base,
                width: "100%",
                borderRadius: 4,
                border:
                  form.getFieldError(key).length > 0 ? "1px solid red" : "",
                fontSize: 16,
              }),
            }}
            options={states.map((state) => {
              return { value: state.name, label: state.name }
            })}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            filterOption={customFilterOption}
          />
        )}
        {info === "city" && (
          <Select
            isSearchable
            placeholder=''
            styles={{
              container: (base) => ({
                ...base,
                width: "100%",
                borderRadius: 4,
                border:
                  form.getFieldError(key).length > 0 ? "1px solid red" : "",
                fontSize: 16,
              }),
            }}
            options={cities.map((city) => {
              return { value: city.name, label: city.name }
            })}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            filterOption={customFilterOption}
          />
        )}
      </Form.Item>
    )
  }

  const isValidEmail = (email: string) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(email.toLowerCase())
  }

  const onNext = async () => {
    try {
      const values = await form.validateFields()

      if (!isValidEmail(values["email"])) {
        showToast("error", _t("register.invalidEmail"))
        form.setFieldValue("email", "")
        return
      }

      if (values["businessId"] === 0) {
        showToast("error", _t("The business id can't be zero"))
        form.setFieldValue("businessId", "")
        return
      }

      if (
        values["password"] !== values["confirmPassword"] ||
        values["password"].length < 8
      ) {
        showToast("error", _t("error.passwordValidFailedError"))
        form.setFieldValue("password", "")
        form.setFieldValue("confirmPassword", "")
        setIsLoading(false)
        return
      }

      try {
        await postData("api/auth/check-business-id", {
          businessId: values["businessId"],
        })
      } catch (error) {
        form.setFieldValue("businessId", "")
        throw error
      }

      setFields({ ...fields, ...values })

      if (tab === 1) {
        onResend()
        setTab(2)
      }
    } catch (error: any) {
      console.error(error)
      if (error.data && error.data.message) {
        showToast("error", _t(error.data.message))
      } else {
        showToast("error", _t("error.publishError"))
      }
    }
  }

  const showToast = (
    type: "loading" | "success" | "error",
    content: React.ReactNode
  ) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2,
    })
  }

  const onRegister = async () => {
    setIsLoading(true)
    try {
      const values = await form.validateFields()

      try {
        await postData("api/auth/register", {
          ...fields,
          ...values,
          "type": "SELLER",
        })

        showToast("success", _t("error.registered"))
        setTimeout(() => navigate("/auth/login"), 2000)
      } catch (error: any) {
        console.error(error.data)
        if (error.data.message) {
          showToast("error", _t(error.data.message))
        } else {
          showToast("error", _t("error.registerFailed"))
        }
        setTab(1)
      }
    } catch (error) {
      showToast("error", _t("error.publishError"))
    } finally {
      setIsLoading(false)
    }
  }

  const onFormChange = () => {
    setSelectedCountry(form.getFieldValue("country"))
    setSelectedState(form.getFieldValue("state"))
  }

  const onResend = async () => {
    try {
      const email = form.getFieldValue("email")
      const fullName = form.getFieldValue("fullName")
      const apiResult = await postData("api/auth/send-verification", {
        email,
        fullName,
      })
      setToken(apiResult.token)
    } catch (error: any) {
      console.error(error.data)
      if (error.data.message) {
        showToast("error", _t(error.data.message))
      }
    }
  }

  const onValidate = async () => {
    try {
      const code = form.getFieldValue("emailVerification")
      await postData("api/auth/verify-code", { token, code })
      await onRegister();
    } catch (error: any) {
      console.error(error.data)
      if (error.data && error.data.message) {
        showToast("error", _t(error.data.message))
      } else {
        showToast("error", _t("An error occured with connecting to the server"))
      }
    }
  }

  const buildBasicDetails = () => (
    <>
      {buildFormField(
        _t("form.fullName"),
        "fullName",
        <CircleUser width={20} height={20} />
      )}
      {buildFormField(
        _t("form.email"),
        "email",
        <Mail width={20} height={20} />
      )}
      {buildFormField(
        _t("form.directPhone"),
        "phone",
        <Phone width={20} height={20} />,
        "phone"
      )}
      {buildFormField(
        _t("form.sellerPhone"),
        "sellerPhone",
        <Phone width={20} height={20} />,
        "phone"
      )}
      {buildFormField(
        _t("form.country"),
        "country",
        <Building width={20} height={20} />,
        "country"
      )}
      {buildFormField(
        _t("form.uniqueBusinessId"),
        "businessId",
        <Building2 width={20} height={20} />
      )}
      {buildFormField(
        _t("form.password"),
        "password",
        <Lock width={20} height={20} />,
        "password"
      )}
      {buildFormField(
        _t("form.confirmPassword"),
        "confirmPassword",
        <Lock width={20} height={20} />,
        "password"
      )}
      <Form.Item>
        <Button onClick={onNext} className='w-full' size='large'>
          {_t("form.next")}
        </Button>
      </Form.Item>
      <p className='text-center text-base font-medium text-blue-800'>
        {_t("register.wondering")}?
        <Link
          to={"/auth/login"}
          className='underline ml-1 font-medium text-blue-300'
        >
          {_t("header.signIn")}
        </Link>
      </p>
    </>
  )

  const buildVerification = () => (
    <>
      {buildFormField(
        _t("form.emailVerificiation"),
        "emailVerification",
        <Mail width={20} height={20} />,
        "number"
      )}
      <Form.Item>
        <div className='w-full flex gap-x-5'>
          <Button block type='default' size='large' onClick={onResend}>
            {_t("form.resend")}
          </Button>
          {/* <Button block type="primary" size='large' onClick={onValidate}>
            {_t("form.validate")}
          </Button> */}
          <Button block onClick={onValidate} loading={isLoading} size='large'>
            {_t("register.button")}
          </Button>
        </div>
      </Form.Item>
    </>
  )

  // const buildSellerDetails = () => (
  //   <>
  //     {buildFormField(
  //       _t("form.streetNumber"),
  //       "streetNumber",
  //       <ListOrdered width={20} height={20} />,
  //       "number"
  //     )}
  //     {buildFormField(
  //       _t("form.streetName"),
  //       "streetName",
  //       <CornerRightUp width={20} height={20} />
  //     )}
  //     {buildFormField(
  //       _t("form.neighborhood"),
  //       "neighborhood",
  //       <UsersRound width={20} height={20} />
  //     )}
  //     {buildFormField(
  //       _t("form.state"),
  //       "state",
  //       <Home width={20} height={20} />,
  //       "state"
  //     )}
  //     {cities.length
  //       ? buildFormField(
  //           _t("form.city"),
  //           "city",
  //           <Home width={20} height={20} />,
  //           "city"
  //         )
  //       : ""}
  //     {buildFormField(
  //       _t("form.postalCode"),
  //       "postalCode",
  //       <ListOrdered width={20} height={20} />
  //     )}
  //     <Form.Item>
  //       <div className='w-full flex'>
  //         <Button block type='default' size='large' onClick={() => setTab(1)}>
  //           {_t("form.back")}
  //         </Button>
  //         <Button block htmlType='submit' loading={isLoading} size='large'>
  //           {_t("register.button")}
  //         </Button>
  //       </div>
  //     </Form.Item>
  //   </>
  // )

  return (
    <>
      {contextHolder}
      <div className='flex flex-col items-start md:items-center py-16 px-5'>
        <div className='flex flex-col items-start md:items-center gap-6 w-full md:w-[720px]'>
          <div className='flex flex-col items-start md:items-center gap-y-2 mb-5'>
            <h3 className='text-xl md:text-5xl font-bold text-blue-800'>
              {_t("register.title")}
            </h3>
            <p className='text-2xl text-blue-600 font-medium'>
              {_t("register.subdesc")}:
            </p>
          </div>
          <p className='w-full font-semibold text-2xl text-blue-800 text-center'>
            {_t("register.step")} {tab} {_t("register.of")} 2:
          </p>
          <Form
            layout={"vertical"}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            className='w-full md:w-[380px]'
            onSubmitCapture={onRegister}
            onValuesChange={onFormChange}
          >
            {tab === 1 && buildBasicDetails()}
            {tab === 2 && buildVerification()}
            {/* {tab === 3 && buildSellerDetails()} */}
          </Form>
        </div>
      </div>
    </>
  )
}

export default CreateSellerPage
