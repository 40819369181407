import { useEffect, useState } from "react";
import { Form, FormInstance, Radio, Space } from "antd";
import { _t } from "../../utils/translate";

const OtherIroningPleatedComponent = ({ form, updateFilter, reset }: { form: FormInstance, updateFilter: Function, reset: Boolean }) => {
  const [prevState, setPrevState] = useState<string>("");

  useEffect(() => {
    updateFilter({
      "ironingPleated": form.getFieldValue('ironingPleated'),
    });
    setPrevState(form.getFieldValue('ironingPleated'));
  }, []);

  useEffect(() => {
    setPrevState("");
  }, [reset])

  const handleClick = (value: any) => () => {
    setPrevState(prevState !== "" && prevState === value ? "" : value);
    updateFilter({ 'ironingPleated': prevState !== "" && prevState === value ? undefined : value });
  }

  return (
    <>
      <Form.Item name="ironingPleated" style={{ marginBottom: 4 }}>
        <Radio.Group size="large">
          <Space direction="vertical">
            <Radio value={'form.unpleated'} key={'ironingPleated' + 'form.unpleated'} className="text-base" onClick={handleClick('form.unpleated')}>{_t('form.unpleated')}</Radio>
            <Radio value={'form.pleated'} key={'ironingPleated' + 'form.pleated'} className="text-base" onClick={handleClick('form.pleated')}>{_t('form.pleated')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default OtherIroningPleatedComponent;
