import { Button } from "antd"
import { _t } from "../../utils/translate"

const Footer = () => {
  return (
    <footer
      className='w-full px-[31.74px] py-2 flex flex-col md:flex-row text-center justify-between items-center border-t-2 border-t-[#D1D5DB] bg-[#F9FAFB]'
      style={{ boxShadow: "0px 2px 2px 0px #1E3A8A2E" }}
    >
      <span className='flex-1 text-center md:text-left text-lg font-bold text-blue-800 flex-grow'>
        {_t("Need support? email us at contact@solotissu.com")}
      </span>
      <span className='flex-1 text-sm font-semibold text-blue-800 flex-grow'>
        Copyright Solotissu &copy; 2024
      </span>
      <span className='flex-1 text-sm text-blue-300 text-right'>Solotissu</span>
    </footer>
  )
}

export default Footer
