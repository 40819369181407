import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DownOutlined, ExclamationCircleFilled, SettingOutlined } from "@ant-design/icons";
import { Dropdown, List, MenuProps, Space, Image, message, Modal, Input, Button, InputNumber, Spin, Skeleton } from "antd";
import { AlarmClockMinus, Boxes, Building2, Copy, Edit, Edit2, Layers3, Percent, Receipt, Ruler, Send, Settings, Trash2, User2 } from "lucide-react";
import { RootState } from "@/redux/store";
import { formatDistanceToNow } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ColorDropdownType, FabricDataType } from "@/utils/types";
import { FABRIC_URI, postData } from "../../utils/utils";
import { _t } from "../../utils/translate";
import { primaryColors, shadowColors } from "../../utils/constants";

const FabricItem = ({ item, onRefresh }: { item: FabricDataType, onRefresh: Function }) => {
  const navigate = useNavigate();
  const { auth, lang } = useSelector((state: RootState) => state.auth);

  const [isDiscountVisible, setIsDiscountVisible] = useState<boolean>(false);
  const [isUpdateStockVisible, setIsUpdateStockVisible] = useState<boolean>(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [discount, setDiscount] = useState<number>(0);
  const [addStock, setAddStock] = useState<number>(0);
  const [subStock, setSubStock] = useState<number>(0);

  const [messageApi, contextHolder] = message.useMessage();

  const shadeColor = useMemo(() => {
    const primaryColor = primaryColors[primaryColors.findIndex((e: ColorDropdownType) => e.label === item.principalColor)].label;
    const shadeItems = [...shadowColors[`light${primaryColor}`], ...shadowColors[`dark${primaryColor}`]];
    return shadeItems[shadeItems.findIndex((e: ColorDropdownType) => e.label === item.colorShade)].color;
  }, [item])

  const items: MenuProps['items'] = [
    {
      label: <div className="flex gap-x-2"><Send color="#1E40AF" /><span className="text-base font-semibold text-blue-600">{_t("See live preview")}</span></div>,
      key: 'live-preview',
    },
    {
      label: <div className="flex gap-x-2"><Copy color="#1E40AF" /><span className="text-base font-semibold text-blue-600">{_t("Duplicate ref")}</span></div>,
      key: 'duplicate-ref',
    },
    {
      label: <div className="flex gap-x-2"><Edit color="#1E40AF" /><span className="text-base font-semibold text-blue-600">{_t("Edit")}</span></div>,
      key: 'edit',
    },
    {
      label: <div className="flex gap-x-2"><Layers3 color="#1E40AF" /><span className="text-base font-semibold text-blue-600">{_t("Update Stock")}</span></div>,
      key: 'update-stock',
    },
    {
      label: <div className="flex gap-x-2"><Percent color="#1E40AF" /><span className="text-base font-semibold text-blue-600">{_t("Apply Discount")}</span></div>,
      key: 'apply-discount',
    },
    {
      label: <div className="flex gap-x-2"><Trash2 color="#1E40AF" /><span className="text-base font-semibold text-blue-600">{_t("Delete")}</span></div>,
      key: 'delete',
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    console.log(key);
    switch (key) {
      case "live-preview":
        navigate(`/fabrics/details?id=${item.id}`);
        break;
      case 'duplicate-ref':
        navigate(`/fabrics/duplicate?id=${item.id}`);
        break;
      case "edit":
        navigate(`/fabrics/edit?id=${item.id}`);
        break;
      case "update-stock":
        setSubStock(0);
        setAddStock(0);
        setIsUpdateStockVisible(true);
        break;
      case "apply-discount":
        setDiscount(item.remise);
        setIsDiscountVisible(true);
        break;
      case "delete":
        setIsDeleteVisible(true);
        break;
    }
  };

  const showToast = (type: 'loading' | 'success' | 'error', content: React.ReactNode) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2
    });
  }

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await postData('api/fabric/delete', { id: item.id });
      showToast('success', _t("details.deletedFabric"));

      onRefresh();
    } catch (error) {
      console.error(error);
      showToast('error', _t("details.deleteFabricError"));
    } finally {
      setIsLoading(false);
      setIsDeleteVisible(false);
    }
  }

  const onApplyDiscount = async () => {
    setIsDiscountVisible(false);
    setIsLoading(true);
    try {
      await postData('api/fabric/update', { id: item.id, remise: discount });
      showToast('success', _t("Fabric data has been updated"));

      onRefresh();
    } catch (error) {
      console.error(error);
      showToast('error', _t("An error occured while updating fabric data"));
    } finally {
      setIsLoading(false);
    }
  }

  const onUpdateStock = async () => {
    const amount = addStock ? Math.abs(addStock) : -Math.abs(subStock);
    setIsUpdateStockVisible(false);
    setIsLoading(true);
    try {
      await postData('api/fabric/update', { id: item.id, updateStock: amount });
      showToast('success', _t("Fabric data has been updated"));

      onRefresh();
    } catch (error) {
      console.error(error);
      showToast('error', _t("An error occured while updating fabric data"));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <List.Item className="relative">
      {contextHolder}
      {isLoading && <div className="absolute w-full h-full p-3 flex items-center justify-center bg-[#f9f9f999] rounded-md" style={{ height: 'calc(100% - 1.5rem)' }}>
        <Spin />
      </div>}
      <BrowserView className="flex p-3 border border-blue-500 rounded-md items-start w-full justify-between bg-white gap-x-2">
        <div className="flex gap-x-5 text-blue-600">
          <div className="relative">
            {item.image
              ? <img src={FABRIC_URI + '/' + item.image} className="w-[150px] h-[150px] rounded-lg" />
              : <div className="w-[150px] h-[150px] border border-blue-600 rounded-md" style={{ backgroundColor: shadeColor }}></div>}
            {item.remise !== 0 &&
              <span className="absolute top-[10px] right-[8px] px-2 py-1 bg-white border border-red-500 rounded-lg text-lg font-semibold text-red-600">-{item.remise}%</span>}
          </div>
          <div className="flex flex-col gap-6 justify-center">
            <div className="flex gap-8">
              <span className="text-[20px] font-bold text-blue-800">{item.ref}</span>
              <div className="flex items-center gap-2 text-blue-800 text-lg font-bold">
                <Receipt />
                {item.remise === 0
                  ? <span>{item.price} MAD/{item.unit === 'Mts' ? 'm' : 'kg'}</span>
                  : <div className="flex flex-row items-end">
                    <span className="">{(item.price * (100 - item.remise) / 100).toFixed(1)} MAD/{item.unit === 'Mts' ? 'm' : 'kg'}</span>
                    <span className="line-through text-red-500 text-sm">{item.price} MAD/{item.unit === 'Mts' ? 'm' : 'kg'}</span>
                  </div>}
              </div>
              <div className="flex items-center gap-2">
                <Ruler />
                <span className="text-lg font-semibold">{item.currentStock} {item.unit === 'Mts' ? 'mts' : 'kg'}</span>
              </div>
            </div>
            <div className="flex gap-x-3">
              <div style={{ width: 100, height: 30, borderRadius: 8, backgroundColor: shadeColor, boxShadow: '0 0 5px gray' }}></div>
              {auth && auth.type === 'ADMIN' && (
                <div className="flex items-center gap-2 text-lg">
                  <User2 />
                  <span className="font-semibold">{item.sellerName}</span>
                </div>
              )}
            </div>
            <div className="flex gap-8">
              <div className="flex items-center gap-2">
                <AlarmClockMinus />
                <span className="text-base font-semibold capitalize-first-letter">{formatDistanceToNow(new Date(item.date.toString()), { locale: lang === 'en' ? enUS : fr, addSuffix: true })}</span>
              </div>
              <div className="flex items-center gap-2">
                <Boxes />
                <span className="text-base font-semibold">{item.stockCity}</span>
              </div>
              <div className="flex items-center gap-2">
                <Building2 />
                <span className="text-base font-semibold">{item.stockCountry}</span>
              </div>
            </div>
          </div>
        </div>
        <Dropdown menu={{ items, onClick }} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            <Space className="text-blue-800">
              <Settings size={32} />
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </BrowserView>
      <MobileView className="flex flex-col border border-blue-500 rounded-md items-start w-full justify-between bg-white">
        <div className="absolute z-10 w-full flex items-end justify-end gap-x-2">
          {item.remise !== 0 &&
              <span className="px-2 py-1 bg-white border border-red-500 rounded-lg text-lg font-semibold text-red-600">-{item.remise}%</span>}
          <Dropdown className="bg-white rounded-tr-md rounded-bl-md" menu={{ items, onClick }} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()} className="p-2 mr-[2px]">
              <Space className="text-blue-800">
                <Settings size={32} />
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="relative w-full pt-3 px-3">
          {item.image
            ? <img src={FABRIC_URI + '/' + item.image} className="w-full aspect-square rounded-lg" />
            : <div className="w-full aspect-square border border-blue-600 rounded-md" style={{ backgroundColor: shadeColor }}></div>}
        </div>
        <div className="flex flex-col w-full p-3 gap-y-2 text-blue-600">
          <div className="flex justify-between w-full text-blue-800">
            <span className="text-[20px] font-bold">{item.ref}</span>
            <div className="flex items-center gap-1">
              <Receipt />
              <span className="text-lg font-bold">{item.price} MAD</span>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-2">
              <Ruler />
              <span className="text-lg font-semibold">{item.currentStock} mts</span>
            </div>
            <div style={{ width: 100, height: 30, borderRadius: 8, backgroundColor: shadeColor }}></div>
          </div>
          {auth && auth.type === 'ADMIN' && (
            <div className="flex items-center gap-2 text-lg">
              <User2 />
              <span className="font-semibold">{item.sellerName}</span>
            </div>
          )}
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-2">
              <AlarmClockMinus />
              <span className="text-base font-semibold capitalize-first-letter">{formatDistanceToNow(new Date(item.date.toString()), { locale: lang === 'en' ? enUS : fr, addSuffix: true })}</span>
            </div>
            <div className="flex items-center gap-2">
              <Boxes />
              <span className="text-base font-semibold">{item.stockCity}</span>
            </div>
            <div className="flex items-center gap-2">
              <Building2 />
              <span className="text-base font-semibold">{item.stockCountry}</span>
            </div>
          </div>
        </div>
      </MobileView>
      <Modal
        title={_t("Apply Discount")} centered
        open={isDiscountVisible} onCancel={() => setIsDiscountVisible(false)}
        styles={{ body: { display: 'flex', justifyContent: 'center' } }}
        footer={<Button onClick={onApplyDiscount}>Ok</Button>}
      >
        <InputNumber style={{ width: 300 }} size="large" value={discount} onChange={(val: any) => setDiscount(val)} />
      </Modal>
      <Modal
        title={_t("Update Stock")} centered
        open={isUpdateStockVisible} onCancel={() => setIsUpdateStockVisible(false)}
        styles={{ body: { display: 'flex', justifyContent: 'center' } }}
        footer={<Button onClick={onUpdateStock}>Ok</Button>}
      >
        <div className="w-[300px] flex flex-col gap-y-2">
          <div className="flex gap-x-2 text-base font-semibold text-red-600">
            <span className="flex items-center justify-end whitespace-nowrap text-right w-[130px]">- {_t("Reduce")} :</span>
            <InputNumber style={{ width: 300 }} size="large" value={subStock} onChange={(val: any) => { setSubStock(val); setAddStock(0); }} />
          </div>
          <div className="flex gap-x-2 text-base font-semibold text-blue-800">
            <span className="flex items-center justify-end whitespace-nowrap text-right w-[130px]">+ {_t("Append")} :</span>
            <InputNumber style={{ width: 300 }} size="large" value={addStock} onChange={(val: any) => { setAddStock(val); setSubStock(0); }} />
          </div>
        </div>
      </Modal>
      <Modal
        title={<div className="text-lg"><ExclamationCircleFilled className="mr-2" /> {_t("Confirm")}</div>}
        onCancel={() => setIsDeleteVisible(false)}
        open={isDeleteVisible} centered footer="">
        <div className="flex flex-col gap-3">
          <span className="text-base">{_t("Are you sure you want to remove this fabric?")}</span>
          <div className="flex justify-end gap-3">
            <Button size="large" type="primary" danger onClick={() => onDelete()}>{_t("Delete")}</Button>
            <Button size="large" onClick={() => setIsDeleteVisible(false)}>{_t("details.close")}</Button>
          </div>
        </div>
      </Modal>
    </List.Item>
  )
}

export default FabricItem;