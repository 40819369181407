import { Card, Empty } from "antd";
import { _t } from "../../utils/translate";

const DetailsEmptyComponent = () => (
  <>
    <h1 className="text-2xl mb-3 pt-5">{_t("research.title")}</h1>
    <Card>
      <div className="flex items-center justify-center w-full md:w-[800px] h-[300px]">
        <Empty className="text-xl pb-12 md:pb-24" description={_t("details.empty")} />
      </div>
    </Card>
  </>
)

export default DetailsEmptyComponent;