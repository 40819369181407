import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, MenuProps, Space } from "antd";

import { ChevronDown, CircleUserRound, Database, LogOut, Package, Plus, Settings, Users } from "lucide-react";
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@/redux/store';

import { setAuth, setLanguage } from "../../redux/authSlice";
import { _t } from '../../utils/translate';
import { useMemo } from 'react';

const HeaderDesktopView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth, lang } = useSelector((state: RootState) => state.auth);

  const onLogout = () => {
    localStorage.removeItem('token');
    dispatch(setAuth(null));
    navigate('/auth/login');
  }

  const items = useMemo(() => {
    let result: MenuProps['items'] = [];
    if (auth) {
      if (auth.type !== 'VISITOR') {
        result.push({
          key: '1',
          label: (
            <Link to="/space/fabrics">
              <div className="flex text-base font-semibold gap-x-1 text-blue-800">
                <Package /> {auth.type === 'ADMIN' ? _t("menu.fabrics") : _t("menu.myFabrics")}
              </div>
            </Link>
          )
        });
      }

      if (auth.type === 'SELLER') {
        result.push(
          {
            key: '2',
            label: (
              <Link to="/space/history">
                <div className="flex text-base font-semibold gap-x-1 text-blue-800">
                  <Database /> {_t("menu.myHistory")}
                </div>
              </Link>
            ),
          })
      }

      if (auth.type === 'ADMIN') {
        result.push(
          {
            key: '2',
            label: (
              <Link to="/space/users">
                <div className="flex text-base font-semibold gap-x-1 text-blue-800">
                  <Users /> {_t("menu.users")}
                </div>
              </Link>
            ),
          })
      }
    }
    result.push(
      {
        key: '3',
        label: (
          <Link to="/auth/settings">
            <div className="flex text-base font-semibold gap-x-1 text-blue-800">
              <Settings /> {_t("menu.settings")}
            </div>
          </Link>
        ),
      },
      {
        type: 'divider',
      },
      {
        key: '4',
        onClick: onLogout,
        label: (
          <div className="flex text-base font-semibold gap-x-1 text-blue-800">
            <LogOut /> {_t("menu.logout")}
          </div>
        ),
      },
    );
    return result;
  }, [auth]);

  const switchLanguage = () => {
    dispatch(setLanguage(lang === 'en' ? 'fr' : 'en'))
  }

  return (
    <>
      <Link to="/" className="text-blue-800 font-bold text-2xl select-none tracking-[-0.02em] flex items-center">
        Solotissu
      </Link>
      <div className="hidden md:flex items-center justify-center px-4">
        <Link to="/">
          <Button size='large' type={'link'} className='flex items-center text-md font-semibold leading-[30px] text-center text-[#1E40AF]'>{_t("header.home")}</Button>
        </Link>
        <Link to="/fabrics/research">
          <Button size='large' type={'link'} className='flex items-center text-md font-semibold leading-[30px] text-center text-[#1E40AF]'>{_t("header.research")}</Button>
        </Link>
        {/* <Link to="/about">
          <Button size='large' type={'link'}>{_t("header.aboutUs")}</Button>
        </Link>
        <Link to="/contact">
          <Button size='large' type={'link'}>{_t("header.contactUs")}</Button>
        </Link> */}
      </div>
      <div className="hidden md:flex items-center gap-x-3">
        <Button 
          type='primary' size='large' className="uppercase h-[40px]" 
          onClick={switchLanguage}
        >{lang}</Button>
        {auth
          ? <>
            {auth.type === 'SELLER' || auth.type === 'ADMIN'
              ? <Button 
                  size='large' className='flex items-center h-[40px]'
                  onClick={() => navigate('/fabrics/publish')}
                > <Plus /> {_t("form.publish")} </Button>
              : <Button 
                  size='large' className='h-[40px]'
                  onClick={() => navigate('/auth/becomeSeller')}
                > {_t("header.becomeSeller")} </Button>
            }
            <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
              <Button 
                type='primary' size='large' icon={<CircleUserRound />}
                className="flex items-center text-base h-[40px]"
              >
                <Space>
                  {_t("header.mySpace")}
                  <ChevronDown />
                </Space>
              </Button>
            </Dropdown>
          </>
          : <>
            <Button 
              size='large' className='px-5 h-[40px]' 
              onClick={() => navigate('/auth/login')}
            >{_t("header.signIn")}</Button>
            <Button 
              type='primary' size='large' className='px-5 h-[40px]' 
              onClick={() => navigate('/auth/register')}
            >{_t("header.startForFree")}</Button>
          </>
        }
      </div>
    </>
  )
}

export default HeaderDesktopView;