import { useEffect, useMemo, useState } from "react";
import { Button, Form, FormInstance, Input, InputNumber, Radio, Upload, Image, Row, Col, Modal } from "antd";
import type { GetProp, RadioChangeEvent, UploadFile, UploadProps } from 'antd';
import Select from "react-select";
import { City, Country, State } from "country-state-city";
import { _t } from "../../utils/translate";
import { OptionType } from "../../utils/types";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { FABRIC_URI, customFilterOption, getBase64, postData, uploadData } from "../../utils/utils";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Check } from "lucide-react";

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const StepNineComponent = ({ form, showToast, update, isLoading }: { form: FormInstance, showToast: Function, update: Function, isLoading: boolean }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState('');

  const [selectedCountry, setSelectedCountry] = useState<OptionType>({ label: "", value: "", realValue: "" });
  const [selectedState, setSelectedState] = useState<OptionType | undefined>({ label: "", value: "", realValue: "" });

  const [isImageEmpty, setIsImageEmpty] = useState<boolean>(false);
  const [fileImage, setFileImage] = useState<File>();

  const [visible, setConfirmVisible] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>(form.getFieldValue('currency') || "EURO");

  const countries = Country.getAllCountries();

  const states = useMemo(() => {
    if (!selectedCountry) return [];

    const country = countries.find(c => c.name === selectedCountry.value);
    setSelectedState({ label: "", value: "", realValue: "" });
    return country ? State.getStatesOfCountry(country.isoCode) : [];
  }, [selectedCountry]);

  const cities = useMemo(() => {
    if (!selectedState) return [];

    const country = countries.find(c => c.name === selectedCountry.value);
    const state = states.find(c => c.name === selectedState.value);
    return country && state ? City.getCitiesOfState(country.isoCode, state.isoCode) : [];
  }, [selectedState]);

  useEffect(() => {
    setSelectedCountry(form.getFieldValue('stockCountry'));
    setSelectedState(form.getFieldValue('stockState'));
    const imageContent = form.getFieldValue('image');
    if (imageContent && imageContent != "") {
      if (typeof imageContent === "string") setPreviewImage(imageContent.startsWith("data:image") ? imageContent : FABRIC_URI + '/' + imageContent);
      else {
        const reader = new FileReader();
        reader.readAsDataURL(imageContent);
        reader.onload = (event) => {
          if (event.target !== null) {
            console.log(event.target);
            setPreviewImage(event.target.result as string);
            setFileImage(imageContent);
            form.setFieldValue('image', imageContent);
          }
        }
      }
    }
    form.setFieldValue('image', form.getFieldValue('image') ? form.getFieldValue('image') : "");
  }, [])

  const onBack = async () => {
    update('prev-step');
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);

  const beforeUpload = (file: File) => {
    // const isLessThan100KB = file.size / 1024 < 100; // file.size is in bytes
    // if (!isLessThan100KB) {
    //   showToast('error', _t("error.bigImage"));
    // }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      if (event.target !== null) {
        console.log(event.target);
        setPreviewImage(event.target.result as string);
        form.setFieldValue('image', file);
      }
    }
    setFileImage(file);
    return Upload.LIST_IGNORE; // Returning LIST_IGNORE to prevent file from being added to fileList
  }

  const onValidate = async () => {
    try {
      const isEmpty = form.getFieldValue('image') === undefined || form.getFieldValue('image').toString() === "undefined" || form.getFieldValue('image') === "";
      setIsImageEmpty(isEmpty);

      if (form.getFieldValue('colorType') !== 'form.unified' && isEmpty) throw "You should upload image file.";

      await form.validateFields();

      setConfirmVisible(true);
    } catch (error: any) {
      console.error(error);
      showToast('error', _t("error.publishError"));
    }
  }

  const onCheck = () => {
    setConfirmVisible(false);

    update('file-image', fileImage);
    update('next-step');
  }

  const onPublish = async () => {
    setConfirmVisible(false);
    try {
      if (fileImage) {
        update('data-submit');

        let data = new FormData();
        data.append('file', fileImage!);
        const apiResult = await uploadData('api/fabric/file-upload', data);
        form.setFieldValue('image', apiResult.key as string);
        update('update-property', { 'image': apiResult.key as string });

        update('data-submitted');
        update('final-step', { 'image': apiResult.key as string });
      } else {
        update('final-step', {});
      }
    } catch (error: any) {
      console.error(error);
      showToast('error', _t("error.publishError"));
    }
  }

  const onCurrencyChange = (e: RadioChangeEvent) => {
    setCurrency(e.target.value);
  }

  const LeftView = () => (
    <>
      <Form.Item
        label={_t("form.origin")} name={"origin"} key={"origin"}
        initialValue={""}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          isSearchable placeholder=""
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError('origin').length > 0 ? '1px solid red' : '' }) }}
          options={countries.map((country) => ({ value: country.name, label: country.name }))}
          filterOption={customFilterOption}
        />
      </Form.Item>
      <Form.Item
        label={_t("form.stockCountry")} name={"stockCountry"} key={"stockCountry"}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          isSearchable placeholder=""
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError('stockCountry').length > 0 ? '1px solid red' : '' }) }}
          options={countries.map((country) => { return { value: country.name, label: country.name }; })}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          onChange={(option) => { setSelectedCountry(option); form.setFieldValue('stockState', ""); form.setFieldValue('stockCity', ""); }}
          filterOption={customFilterOption}
        />
      </Form.Item>
      {states.length ? <Form.Item
        label={_t("form.stockState")} name={"stockState"} key={"stockState"}
      >
        <Select
          isSearchable placeholder=""
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError('stockState').length > 0 ? '1px solid red' : '' }) }}
          options={states.map((state) => { return { value: state.name, label: state.name }; })}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          onChange={(option) => { setSelectedState(option); form.setFieldValue('stockCity', ""); }}
          filterOption={customFilterOption}
        />
      </Form.Item> : ""}
      {cities.length ? <Form.Item
        label={_t("form.stockCity")} name={"stockCity"} key={"stockCity"}
      >
        <Select
          isSearchable placeholder=""
          styles={{ container: (base) => ({ ...base, width: 300, fontSize: 16, borderRadius: 4, border: form.getFieldError('stockCity').length > 0 ? '1px solid red' : '' }) }}
          options={cities.map((city) => { return { value: city.name, label: city.name }; })}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          filterOption={customFilterOption}
        />
      </Form.Item> : ""}
    </>
  )

  const RightView = () => (
    <>
      <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.currentStock")}</span>}
        name={"currentStock"} key={"currentStock"}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={form.getFieldValue('salesMethod') === 'form.yes' ? "Mts" : "Kgs"} />
      </Form.Item>
      {/* <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.upcomingShipmentQuantity")}</span>}
        name={"upcomingShipmentQuantity"} key={"upcomingShipmentQuantity"}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={form.getFieldValue('salesMethod') === 'form.yes' ? "Mts" : "Kgs"} />
      </Form.Item> */}
      <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.quantityAvailableInTwoWeeks")}</span>}
        name={"quantityAvailableInTwoWeeks"} key={"quantityAvailableInTwoWeeks"}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={form.getFieldValue('salesMethod') === 'form.yes' ? "Mts" : "Kgs"} />
      </Form.Item>
      <Form.Item
        label={<span style={{ whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.availabilityIn6Weeks")}</span>}
        name={"availabilityIn6Weeks"} key={"availabilityIn6Weeks"}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={form.getFieldValue('salesMethod') === 'form.yes' ? "Mts" : "Kgs"} />
      </Form.Item>
      <Form.Item
        label={<span style={{ whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.currency")}</span>}
        name={"currency"} key={"currency"}
        initialValue={'EURO'}
        rules={[{ required: true, message: '' }]}
      >
        <Radio.Group size="large" onChange={onCurrencyChange}>
          <Radio value={'EURO'} key={'EURO'} className="text-base">EURO</Radio>
          <Radio value={'MAD'} key={'MAD'} className="text-base">MAD</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.price")}</span>}
        name={"price"} key={"price"}
        initialValue={''}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={`${currency}/${form.getFieldValue('salesMethod') === 'form.yes' ? "Mts" : "Kgs"}`} />
      </Form.Item>
      <Form.Item
        label={<span style={{ maxWidth: 250, whiteSpace: 'normal', height: 40, display: 'flex', alignItems: 'center', lineHeight: '1.2rem' }}>{_t("form.remise")}</span>}
        name={"remise"} key={"remise"}
      >
        <InputNumber size="large" style={{ width: 300 }} addonAfter={'%'} />
      </Form.Item>
      {form.getFieldValue('colorType') !== 'form.unified' && <Form.Item
        label={<span style={{ whiteSpace: 'normal', lineHeight: '1.2rem' }}>{_t("form.selectImage")} (JPEG)</span>} name={"image"} key={"image"} initialValue={""}
        rules={[{ required: true, message: '' }]}
      >
        {previewImage && previewImage !== ""
          ? <div className="relative">
            <img src={previewImage} className="w-[100px] h-[100px]" style={{ boxShadow: '0 1px 5px black', borderRadius: 8 }} />
            <Button
              type="primary" danger
              className="absolute top-[-10px] left-[85px]"
              onClick={() => { setPreviewImage(""); form.setFieldValue('image', ""); fileList.pop(); }}
              icon={<CloseCircleOutlined />}
            />
          </div>
          : <div className="rounded-md w-[102px]" style={{ border: isImageEmpty ? '1px solid red' : '' }}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              // onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={beforeUpload}
            >
              {_t("Upload")}
            </Upload>
          </div>
        }
      </Form.Item>}
    </>
  )

  return (
    <>
      <MobileView>
        <LeftView />
        <RightView />
      </MobileView>
      <BrowserView>
        <Row>
          <Col span={12} className="flex flex-col">
            <LeftView />
          </Col>
          <Col span={12} className="flex flex-col">
            <RightView />
          </Col>
        </Row>
      </BrowserView>
      <Form.Item wrapperCol={{ offset: isMobile ? 0 : 10 }}>
        <div className="flex gap-x-2">
          <Button size="large" onClick={onBack}>{_t("form.back")}</Button>
          <Button size="large" onClick={onValidate} loading={isLoading}>{_t("form.publish")}</Button>
        </div>
      </Form.Item>
      <Modal open={visible} centered footer={<></>} closable={true} onCancel={() => setConfirmVisible(false)}>
        <div className="flex flex-col items-center gap-y-2">
          <Check size={64} color="#00ff9d" />
          <span className="text-2xl font-bold text-blue-700 text-center">{_t("Fabric ready to be published")}</span>
          <div className="flex justify-center gap-x-5 mt-5">
            <Button
              type="primary" size="large" className="w-[160px] h-[64px]"
              onClick={onCheck}
            >
              <span className="whitespace-pre-wrap">{_t("Check before publishing")}</span>
            </Button>
            <Button
              type="primary" size="large" className="w-[160px] h-[64px]"
              onClick={onPublish}
            >
              <span className="whitespace-pre-wrap">{_t("Confirm publication")}</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StepNineComponent;