import { useEffect, useState } from "react";
import { Input, Radio, Space, List, RadioChangeEvent, Pagination, PaginationProps, Empty } from "antd";

import SpaceHeader from "../../components/space/space-header";
import FabricItem from "../../components/space/FabricItem";
import { fetchData } from "../../utils/utils";
import { FabricDataType } from '../../utils/types';
import { ArrowDown, ArrowUp } from "lucide-react";

import { _t } from "../../utils/translate";

const { Search } = Input;

const MyFabrics = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [sortByDate, setSortByDate] = useState<boolean | undefined>(false); //  Old2New(T), New2Old(F)
  const [sortByPrice, setSortByPrice] = useState<boolean | undefined>(undefined); // High2Low(T), Low2High(F)
  const [fabricData, setFabricData] = useState<FabricDataType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const pageSize = 9;

  useEffect(() => {
    loadFabrics(page, searchText, sortByDate, sortByPrice)
  }, [page, searchText, sortByDate, sortByPrice])

  const loadFabrics = async (page: number, searchText: string, sortByDate: boolean | undefined, sortByPrice: boolean | undefined) => {
    setIsLoading(true);
    try {
      const apiResult = await fetchData("api/fabric/read", { from: (page - 1) * pageSize, to: page * pageSize - 1, searchText, sortByDate, sortByPrice })

      if (apiResult) {
        const fabrics: FabricDataType[] = apiResult.fabrics;
        const total: number = apiResult.total;
        setFabricData(fabrics);
        setTotalCount(total);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const onSearchChange = (text: string) => {
    setPage(1);
    setSearchText(text);
  }

  const onDateChange = (e: RadioChangeEvent) => {
    setSortByDate(e.target.value);
    setSortByPrice(undefined);
  }

  const onPriceChange = (e: RadioChangeEvent) => {
    setSortByPrice(e.target.value);
    setSortByDate(undefined);
  }

  const onPageChange: PaginationProps['onChange'] = (page: number) => {
    setPage(page);
  };

  return (
    <div className='p-2 md:p-10'>
      <SpaceHeader />
      <div className="flex flex-col md:flex-row mt-5 gap-x-5">
        <div className="py-3">
          <div className="flex flex-col gap-4">
            <Search size="large" onSearch={onSearchChange} className="w-full md:w-[300px]" />
            <div className="flex flex-row md:flex-col justify-between">
              <div className="flex flex-col">
                <p className="text-lg font-bold text-blue-700">{_t("Sort by Date")}: </p>
                <Radio.Group size="large" onChange={onDateChange} value={sortByDate} className="p-3">
                  <Space direction="vertical">
                    <Radio value={true}><div className="flex items-center text-base text-blue-400 font-semibold">{_t("form.old2New")} <ArrowUp /></div></Radio>
                    <Radio value={false}><div className="flex items-center text-base text-blue-400 font-semibold">{_t("form.new2Old")} <ArrowDown /></div></Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div className="flex flex-col">
                <p className="text-lg font-bold text-blue-700">{_t("Sort by Price")}: </p>
                <Radio.Group size="large" onChange={onPriceChange} value={sortByPrice} className="p-3">
                  <Space direction="vertical">
                    <Radio value={true}><div className="flex items-center text-base text-blue-400 font-semibold">{_t("form.high2Low")} <ArrowUp /></div></Radio>
                    <Radio value={false}><div className="flex items-center text-base text-blue-400 font-semibold">{_t("form.low2High")} <ArrowDown /></div></Radio>
                  </Space>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <span className="text-right font-semibold text-lg text-blue-800">{totalCount} {_t("fabric result")}</span>
          <List
            className="min-h-[240px]"
            loading={isLoading}
            itemLayout="horizontal"
            dataSource={fabricData}
            renderItem={(item) => <FabricItem item={item} onRefresh={() => loadFabrics(page, searchText, sortByDate, sortByPrice)} />}
            locale={{ emptyText: <Empty description={<span className="text-base font-bold">{<span className="text-blue-800">{_t("No data")}</span>}</span>} /> }}
          />
          <div className="flex justify-center">
            <Pagination pageSize={pageSize} total={totalCount} current={page} onChange={onPageChange} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyFabrics;