import { useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  Button,
  Card,
  Tabs,
  TabsProps,
  message,
  Collapse,
  Modal,
  Skeleton,
} from "antd"

import FabricMtsDetailsPage from "../../components/fabric/fabricMtsDetails"
import FabricKgsDetailsPage from "../../components/fabric/fabricKgsDetails"
import SellerDetailsPage from "../../components/fabric/sellerDetails"

import DetailsLoadingComponent from "../../components/details/detailsLoading"
import DetailsEmptyComponent from "../../components/details/detailsEmpty"
import ScrollIndicator from "../../components/details/scrollIndicator"

import {
  ColorDropdownType,
  FabricDetailType,
  SellerDetailType,
} from "../../utils/types"
import {
  ChevronLeft,
  Palette,
  Receipt,
  Ruler,
  Settings,
  Tally4,
  UserRoundCheck,
} from "lucide-react"

import { _t } from "../../utils/translate"
import { FABRIC_URI, fetchData, postData } from "../../utils/utils"
import { isBrowser } from "react-device-detect"
import {
  ExclamationCircleFilled,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons"
import { RootState } from "@/redux/store"
import { useSelector } from "react-redux"
import { primaryColors, shadowColors } from "../../utils/constants"

const FabricDetails = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { auth } = useSelector((state: RootState) => state.auth)

  const [id, setId] = useState<string>("")
  const [fabricDetails, setFabricDetails] = useState<FabricDetailType>()
  const [sellerDetails, setSellerDetails] = useState<SellerDetailType>()
  const [isFabricEditable, setIsFabricEditable] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false)

  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    const id = searchParams.get("id")

    if (id && id !== "") {
      loadFabricData(id)
      setId(id)
    }
  }, [searchParams])

  const showToast = (
    type: "loading" | "success" | "error",
    content: React.ReactNode
  ) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2,
    })
  }

  const loadFabricData = async (id: string) => {
    setIsLoading(true)
    try {
      const apiResult = await fetchData("api/fabric/details", { id })

      if (apiResult && apiResult.status === "success") {
        const fabricDetails: FabricDetailType = apiResult.fabric
        const isEditable: boolean = apiResult.isEditable

        setFabricDetails(fabricDetails)
        setIsFabricEditable(isEditable)
      }

      if (auth) {
        await fetchSellerDetails(id)
      }
    } catch (error: any) {
      console.error(error)
      if (error.data.message) {
        showToast("error", _t(error.data.message))
      } else {
        showToast("error", _t("error.noFabric"))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const fetchSellerDetails = async (id: string) => {
    try {
      const apiResult = await fetchData("api/fabric/seller_details", {
        fabricId: id,
      })

      if (apiResult && apiResult.status === "success") {
        const seller: SellerDetailType = apiResult.seller
        setSellerDetails(seller)
      }
    } catch (error: any) {
      console.error(error)
      if (error.data.message) {
        showToast("error", _t(error.data.message))
      } else {
        showToast("error", _t("error.noFabric"))
      }
    }
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className='flex gap-x-2'>
          <Settings />
          <span className='text-blue-800 font-bold text-lg'>
            {_t("details.generalDetails")}
          </span>
        </div>
      ),
      children:
        fabricDetails?.colorType === "form.unified" ? (
          <FabricMtsDetailsPage details={fabricDetails} />
        ) : (
          <FabricKgsDetailsPage details={fabricDetails} />
        ),
    },
    {
      key: "2",
      label: (
        <div className='flex gap-x-2'>
          <UserRoundCheck />
          <span className='text-blue-800 font-bold text-lg'>
            {_t("details.sellerDetails")}
          </span>
        </div>
      ),
      children: <SellerDetailsPage details={sellerDetails} />,
    },
  ]

  const onCall = () => {
    if (sellerDetails && sellerDetails.phone)
      window.location.href = `tel:+${sellerDetails.sellerPhone}`
  }

  const onEmail = () => {
    // Check that sellerDetails is defined and has a non-null/undefined 'email' property before trying to use it.
    if (sellerDetails && sellerDetails.email) {
      console.log(sellerDetails)
      window.location.href = `mailto:${sellerDetails.email}`
    } else {
      // Handle the case where sellerDetails is undefined or sellerDetails.email is not provided.
      console.error("Seller details are undefined or email is not provided")
    }
  }

  const onDeleteFabric = async () => {
    try {
      const apiResult = await postData("api/fabric/delete", { id })
      showToast("success", _t("details.deletedFabric"))
      navigate("/fabrics/research")
    } catch (error) {
      console.error(error)
    }
  }

  const shadeColor = useMemo(() => {
    if (!fabricDetails) return ""
    const primaryColor =
      primaryColors[
        primaryColors.findIndex(
          (e: ColorDropdownType) => e.label === fabricDetails.principalColor
        )
      ].label
    const shadeItems = [
      ...shadowColors[`light${primaryColor}`],
      ...shadowColors[`dark${primaryColor}`],
    ]
    return shadeItems[
      shadeItems.findIndex(
        (e: ColorDropdownType) => e.label === fabricDetails.colorShade
      )
    ].color
  }, [fabricDetails])

  const onReturn = () => {
    navigate(-1);
  }

  return (
    <div className='flex flex-col items-center'>
      {contextHolder}
      {isLoading && <DetailsLoadingComponent />}
      {!isLoading && fabricDetails === undefined && <DetailsEmptyComponent />}
      {!isLoading && fabricDetails && (
        <>
          <div className="flex flex-col w-full items-center md:flex-col-reverse bg-white sticky top-[62px] z-10 pt-5" style={{ width: 'calc(100% + 4px)' }}>
            <div className="w-full max-w-[900px] mb-3">
              <Button
                type="link" size="large" onClick={onReturn}
                icon={<div><ChevronLeft size={24} /></div>}
                className='inline-flex items-center underline text-blue-800 text-xl'
              >
                {_t("Return")}
              </Button>
            </div>
            <h1 className='text-2xl mb-3 font-bold text-blue-800'>
              {_t("research.title")}
            </h1>
          </div>
          <Card
            className='w-full max-w-[900px] m-5'
            bordered={isBrowser}
            styles={{ body: { padding: isBrowser ? 24 : 0 } }}
          >
            <div className='flex flex-col md:flex-row gap-x-3 mb-6'>
              <div className='relative rounded-md border border-blue-500'>
                {fabricDetails.image ? (
                  <img
                    className='w-full md:w-[200px] h-[200px] rounded-md border-red-600'
                    src={FABRIC_URI + "/" + fabricDetails.image}
                    loading='lazy'
                  />
                ) : (
                  <div
                    className='w-full md:w-[200px] h-[200px] rounded-md border-red-600'
                    style={{ backgroundColor: shadeColor }}
                  ></div>
                )}
                {fabricDetails.remise !== 0 && (
                  <span className='absolute top-[10px] right-[10px] px-2 py-1 bg-white border border-red-500 rounded-lg text-lg font-semibold text-red-600'>
                    -{fabricDetails.remise}%
                  </span>
                )}
              </div>
              <div className='flex-1 flex flex-col justify-between h-[200px] gap-y-3'>
                <div
                  className='flex flex-col md:flex-row justify-between w-full bg-[#EFF6FF] rounded-md p-5 gap-y-3 text-center'
                  style={{ boxShadow: "1px 1px 2px #1E40AF" }}
                >
                  <div className='flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5'>
                    <span className='flex gap-x-1 font-semibold text-blue-800'>
                      <Tally4 size={32} /> {_t("form.ref")}
                    </span>
                    <span className='text-blue-600 font-semibold'>
                      {fabricDetails.ref}
                    </span>
                  </div>
                  <div className='flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5'>
                    <span className='flex gap-x-1 font-semibold text-blue-800'>
                      <Receipt size={32} /> {_t("form.priceText")}
                    </span>
                    {fabricDetails.remise === 0 ? (
                      <span>
                        {fabricDetails.price} MAD/
                        {fabricDetails.unit === "Mts" ? "m" : "kg"}
                      </span>
                    ) : (
                      <div className='flex flex-col'>
                        <span className=''>
                          {(
                            (fabricDetails.price *
                              (100 - fabricDetails.remise)) /
                            100
                          ).toFixed(1)}{" "}
                          MAD/{fabricDetails.unit === "Mts" ? "m" : "kg"}
                        </span>
                        <span className='line-through text-red-500 text-sm'>
                          {fabricDetails.price} MAD/
                          {fabricDetails.unit === "Mts" ? "m" : "kg"}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5'>
                    <span className='flex gap-x-1 font-semibold text-blue-800'>
                      <Ruler size={32} /> {_t("form.quantity")}
                    </span>
                    <span className='text-blue-600 font-semibold'>
                      {fabricDetails.currentStock}{" "}
                      <span className='text-base font-normal lowercase'>
                        {fabricDetails.unit}
                      </span>
                    </span>
                  </div>
                  <div className='flex flex-row md:flex-col justify-between md:justify-start text-lg gap-y-5'>
                    <span className='flex gap-x-1 font-semibold text-blue-800'>
                      <Palette size={32} /> {_t("form.color")}
                    </span>
                    <span
                      className='w-[100px] h-[30px] rounded-md'
                      style={{
                        boxShadow: "1px 1px 3px black",
                        backgroundColor: shadeColor,
                      }}
                    ></span>
                  </div>
                </div>
                <div className='flex flex-col md:flex-row gap-2'>
                  <Button size='large' onClick={() => setVisible(true)}>
                    {_t("details.contactSellers")}
                  </Button>
                  {isFabricEditable && (
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => navigate(`/fabrics/edit?id=${id}`)}
                    >
                      {_t("Edit fabric")}
                    </Button>
                  )}
                  {auth && auth.type === "ADMIN" && (
                    <Button
                      type='primary'
                      danger
                      size='large'
                      onClick={() => setIsDeleteVisible(true)}
                    >
                      {_t("details.deleteFabric")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {isBrowser ? (
              <Tabs items={items} size='large' />
            ) : (
              <Collapse defaultActiveKey={["1"]} items={items} size='large' />
            )}
          </Card>
          <Modal
            title={_t("details.contactSellers")}
            centered
            open={visible}
            onCancel={() => setVisible(false)}
            footer=''
          >
            <div className='flex flex-col items-center'>
              <Button
                size='large'
                icon={<PhoneOutlined />}
                className='flex items-center gap-x-2'
                onClick={onCall}
              >
                {_t("details.call")}
              </Button>
              <div className='relative w-[350px] my-3'>
                <div className='absolute inset-0 flex items-center'>
                  <span className='w-full border-t border-black'></span>
                </div>
                <div className='relative flex justify-center text-md font-semibold uppercase'>
                  <span className='bg-white px-3'>{_t("login.or")}</span>
                </div>
              </div>
              <Button
                size='large'
                icon={<MailOutlined />}
                className='hover:text-red-600 flex items-center gap-x-2'
                onClick={onEmail}
              >
                {_t("details.sendEmail")}
              </Button>
            </div>
          </Modal>
          <Modal
            title={
              <div className='text-lg'>
                <ExclamationCircleFilled className='mr-2' /> {_t("Confirm")}
              </div>
            }
            onCancel={() => setIsDeleteVisible(false)}
            open={isDeleteVisible}
            centered
            footer=''
          >
            <div className='flex flex-col gap-3'>
              <span className='text-base'>
                {_t("Are you sure you want to remove this fabric?")}
              </span>
              <div className='flex justify-end gap-3'>
                <Button
                  size='large'
                  type='primary'
                  danger
                  onClick={() => onDeleteFabric()}
                >
                  {_t("Delete")}
                </Button>
                <Button size='large' onClick={() => setIsDeleteVisible(false)}>
                  {_t("details.close")}
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
      <ScrollIndicator />
    </div>
  )
}

export default FabricDetails
