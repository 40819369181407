import { useEffect, useMemo, useState } from "react";
import { Checkbox, Form, FormInstance, Space } from "antd";
import { _t } from "../../utils/translate";
import { treatments } from "../../utils/constants";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

const TreatmentsComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const [values, setValues] = useState<string[]>([]);
  const { lang } = useSelector((state: RootState) => state.auth);

  const resortedTreatments = useMemo(() => {
    let tmp: string[] = treatments["ANTI"].concat(treatments["Eco"], treatments["C3"], treatments["C4"], treatments["C5"]).concat(['Undetermined treatment']);
    tmp.sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]));

    return tmp;
  }, [treatments])

  useEffect(() => updateFilter({ 'treatments': form.getFieldValue('treatments') }), []);

  const onChange = (values: any) => {
    setValues(values);
    updateFilter({ 'treatments': values });
  }

  return (
    <Form.Item name="treatments" initialValue={undefined} style={{ height: 150, overflowY: 'auto', marginBottom: 0 }}>
      <Checkbox.Group value={values} onChange={onChange}>
        <Space direction="vertical">
          {resortedTreatments
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map(type => <Checkbox key={"treatments" + type} value={type}>{_t(type)}</Checkbox>)}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  )
}

export default TreatmentsComponent;
