import { useEffect, useMemo, useState } from "react";
import { Checkbox, Form, FormInstance, Radio, RadioChangeEvent, Space } from "antd";
import { _t } from "../../utils/translate";
import { patternDrawingData } from "../../utils/constants";
import { messages } from '../../utils/translate';
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

const AdvancedDrawingComponent = ({ form, updateFilter }: { form: FormInstance, updateFilter: Function }) => {
  const [values, setValues ] = useState<string[]> ([]);
  const { lang } = useSelector((state: RootState) => state.auth);

  const drawings = useMemo(() => {
    return form.getFieldValue('pattern') ? patternDrawingData[form.getFieldValue('pattern')] : [];
  }, [form.getFieldValue('pattern')]);

  useEffect(() => {
    updateFilter({
      "drawing": form.getFieldValue('drawing'),
    });
  }, []);

  const onChange = (values: any) => {
    setValues(values);
    updateFilter({'drawing': values});
  }

  if (drawings.length === 0) {
    return <span>{_t("Please choose pattern")}</span>
  }

  return (
    <Form.Item name="drawing" style={{ height: 150, overflowY: 'auto', marginBottom: 0 }}>
      <Checkbox.Group value={values} onChange={onChange}>
        <Space direction="vertical">
          {drawings
            .sort((a: string, b: string) => messages[lang][a].localeCompare(messages[lang][b]))
            .map((val) => (
            <Checkbox value={val} key={'imprimeDrawing' + val}>{_t(val)}</Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  )
}

export default AdvancedDrawingComponent;